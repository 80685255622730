import {
  equals,
  find,
  forEach,
  isEmpty,
  isNil,
  keys,
  map,
  mergeAll,
  path,
  reduce,
  uniq,
} from "ramda";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CatalogService,
  Characteristic,
  Characteristics,
  Filter,
  Product,
  ProductGroup,
  Selection,
  SelectProductsResult,
} from "@encoway/c-services-js-client";

import { AppInstance, SETTINGS } from "../settings";
import { Categories, Category, DEFAULT_CATEGORIES } from "../types/category";
import { Products } from "../types/product";
import {
  matchCategory,
  matchProduct,
  toExtendedCharacteristic,
} from "./productUtils";

export interface ProductStore {
  catalogService: CatalogService | undefined;
  products: Products;
  categories: Categories;
  characteristics: { [id: string]: Characteristic };

  getProducts(id: string | number | Array<string | number>): Promise<Products>;

  getCategory(id?: DEFAULT_CATEGORIES): Promise<Categories>;

  getCategoryProduct(id: keyof Products): Category | undefined;

  getCharacteristics(
    product: string,
    ids: string[],
  ): Promise<{ [id: string]: Characteristic }>;
}

const ProductContext = createContext<ProductStore | null>(null);

export function ProductProvider({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();
  const catalogService = useMemo(
    () =>
      new CatalogService(
        AppInstance.http,
        SETTINGS.showroom.url,
        i18n.language,
      ),
    [i18n.language],
  );
  const [products, setProducts] = useState<{ [lang: string]: Products }>({});
  const [categories, setCategories] = useState<{ [lang: string]: Categories }>(
    {},
  );
  const [characteristics, setCharacteristics] = useState<{
    [lang: string]: { [id: string]: Characteristic };
  }>({});

  async function fetchProducts(ids: string[]): Promise<Products> {
    let productsFilter = Filter.productsFilter();
    forEach((id) => productsFilter.id(id + ""), ids);
    let selection = new Selection()
      .filter(productsFilter)
      .limit(1000)
      .characteristics(new Characteristics().all());
    const productsResult = await catalogService.products(selection);
    return reduce(
      (acc, product) => {
        const characteristicValues = reduce(
          toExtendedCharacteristic(product.characteristicValues),
          {},
          productsResult.characteristics,
        );
        return { ...acc, [product.id]: { ...product, characteristicValues } };
      },
      {},
      productsResult.products,
    );
  }

  async function getProductsForDeepFetchCategories(
    groups: ProductGroup[],
  ): Promise<{ [groupId: string]: Products }> {
    const productFilter = Filter.productGroupFilter();
    const productsGroupResult = await Promise.all(
      map(async (group) => {
        return {
          id: group.id,
          result: await catalogService.products(
            new Selection()
              .filter(productFilter.id(group.id))
              .characteristics(new Characteristics().all())
              .limit(1000),
          ),
        };
      }, groups),
    );
    return reduce<
      { id: string; result: SelectProductsResult },
      { [key: string]: Products }
    >(
      (acc, { id, result }) => {
        return {
          ...acc,
          [id]: reduce(
            (accProducts: {}, product: Product): Products => {
              const characteristicValues = reduce(
                toExtendedCharacteristic(product.characteristicValues),
                {},
                result.characteristics,
              );
              return {
                ...accProducts,
                [product.id]: { ...product, characteristicValues },
              };
            },
            {},
            result.products,
          ),
        };
      },
      {},
      productsGroupResult,
    );
  }

  async function deepFetchCategories(
    groups: ProductGroup[],
  ): Promise<Categories> {
    const newProducts = await getProductsForDeepFetchCategories(groups);
    const combinedProducts = reduce(
      (acc, ele) => ({ ...acc, ...newProducts[ele] }),
      {},
      keys(newProducts),
    );
    setProducts((prev) => ({
      ...prev,
      [i18n.language]: { ...prev[i18n.language], ...combinedProducts },
    }));
    const groupsResult = await Promise.all(
      map(
        async (group) => ({
          id: group.id,
          result: await catalogService.group(group.id),
        }),
        groups,
      ),
    );

    return reduce(
      async function (acc: {}, group: ProductGroup): Promise<Categories> {
        const groupResult = find(
          (result) => equals(result.id, group.id),
          groupsResult,
        );
        if (groupResult?.result) {
          const newGroup: Category = {
            id: group.id,
            name: group.name || group.id,
            categories: {},
            products: keys(newProducts[group.id]),
            characteristics: reduce(
              toExtendedCharacteristic(
                groupResult.result.productGroup.characteristicValues,
              ),
              {},
              groupResult.result.characteristics,
            ),
          };
          const accW = await acc;
          if (group.hasChildProductGroups) {
            const { productGroups } = await catalogService.subgroups(group.id);
            return {
              ...accW,
              [group.id]: {
                ...newGroup,
                categories: await deepFetchCategories(productGroups),
              },
            };
          }
          return { ...accW, [group.id]: newGroup };
        }
        throw new Error("Cold not find product result");
      },
      {},
      groups,
    );
  }

  async function getProducts(
    ids: string | number | (string | number)[],
  ): Promise<Products> {
    if (isEmpty(ids)) {
      throw new Error("can not fetch empty array products");
    }
    const uIds = Array.isArray(ids)
      ? uniq(map((id) => id + "", ids))
      : [ids + ""];
    if (path<string>([i18n.language], products)) {
      const { cacheIds, fetchIds } = reduce(
        (acc: { cacheIds: string[]; fetchIds: string[] }, id: string) => {
          if (products[i18n.language][id]) {
            return { ...acc, cacheIds: [...acc.cacheIds, id] };
          }
          return { ...acc, fetchIds: [...acc.fetchIds, id] };
        },
        { cacheIds: [], fetchIds: [] },
        uIds,
      );
      const cachedProducts = reduce(
        (acc, _id) => ({ ...acc, [_id]: products[i18n.language][_id] }),
        {},
        cacheIds,
      );
      if (isEmpty(fetchIds)) {
        return cachedProducts;
      }
      const newProducts = await fetchProducts(fetchIds);
      setProducts((prev) => ({
        ...prev,
        [i18n.language]: { ...prev[i18n.language], ...newProducts },
      }));
      return { ...newProducts, ...cachedProducts };
    }
    const newProducts = await fetchProducts(uIds);
    setProducts((prev) => ({
      ...prev,
      [i18n.language]: { ...prev[i18n.language], ...newProducts },
    }));
    return newProducts;
  }

  async function getCategory(
    id: string | number = "_CATALOGUE",
  ): Promise<Categories> {
    if (path([i18n.language, id], categories)) {
      return { [id]: categories[i18n.language][id] };
    }
    const match = matchCategory(id, categories[i18n.language]);
    if (!isEmpty(match)) {
      return { [id]: match as Category };
    }
    const group = await catalogService.group(id + "");
    const subCategory = await deepFetchCategories([group.productGroup]);
    setCategories((prev) => ({
      ...prev,
      [i18n.language]: { ...prev[i18n.language], ...subCategory },
    }));
    return subCategory;
  }

  function getCategoryProduct(id: keyof Products): Category | undefined {
    const match = matchProduct(id, categories[i18n.language]);
    if (isEmpty(match)) {
      return undefined;
    }
    return match as Category;
  }

  async function getCharacteristics(
    product: string,
    ids: string[],
  ): Promise<{
    [id: string]: Characteristic;
  }> {
    const productsFilter = Filter.productsFilter();
    productsFilter.id(product);
    const characteristics = new Characteristics();
    forEach((id) => characteristics.id(id), ids);
    const productsGroupResult = await catalogService.products(
      new Selection()
        .filter(productsFilter)
        .characteristics(characteristics)
        .limit(1000),
    );
    const allCharacteristicsObject = mergeAll(
      map(
        (characteristic) => ({ [characteristic.id]: characteristic }),
        productsGroupResult.characteristics,
      ),
    );
    setCharacteristics((prev) => ({
      ...prev,
      [i18n.language]: allCharacteristicsObject,
    }));
    return allCharacteristicsObject;
  }

  const value = useMemo(
    () => ({
      catalogService,
      products: products[i18n.language],
      categories: categories[i18n.language],
      characteristics: characteristics[i18n.language],
      getProducts,
      getCategory,
      getCategoryProduct,
      getCharacteristics,
    }),
    [products, categories, characteristics, i18n.language],
  );

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
}

export function useProductContext() {
  const context = useContext(ProductContext);
  if (isNil(context)) {
    throw new Error("useProduct must be used within a ProductProvider");
  }
  return context;
}
