import {
  any,
  equals,
  filter,
  find,
  includes,
  isEmpty,
  length,
  map,
  path,
  pathOr,
  reduce,
  replace,
} from "ramda";

import { ContainerTO, GuiTO, ParameterTO } from "@encoway/c-services-js-client";

import { Bom, BomParameter } from "../types/configuration";
import { SelectedParameters } from "./useConfiguration";

/**
 * Function to fetch all parameters recursively
 * @param acc parameter accumulator
 * @param container container to reduce
 * @returns all parameters contained in rootCountainer and its recursive children
 */
export function toParameter(
  acc: ParameterTO[],
  container: ContainerTO,
): ParameterTO[] {
  if (!isEmpty(container.children)) {
    return [
      ...acc,
      ...container.parameters,
      ...reduce(toParameter, [], container.children),
    ];
  }
  return [...acc, ...container.parameters];
}

/**
 * Function to fetch all container recursively
 * @param acc parameter accumulator
 * @param container container to reduce
 * @returns all container contained in rootContainer and its recursive children
 */
export function toContainer(
  acc: ContainerTO[],
  container: ContainerTO,
): ContainerTO[] {
  if (!isEmpty(container.children)) {
    return [
      ...acc,
      ...container.children,
      ...reduce(toContainer, [], container.children),
    ];
  }
  return [...acc, ...container.children];
}

/**
 * Determines if any selection by the user is in the active parameterTO array
 * @param parameters the parameters to search with
 * @returns boolean true if any selection had taken place
 */
export function anySelectionByUser(parameters: ParameterTO[]): boolean {
  const selectionSources = map(
    (param) => path(["selectedValues", 0, "selectionSource"], param),
    parameters,
  );
  return any((val) => equals("SET_BY_USER", val), selectionSources);
}

/**
 * Function to determine all selected parameters to the given reducer
 * @param acc the selected parameters accumulator
 * @param parameter the parameter to reduce
 * @returns selectedParameters the selected parameters object
 */
export function toSelectedParameters(
  acc: SelectedParameters,
  parameter: ParameterTO,
): SelectedParameters {
  return {
    ...acc,
    [parameter.name]: path(["selectedValues", 0, "value"], parameter),
  };
}

function toBomParameter(parameter: ParameterTO): BomParameter {
  return {
    id: parameter.name,
    value: path(["selectedValues", 0, "value"], parameter),
    quantity: path(
      ["selectedValues", 0, "quantity", "currentQuantity"],
      parameter,
    ),
    label: parameter.translatedName || parameter.name,
  };
}

/**
 * Function to fetch all not terminal parameters recursively
 * @param acc parameter accumulator
 * @param container container to reduce
 * @returns all parameters which should be selected and are not terminal
 * contained in rootCountainer and its recursive children
 */
export function toTerminal(
  acc: ParameterTO[],
  container: ContainerTO,
): ParameterTO[] {
  const parameters = filter((p) => {
    return !p.terminal! && p.mandatory!;
  }, container.parameters);
  if (!isEmpty(container.children)) {
    return [
      ...acc,
      ...parameters,
      ...reduce(toTerminal, [], container.children),
    ];
  }
  return [...acc, ...parameters];
}

export function toBom(acc: Bom[], container: ContainerTO): Bom[] {
  const initialBom = {
    id: container.name,
    parameter: map(toBomParameter, container.parameters),
  };
  if (isEmpty(container.children)) {
    return [...acc, initialBom];
  }
  return [...acc, initialBom, ...reduce(toBom, [], container.children)];
}

export function isValidConfiguration(
  guiTO: GuiTO,
  softConstraints: ParameterTO[],
) {
  if (equals(guiTO.rootContainer.readyState, "READY")) {
    if (softConstraints) {
      return !any(
        (constraint) =>
          equals(
            pathOr<string>("0", ["selectedValues", 0, "value"], constraint),
            "0",
          ),
        softConstraints,
      );
    }
    return true;
  }
  return false;
}

export function determineSoftConstraints(guiTO?: GuiTO): ParameterTO[] {
  if (guiTO) {
    const allContainerTOs = reduce(toContainer, [], [guiTO.rootContainer]);
    const container = find(
      (container) => equals(container.name, "CB_SOFT_CONSTRAINT"),
      allContainerTOs,
    );
    if (container && length(container.parameters) > 0) {
      return container.parameters;
    }
  }
  return [];
}

export function determineInvalidSoftConstraints(
  guiTO: GuiTO,
  softConstraints: ParameterTO[],
): ParameterTO[] {
  const allParameter = reduce(toParameter, [], [guiTO.rootContainer]);
  const invalidSoftConstraints = filter((constraint) => {
    const val = pathOr<string>("1", ["selectedValues", 0, "value"], constraint);
    return equals(val, "0");
  }, softConstraints);

  return map((invalidConstraint) => {
    const constraintLink = replace(
      /^{"([^"]+)"}$/,
      "$1",
      pathOr<string>(
        "",
        ["viewPortProperties", "C_SOFTCONSTRAINT_REFERENCE"],
        invalidConstraint,
      ),
    );
    const invalidParameter = find(
      (parameter) => equals(parameter.name, constraintLink),
      allParameter,
    );
    if (invalidParameter) {
      return invalidParameter;
    }
    throw new Error(
      `${invalidParameter} could not be determined to match invalid soft constraint from ${constraintLink} - ${invalidConstraint.name}`,
    );
  }, invalidSoftConstraints);
}

export function filterUnwantedViewports(parameter: ParameterTO): boolean {
  const INVALID_VIEWPORTS = ["displayOnly", "CustomDisplayOnly"];
  const currentViewport = path(["viewPort"], parameter);

  return includes(currentViewport, INVALID_VIEWPORTS);
}
