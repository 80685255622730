import { equals, not } from "ramda";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { ParameterTO, selectedValue } from "@encoway/c-services-js-client";
import { Constants } from "@encoway/react-configurator";

import { SETTINGS } from "../../../settings";
import { ConfiguratorComponentProps } from "../../../types/configuration";
import {
  equals1,
  getCustomViewportProperty,
  placeholderMinMax,
} from "../configuratorUtils";
import {
  equalsEnter,
  generateNumberProps,
  isNumberInput,
} from "./ConfInputUtils";

type NumberInputProps = Omit<NumericFormatProps, "value"> & {
  value: string;
  submit: (value: string) => void;
};

function NumberInput({ value, submit, ...props }: NumberInputProps) {
  const { i18n } = useTranslation();
  const [internalValue, setInternalValue] = useState(value);

  return (
    <NumericFormat
      className="customInput-number customInput"
      value={internalValue}
      onValueChange={(values) => setInternalValue(values.value)}
      style={{
        border: "1px solid rgb(219, 219, 219)",
        padding: "6px",
        width: "unset",
      }}
      onKeyDown={(e) => equalsEnter(e) && submit(internalValue)}
      onBlur={() => submit(internalValue)}
      {...props}
      {...generateNumberProps(i18n.language)}
    />
  );
}

export function ConfInput({
  data,
  onValueChanged,
}: ConfiguratorComponentProps<ParameterTO>) {
  const initialValueRef = useRef(selectedValue(data, "")!);

  function submit(value: string) {
    if (equals(value, initialValueRef.current)) {
      return;
    }
    onValueChanged(data, value || Constants.Undo);
    initialValueRef.current = value;
  }

  if (isNumberInput(data)) {
    const hidePlaceholder = getCustomViewportProperty(
      data,
      SETTINGS.configuration.viewportProperties.hidePlaceholder,
    );
    return (
      <NumberInput
        value={initialValueRef.current}
        submit={submit}
        {...(not(equals1(hidePlaceholder)) && {
          placeholder: placeholderMinMax(data),
        })}
      />
    );
  }

  return (
    <input
      className="customInput"
      defaultValue={initialValueRef.current}
      onKeyDown={(e) => equalsEnter(e) && submit(e.currentTarget.value)}
      style={{ border: "1px solid rgb(219, 219, 219)", padding: "6px" }}
      onBlur={(e) => submit(e.target.value)}
    />
  );
}
