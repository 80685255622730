import { DefaultButton } from "@fluentui/react/lib/Button";
import { FocusZone } from "@fluentui/react/lib/FocusZone";
import { IPageProps, List } from "@fluentui/react/lib/List";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { TooltipHost, TooltipOverflowMode } from "@fluentui/react/lib/Tooltip";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { useCallback } from "react";

import {
  Constants as RestApiConstants,
  specState,
  Value,
} from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { InfoButtonProps } from "../InfoButton/InfoButton.types";
import { ParameterProps } from "../Parameter/Parameter.types";
import {
  ComponentName,
  READY_STATE_NOT_READY,
  SPEC_STATE_SET_BY_SYSTEM,
} from "../constants";
import { getDisplayValue } from "../helperFunctions";
import { TextButtonStyles } from "./TextButton.styles";
import { ITextButtonStyles, TextButtonProps } from "./TextButton.types";

const Parameter = (props: ParameterProps) =>
  ComponentFactory.instanceOf(ComponentName.Parameter, props);

const InfoButton = (props: InfoButtonProps) =>
  ComponentFactory.instanceOf(ComponentName.InfoButton, props);

const ITextButton = (props: TextButtonProps) => {
  const { styles, theme, data, ...delegatedProps } = props;
  const classNames: IProcessedStyleSet<ITextButtonStyles> =
    classNamesFunction()(styles, theme);
  const readOnly = !data.editable || props.options?.readOnly;
  const isMandatory = data.readyState === READY_STATE_NOT_READY;
  const specStateValue = specState(data);

  const changeValue = (newValue: Value) => {
    if (!newValue.selected) {
      props.onValueChanged(
        data,
        newValue.value,
        RestApiConstants.ValueFormat.Unformatted,
      );
    }
  };

  function createPrice(className: string, item?: Value) {
    const { options, ...otherProps } = props;
    if (item?.price === undefined || (readOnly && !item?.selected)) return null;

    const priceProps = {
      price: item?.price,
      currency: options?.currency,
      showPrices: options?.showPrices,
      ...otherProps,
    };

    return (
      <Stack className={"textButtonWrapper"}>
        <Text className={classnames("textButtonValue", className)}>
          {ComponentFactory.instanceOf(ComponentName.Price, priceProps)}
        </Text>
      </Stack>
    );
  }

  function createInfoButton(className: string, item?: Value) {
    return (
      <div className={classnames("textButtonInfoVariant", className)}>
        <InfoButton {...delegatedProps} data={item || ({} as Value)} />
      </div>
    );
  }

  const extractOptionClasses = (item: Value) => {
    const classNameArray = [];
    classNameArray.push(classNames.option);
    if (isMandatory && !readOnly) {
      classNameArray.push(classNames.optionNotReady);
    } else if (readOnly || !item.enabled) {
      classNameArray.push(classNames.optionDisabled);
      if (item.selected) {
        classNameArray.push(classNames.optionSelectedDisabled);
      }
    } else if (item.selected) {
      classNameArray.push(classNames.optionSelected);
    } else if (!item.selected) {
      classNameArray.push(classNames.optionNotSelected);
      if (!item.selectable) {
        classNameArray.push(classNames.optionConflict);
      }
    } else if (specStateValue === SPEC_STATE_SET_BY_SYSTEM) {
      classNameArray.push(classNames.optionConflict);
    }
    return classNameArray;
  };

  const extractTextClass = (item?: Value) => {
    const classNameArray = [];
    classNameArray.push(classNames.optionText);
    if (readOnly || !item?.enabled) {
      classNameArray.push(classNames.optionTextDisabled);
    } else if (item?.selected) {
      classNameArray.push(classNames.optionTextSelected);
    } else if (!item?.selectable) {
      classNameArray.push(classNames.optionTextNotSelected);
    }
    return classNameArray;
  };

  const extractPriceClasses = (item?: Value) => {
    const classNameArray = [];
    classNameArray.push(classNames.price);
    if (readOnly || !item?.enabled) {
      classNameArray.push(classNames.priceDisabled);
    } else if (item?.selected) {
      classNameArray.push(classNames.priceSelected);
    } else if (!item?.selectable) {
      classNameArray.push(classNames.priceNotSelected);
    }
    return classNameArray;
  };

  const extractInfoButtonClasses = (item?: Value) => {
    const classNameArray = [];
    classNameArray.push(classNames.infoButton);
    if (readOnly || !item?.enabled) {
      classNameArray.push(classNames.infoButtonDisabled);
    } else if (item?.selected) {
      classNameArray.push(classNames.infoButtonSelected);
    } else if (!item?.selectable) {
      classNameArray.push(classNames.infoButtonNotSelected);
    }
    return classNameArray;
  };

  const defaultRenderText = (item?: Value) => {
    const optionTextClasses = mergeStyles(extractTextClass(item));
    const priceClasses = mergeStyles(extractPriceClasses(item));
    const infoButtonClasses = mergeStyles(extractInfoButtonClasses(item));
    const translatedValue = getDisplayValue(
      item?.translatedValue,
      data.displayUnit,
    );

    return (
      <>
        <Stack
          horizontalAlign={"center"}
          horizontal
          tokens={{ childrenGap: "0.5rem" }}
          className={"textButtonStack"}
        >
          <TooltipHost
            hostClassName={optionTextClasses}
            overflowMode={TooltipOverflowMode.Self}
            content={translatedValue}
            className={"textButtonTooltip"}
          >
            {translatedValue}
          </TooltipHost>
          {createInfoButton(infoButtonClasses, item)}
        </Stack>
        {createPrice(priceClasses, item)}
      </>
    );
  };

  const renderCell = useCallback(
    (item: Value) => {
      const optionClasses = mergeStyles(extractOptionClasses(item));
      const enabled = !readOnly && item.enabled;
      return (
        <Stack.Item
          className={"textButtonStackItem"}
          key={item.value}
          align="stretch"
        >
          <DefaultButton
            onClick={() => enabled && changeValue(item)}
            className={classnames("textButtonOptional", optionClasses)}
            data-is-focusable={enabled}
          >
            <Stack
              className={classnames(
                "textButtonContentContainer",
                classNames.optionContentContainer,
              )}
            >
              {props.onRenderText
                ? props.onRenderText(item, defaultRenderText)
                : defaultRenderText(item)}
            </Stack>
          </DefaultButton>
        </Stack.Item>
      );
    },
    [data],
  );

  const renderPage = useCallback(
    (pageProps: IPageProps<Value> | undefined) => {
      return (
        <FocusZone className={"textButtonFocusZone"} key={data.id}>
          <Stack
            className={"textButtonFocusStack"}
            wrap
            horizontal
            tokens={{ childrenGap: "1rem" }}
          >
            {pageProps?.page?.items?.map(renderCell)}
          </Stack>
        </FocusZone>
      );
    },
    [renderCell],
  );

  return (
    <Stack className={classnames("textButtonOptionStack", classNames.root)}>
      <Parameter data={data} {...delegatedProps} />
      <List
        className={classnames(
          "textButtonOptionContainer",
          classNames.optionsContainer,
        )}
        getItemCountForPage={() => data.values?.length || 0}
        items={data.values}
        onRenderPage={renderPage}
      />
    </Stack>
  );
};

/**
 * The TextButton component renders a Button for the possible values of a ParameterTO.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/TextButton/TextButton.tsx)
 * - [TextButtonStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/TextButton/TextButton.styles.ts)
 * - [TextButtonProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/TextButton/TextButton.types.ts)
 * - [MS Fluent DefaultButton](https://developer.microsoft.com/de-DE/fluentui#/controls/web/button)
 *
 * @visibleName TextButton
 */
export const TextButton = styled(ITextButton, TextButtonStyles);
TextButton.displayName = "TextButton";
