import { Stack, StackItem } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { TextField } from "@fluentui/react/lib/TextField";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

import { ParameterTO, Value } from "@encoway/c-services-js-client";
import { L10n } from "@encoway/l10n";
import { ComponentFactory } from "@encoway/react-configurator";

import { ComponentName } from "../constants";
import { getDisplayValue } from "../helperFunctions";
import { DisplayStyles } from "./Display.styles";
import { IDisplayStyles, DisplayProps } from "./Display.types";

const getSelectedValue = (data: ParameterTO) => {
  if (data?.terminal) {
    return data?.values?.find((v) => v.selected);
  }
};
const Parameter = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.Parameter, {
    ...props,
    hideStateIcon: true,
  });
};
const Price = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.Price, props);
};
const StateIcon = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.StateIcon, props);
};
const BurgerMenu = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.BurgerMenu, props);
};

/**
 * Renders the value of a ParameterTO without mutation options.
 *
 * @visibleName Display
 */
function IDisplay(props: DisplayProps) {
  const { styles, theme, ...delegatedProps } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IDisplayStyles>;
  const selectedValue: Value | undefined = getSelectedValue(props.data);

  const [currentValue, setCurrentValue] = useState(
    selectedValue?.translatedValue,
  );

  useEffect(() => {
    const translatedValue = selectedValue?.translatedValue;
    if (translatedValue !== currentValue) {
      setCurrentValue(translatedValue);
    }
  }, [selectedValue]);

  const value = currentValue || L10n.format("Configuration.Display.noValue");

  const textFieldClassName = mergeStyles(
    classNames.textField,
    currentValue ? undefined : classNames.textFieldEmpty,
  );

  function defaultRender(displayValue?: string) {
    const value = currentValue
      ? getDisplayValue(displayValue, props.data.displayUnit)
      : displayValue;
    return (
      <TextField
        key={`${props.data.id}.textField`}
        className={classnames("displayTextField", textFieldClassName)}
        readOnly
        disabled
        value={value || ""}
      />
    );
  }

  const textField =
    (props.onRenderValue && props.onRenderValue(value, defaultRender)) ||
    defaultRender(value);

  return (
    <Stack className={classnames("defaultDisplay", classNames.root)}>
      <Parameter {...delegatedProps} className={"defaultDisplayStack"} />
      <Stack
        horizontal
        className={classnames(
          "defaultDisplayHorizontalStack",
          classNames.horizontalStack,
        )}
      >
        <StackItem grow={2} className={"defaultDisplayStackItem"}>
          {textField}
        </StackItem>
        <Price
          {...delegatedProps}
          price={selectedValue?.price}
          currency={props.options?.currency}
          showPrices={props.options?.showPrices}
          classname={"defaultDisplayPrice"}
        />
        <StateIcon {...delegatedProps} className={"defaultDisplayStateIcon"} />
        <BurgerMenu
          {...delegatedProps}
          className={"defaultDisplayBurgerMenu"}
        />
      </Stack>
    </Stack>
  );
}

export const Display = styled(IDisplay, DisplayStyles);
Display.displayName = "Display";
