import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { ILinkedTreeStyles } from "./LinkedTree.types";

export const LinkedTreeStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<ILinkedTreeStyles>
> = (theme: IPartialTheme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  resizeContainer: {
    display: "flex",
    width: "20em",
    overflow: "auto",
    selectors: {
      ".ms-FocusZone": {
        display: "flex",
        flex: 1,
      },
      ".ms-Nav": {
        // MS Fluent automatically sets overflow-y to auto in nav component.
        // but we want to let it grow and let the resize container to be scrollable
        overflowY: "inherit",
      },
    },
  },
  nav: {
    width: "100%",
    borderRadius: 0,
    selectors: {
      ".ms-Nav-link": {
        backgroundColor: theme.palette?.white,
        borderRadius: 0,
      },
      ".ms-Nav-chevronButton": {
        color: theme.palette?.neutralPrimary,
        backgroundColor: "transparent",
      },
      ".ms-Nav-link::after": {
        border: "none",
      },
      ".ms-Nav-chevronButton::after": {
        border: "none",
      },
      ".ms-Nav-linkText": {
        color: theme.palette?.neutralPrimary,
      },
    },
  },
  resizeBar: {
    flexGrow: 1,
    width: 3,
    backgroundColor: theme.semanticColors?.bodyBackgroundHovered,
    cursor: "col-resize",
    selectors: {
      ":hover": {
        backgroundColor: theme.palette?.themePrimary,
      },
    },
  },
});
