import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react/lib/ChoiceGroup";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { TooltipDelay, TooltipHost } from "@fluentui/react/lib/Tooltip";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { FormEvent, useEffect, useState } from "react";

import {
  Constants as RestApiConstants,
  selectedRawValue,
  specState,
  Value,
} from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { ComponentName, SPEC_STATE_SET_BY_SYSTEM } from "../constants";
import { getUnitDisplayValue } from "../helperFunctions";
import { RadioButtonStyles } from "./RadioButton.styles";
import {
  IRadioButtonStyles,
  RadioButtonProps,
  render,
} from "./RadioButton.types";

/**
 * The RadioButton component renders a ParameterTO as a headline using the Parameter component and its ValueTOs as radio buttons.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/RadioButton/RadioButton.tsx)
 * - [RadioButtonStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/RadioButton/RadioButton.styles.ts)
 * - [RadioButtonProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/RadioButton/RadioButton.types.ts)
 * - [MS Fluent ChoiceGroup](https://developer.microsoft.com/de-DE/fluentui#/controls/web/choicegroup)
 *
 * @visibleName RadioButton
 */
const IRadioButton = (props: RadioButtonProps) => {
  const { styles, theme, ...delegatedProps } = props;
  const classNames: IProcessedStyleSet<IRadioButtonStyles> =
    classNamesFunction()(styles, theme);
  const readOnly = (!props.data.editable || props.options?.readOnly) as boolean;
  const specStateValue = specState(props.data);

  const [selectedValue, setSelectedValue] = useState<string | undefined | null>(
    selectedRawValue(props.data, null),
  );

  useEffect(() => {
    const value = selectedRawValue(props.data);
    if (value !== selectedValue) {
      if (value === undefined) {
        setSelectedValue(null);
      } else {
        setSelectedValue(value);
      }
    }
  });

  const changeValue = (newValue: IChoiceGroupOption | undefined) => {
    if (newValue?.key !== selectedValue) {
      props.onValueChanged(
        props.data,
        newValue?.key,
        RestApiConstants.ValueFormat.Unformatted,
      );
    }
  };

  const extractRadiobuttonOptions = () => {
    const { data, onCreateRadioButtonOption } = props;
    const unit = getUnitDisplayValue(data.displayUnit);
    const func = onCreateRadioButtonOption
      ? (v: Value) =>
          onCreateRadioButtonOption(v, unit, createBasicRadioButtonOption)
      : (v: Value) => createBasicRadioButtonOption(v, unit);

    return data.values?.map(func);
  };

  const renderLabel = (
    props: RadioButtonProps,
    render: render,
    text: string,
  ) => {
    return (
      <TooltipHost
        className={"radioButtonTooltipHost"}
        hostClassName={classnames(
          "radioButtonTooltipTextContainer",
          classNames.textContainer,
        )}
        content={text}
        delay={TooltipDelay.long}
      >
        {render(props)}
      </TooltipHost>
    );
  };

  const renderField = (
    props: RadioButtonProps,
    render: render,
    value: Value,
  ) => {
    let optionClass = classNames.option;

    if (specStateValue === SPEC_STATE_SET_BY_SYSTEM) {
      optionClass = mergeStyles(optionClass, classNames.optionConflict);
    } else if (readOnly) {
      optionClass = mergeStyles(optionClass, classNames.optionDisabled);
    } else if (value.selected) {
      optionClass = mergeStyles(optionClass, classNames.optionSelected);
    } else if (!value.selectable) {
      optionClass = mergeStyles(optionClass, classNames.optionNotSelected);
    }

    let priceClass = classNames.price;
    if (!value.selected) {
      priceClass = mergeStyles(priceClass, classNames.priceNotSelected);
    }

    const quantity = createQuantity(value);
    const price = createPrice(value);
    const infoButton = createInfoButton(value);

    return (
      <Stack
        horizontal
        tokens={{ childrenGap: "0.5em" }}
        className={classnames("radioButtonOption", optionClass)}
      >
        {render(props)}
        {infoButton}
        {quantity}
        <div className={classnames("radioButtonPrice", priceClass)}>
          {price}
        </div>
      </Stack>
    );
  };

  const createBasicRadioButtonOption = (value: Value, unit: string) => {
    const text = value.translatedValue + unit;
    return {
      key: value.value,
      text,
      onRenderLabel: (props: RadioButtonProps, render: render) =>
        renderLabel(props, render, text),
      onRenderField: (props: RadioButtonProps, render: render) =>
        renderField(props, render, value),
    };
  };

  const createPrice = (value: Value) => {
    if (value.price === undefined || (readOnly && !value.selected)) return null;

    const { options } = props;
    return ComponentFactory.instanceOf(ComponentName.Price, {
      ...delegatedProps,
      price: value.price,
      currency: options?.currency,
      showPrices: options?.showPrices,
    });
  };

  const createQuantity = (value: Value) => {
    if (value.selected) {
      return ComponentFactory.instanceOf(ComponentName.Quantity, {
        ...delegatedProps,
        data: props.data,
      });
    }
    return null;
  };

  const createInfoButton = (value: Value) => {
    return ComponentFactory.instanceOf(ComponentName.InfoButton, {
      ...delegatedProps,
      data: value,
    });
  };

  const parameterComponent = ComponentFactory.instanceOf(
    ComponentName.Parameter,
    {
      ...delegatedProps,
      hideQuantity: true,
    },
  );

  let className = classNames.choiceGroup;
  if (!readOnly && specStateValue === SPEC_STATE_SET_BY_SYSTEM) {
    className = mergeStyles(className, classNames.conflict);
  }

  return (
    <Stack className={classnames("radioButtonWrapper", classNames.root)}>
      {parameterComponent}
      <ChoiceGroup
        className={classnames("radioButtonChoiceGroup", className)}
        disabled={readOnly}
        options={extractRadiobuttonOptions() as IChoiceGroupOption[]}
        selectedKey={selectedValue}
        onChange={(
          event: FormEvent<HTMLElement | HTMLInputElement> | undefined,
          option?: IChoiceGroupOption | undefined,
        ) => changeValue(option)}
      />
    </Stack>
  );
};

export const RadioButton = styled(IRadioButton, RadioButtonStyles);
RadioButton.displayName = "RadioButton";
