import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IOptionalPositionDisplayStyles } from "./OptionalPositionDisplay.types";

export const OptionalPositionDisplayStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IOptionalPositionDisplayStyles>
> = (theme: IPartialTheme) => ({
  headline: {
    fontSize: "14px",
  },
  value: {
    "input:focus + label::before": {
      // unfortunately we need to use important here because the outline color is set with a very
      // specific rule wich applies to fluents root ms-Fabric--isFocusVisible component
      outlineColor: `${theme.semanticColors?.focusBorder} !important`,
    },
    ".ms-Checkbox:not(.is-checked):not(.is-disabled) .ms-Checkbox-checkbox": {
      borderColor: theme.palette?.neutralTertiary,
    },
    ".ms-Checkbox:not(.is-checked):not(.is-disabled) :hover .ms-Checkbox-checkbox":
      {
        borderColor: theme.palette?.neutralSecondary,
      },
    ".ms-Checkbox:not(.is-checked).is-disabled .ms-Checkbox-checkbox": {
      borderColor: theme.semanticColors?.buttonBackgroundDisabled,
    },
    ".ms-Checkbox:not(.is-checked).is-disabled :hover .ms-Checkbox-checkbox": {
      borderColor: theme.semanticColors?.buttonBackgroundDisabled,
    },
    ".ms-Checkbox:not(.is-checked) :hover .ms-Checkbox-checkmark": {
      opacity: 0,
    },
  },
  acceptButton: {
    border: "none",
    borderRadius: "0",
    selectors: {
      ":focus&::after": {
        outlineOffset: "3",
        outlineColor: theme.semanticColors?.focusBorder,
      },
      ":hover": {
        border: "none",
      },
      ":active": {
        border: "none",
      },
    },
  },
  rejectButton: {
    border: "none",
    borderRadius: "0",
    marginLeft: ".5em",
    selectors: {
      ":focus&::after": {
        outlineOffset: "3",
        outlineColor: theme.semanticColors?.focusBorder,
      },
    },
  },
  checkbox: {
    minWidth: 20,
    selectors: {
      ".ms-Checkbox-label": {
        minWidth: 0,
      },
      ".ms-Checkbox-checkbox": {
        borderRadius: 0,
      },
    },
  },
  price: {
    color: theme?.palette?.neutralSecondary,
    marginRight: "1em",
  },
  deleteButton: {
    padding: "0px",
    border: "none",
    borderRadius: "0em",
    backgroundColor: "transparent",
    minWidth: "0",
    height: "auto",
    fontSize: "14",
    color: theme.semanticColors?.link,
    selectors: {
      i: {
        color: "inherit",
        fontSize: "inherit",
        height: "auto",
        lineHeight: "normal",
        margin: "0",
      },
      "i > span": {
        height: "1.1em",
      },
      ":hover": {
        backgroundColor: "inherit",
        color: theme.semanticColors?.linkHovered,
      },
      ":focus&::after": {
        outline: `1px solid ${theme.semanticColors?.focusBorder}`,
        outlineOffset: "0.25rem",
      },
      "i > span > svg": {
        height: "14px",
        width: "14px",
      },
    },
  },
  optionalValues: {
    backgroundColor: theme.palette?.themeLighter,
    height: "2.2rem",
    width: "100%",
    padding: "0.2rem 0.3rem 0.2rem 10px",
    marginBottom: "2px",
    alignItems: "center",
    selectors: {
      "> :not(:first-child)": {
        marginLeft: "5px",
      },
    },
  },
  optionalValuesTitle: {
    color: theme.palette?.neutralSecondary,
    fontWeight: "bold",
    fontSize: "11px",
    margin: "10px 0px",
  },
  footer: {
    position: "relative",
    width: "100%",
    minHeight: "24px",
    lineHeight: "24px",
    margin: "32px 0px 0px",
    fontSize: "0px",
  },
});
