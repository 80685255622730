import { DefaultButton } from "@fluentui/react/lib/Button";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React from "react";

import { TabButtonStyles } from "./TabButton.styles";
import { ITabButtonStyles, TabButtonProps } from "./TabButton.types";

/**
 * The TabButton component renders the buttons in the Tabs bar.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabButton/TabButton.tsx)
 * - [TabButtonStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabButton/TabButton.styles.ts)
 * - [TabButtonProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabButton/TabButton.types.ts)
 * - [MS Fluent button](https://developer.microsoft.com/de-DE/fluentui#/controls/web/button)
 *
 * @visibleName TabButton
 */
const ITabButton = (props: TabButtonProps) => {
  const { styles, theme } = props;
  const classNames: IProcessedStyleSet<ITabButtonStyles> = classNamesFunction()(
    styles,
    theme,
  );

  let className = classNames.button;

  if (props.disabled) {
    className = mergeStyles(className, classNames.disabledButton);
  }
  return (
    <DefaultButton
      id={props.id}
      className={classnames("tabRootButton", className)}
      iconProps={{ iconName: props.iconName }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text || props.children}
    </DefaultButton>
  );
};

/**
 * The TabButton component renders the buttons in the Tabs bar.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabButton/TabButton.tsx)
 * - [TabButtonStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabButton/TabButton.styles.ts)
 * - [TabButtonProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabButton/TabButton.types.ts)
 * - [MS Fluent button](https://developer.microsoft.com/de-DE/fluentui#/controls/web/button)
 *
 * @visibleName TabButton
 */
export const TabButton = styled(ITabButton, TabButtonStyles);
TabButton.displayName = "TabButton";
