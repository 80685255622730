import {
  classNamesFunction,
  Dialog,
  DialogType,
  IDialogContentProps,
  IModalProps,
  IPartialTheme,
  IProcessedStyleSet,
  IPropsWithStyles,
  IStyleSet,
  mergeStyles,
  PrimaryButton,
  Separator,
  Stack,
  styled,
  Text,
  TooltipHost,
} from "@fluentui/react";
import classnames from "classnames";
import { join, map, path } from "ramda";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO, Value } from "@encoway/c-services-js-client";

import { EpriStyles } from "./standard/Epri/Epri.styles";
import { IEpriStyles } from "./standard/Epri/Epri.types";
import { determineLayerClass } from "./standard/helperFunctions";

interface ConfDenyEpriProps
  extends IPropsWithStyles<IPartialTheme, IStyleSet<IEpriStyles>> {
  source: ParameterTO;
  value: string;
  affectedParameters: ParameterTO[];
  onDecline: () => void;
  dialogMinWidth?: string;
  modalProps?: Partial<IModalProps>;
}

function getSelectedValue(parameter: ParameterTO): string {
  const selectedValue = path<Value>(["selectedValues", 0], parameter);
  return selectedValue
    ? selectedValue.translatedValue ?? selectedValue.value
    : "";
}

function TooltipText({
  classNames,
  text,
}: {
  classNames: IProcessedStyleSet<IEpriStyles>;
  text?: string;
}) {
  return (
    <TooltipHost
      content={text || ""}
      className={classnames("epriTooltipHost", classNames.tooltip)}
      styles={{
        root: { display: "inline-block", maxWidth: "100%" },
      }}
    >
      <Text
        block
        nowrap
        className={classnames("epriTooltipText", classNames.text)}
      >
        {text}
      </Text>
    </TooltipHost>
  );
}

function ParameterItem({
  classNames,
  parameter,
}: {
  parameter: ParameterTO;
  classNames: IProcessedStyleSet<IEpriStyles>;
}) {
  const selectedValue = getSelectedValue(parameter);

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      className={classnames("epriParameterStack", classNames.parameter)}
    >
      <div
        className={classnames("epriParameterName", classNames.parameterName)}
      >
        <TooltipText
          text={parameter.translatedName || parameter.name}
          classNames={classNames}
        />
      </div>
      <div
        className={classnames("epriParameterValue", classNames.parameterValue)}
      >
        <TooltipText text={selectedValue} classNames={classNames} />
      </div>
    </Stack>
  );
}

function Adjustments({
  classNames,
  affectedParameters,
}: {
  classNames: IProcessedStyleSet<IEpriStyles>;
  affectedParameters: ParameterTO[];
}) {
  const { t } = useTranslation();

  return (
    <div className={classnames("epriHeadlineSection", classNames.section)}>
      <Text
        variant="mediumPlus"
        className={classnames("epriHeadlineText", classNames.headline)}
      >
        {t("t:epri.deny.to_adjust")}
      </Text>
      <Separator className={"epriHeadlineSeperator"} />
      <div className={classnames("epriContentSection", classNames.section)}>
        {affectedParameters.map((parameter) => (
          <ParameterItem
            key={parameter.id}
            classNames={classNames}
            parameter={parameter}
          />
        ))}
      </div>
    </div>
  );
}

function ConfDenyEpriBase(props: ConfDenyEpriProps) {
  const { t } = useTranslation();
  const {
    source,
    value,
    affectedParameters,
    onDecline,
    styles,
    dialogMinWidth = "50rem",
    modalProps,
  } = props;

  const classNames = classNamesFunction<any, IEpriStyles>()(styles);

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: t("t:epri.deny.title"),
      showCloseButton: false,
    }),
    [t],
  );

  const message = useMemo(
    () =>
      t("t:epri.deny.text", {
        selectedValue: `${source.translatedName}: ${value}`,
        conflictedValues: join(
          ",",
          map(
            (param) => param.translatedName ?? param.name,
            affectedParameters,
          ),
        ),
      }),
    [t, source, value],
  );

  return (
    <Dialog
      hidden={false}
      dialogContentProps={dialogContentProps}
      onDismiss={onDecline}
      minWidth={`min(${dialogMinWidth}, 100%)`}
      modalProps={{
        isBlocking: true,
        className: classnames(
          "epriDialog",
          mergeStyles(
            classNames.dialog,
            classNames.modal,
            determineLayerClass(modalProps?.layerProps, classNames.layer),
          ),
        ),
        overlay: {
          isDarkThemed: false,
          className: classNames.overlay,
        },
        ...modalProps,
      }}
    >
      <div className={classnames("epriDialogContent", classNames.content)}>
        <div className={classnames("epriTextSection", classNames.section)}>
          {message}
        </div>
        <Adjustments
          classNames={classNames}
          affectedParameters={affectedParameters}
        />
      </div>
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: 10 }}
        className={classnames("epriDialogFooter", classNames.footer)}
      >
        <PrimaryButton
          onClick={onDecline}
          text={t("t:epri.deny.accept")}
          iconProps={{ iconName: "Accept" }}
          className={classnames(
            "epriDialogAcceptButton",
            classNames.acceptButton,
          )}
        />
      </Stack>
    </Dialog>
  );
}

export const ConfDenyEpri = styled(ConfDenyEpriBase, EpriStyles);
