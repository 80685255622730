import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IQuantityStyles } from "./Quantity.types";

export const QuantityStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IQuantityStyles>
> = (theme: IPartialTheme) => ({
  modal: {},
  overlay: {},
  dialog: {},
  root: {
    alignItems: "center",
    selectors: {
      "> button:not(:first-child)": {
        marginLeft: "0.5em",
      },
    },
  },
  iconButton: {
    color: theme.semanticColors?.inputIcon,
    height: "1em",
    width: "1em",
    margin: "0",
    padding: "0",
    paddingTop: "2px", // this is a workaround because the MS Fluent icon is one pixel off itself
    selectors: {
      ":hover": {
        backgroundColor: "none",
      },
      ":active": {
        backgroundColor: "none",
      },
    },
  },
  acceptButton: {
    border: "none",
    borderRadius: "0",
    selectors: {
      ":focus&::after": {
        outlineOffset: "3",
        outlineColor: theme.semanticColors?.focusBorder,
      },
      ":hover": {
        border: "none",
      },
      ":active": {
        border: "none",
      },
    },
  },
  rejectButton: {
    border: "none",
    borderRadius: "0",
    marginLeft: ".5em",
    selectors: {
      ":focus&::after": {
        outlineOffset: "3",
        outlineColor: theme.semanticColors?.focusBorder,
      },
    },
  },
  inputField: {
    selectors: {
      ".ms-TextField-description": {
        fontStyle: "italic",
        fontSize: "14px",
      },
      ".ms-TextField-fieldGroup": {
        marginBottom: "0.4em",
      },
    },
  },
  inputError: {
    selectors: {
      ".ms-TextField-fieldGroup": {
        backgroundColor: theme.semanticColors?.errorBackground,
        borderColor: theme.semanticColors?.errorIcon,
      },
      ".ms-TextField-fieldGroup:after": {
        borderColor: theme.semanticColors?.errorIcon,
      },
      ".ms-TextField-fieldGroup:hover": {
        borderColor: theme.semanticColors?.errorIcon,
      },
      ":focus": {
        borderColor: theme.semanticColors?.errorIcon,
      },
    },
  },
  restrictedQuantity: {
    fontStyle: "italic",
    fontSize: "14px",
    color: theme.semanticColors?.disabledText,
  },
  errorMessage: {
    fontSize: "14px",
    paddingTop: "0px",
    color: theme.semanticColors?.errorText,
  },
  disabled: {
    color: theme.semanticColors?.disabledText,
  },
});
