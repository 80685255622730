import { ChevronLeftIcon, ChevronRightIcon } from "@fluentui/react-icons-mdl2";
import { Icon } from "@fluentui/react/lib/Icon";
import { Link } from "@fluentui/react/lib/Link";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, registerIcons } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import {
  classNamesFunction,
  IRenderFunction,
  styled,
} from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React from "react";

import { L10n } from "@encoway/l10n";

import { TabsFooterStyles } from "./TabsFooter.styles";
import {
  ButtonProps,
  ContentProps,
  ITabsFooterStyles,
  TabsFooterProps,
} from "./TabsFooter.types";

const CHEVRON_LEFT = "ChevronLeft";
const CHEVRON_RIGHT = "ChevronRight";

registerIcons(
  {
    icons: {
      [CHEVRON_LEFT]: <ChevronLeftIcon />,
      [CHEVRON_RIGHT]: <ChevronRightIcon />,
    },
  },
  { disableWarnings: true },
);

/**
 * The TabsFooter component renders a Tab component for each of its ContainerTOs (children) and an interface to navigate between the Tab components.
 *
 * @visibleName TabsFooter
 */
function ITabsFooter(props: TabsFooterProps) {
  const defaultRenderPrevButton = (buttonProps: ButtonProps | undefined) => {
    return (
      <Link
        onClick={buttonProps?.onClick}
        className={classnames(
          "tabsFooterPrevLink",
          buttonProps?.classNames.footerLink,
        )}
      >
        <Icon className={"tabsFooterPrevIcon"} iconName={CHEVRON_LEFT} />
        <Text nowrap className={"tabsFooterPrevText"}>
          {L10n.format("Configuration.TabsFooter.Previous", {})}
        </Text>
      </Link>
    );
  };

  const defaultRenderNextButton = (buttonProps: ButtonProps | undefined) => {
    return (
      <Link
        onClick={buttonProps?.onClick}
        className={classnames(
          "tabsFooterNextLink",
          buttonProps?.classNames.footerLink,
        )}
      >
        <Text nowrap className={"tabsFooterNextText"}>
          {L10n.format("Configuration.TabsFooter.Next", {})}
        </Text>
        <Icon className={"tabsFooterNextIcon"} iconName={CHEVRON_RIGHT} />
      </Link>
    );
  };

  const defaultRender = (contentProps: ContentProps) => {
    const {
      classNames,
      data,
      onRenderPreviousButton,
      onRenderNextButton,
      changeTab,
    } = contentProps;

    const PreviousButton = (bProps?: ButtonProps) => {
      if (contentProps?.isFirstTab) {
        return null;
      }
      return onRenderPreviousButton
        ? onRenderPreviousButton(bProps, defaultRenderPrevButton)
        : defaultRenderPrevButton(bProps);
    };
    const NextButton = (bProps?: ButtonProps) => {
      if (contentProps?.isLastTab) {
        return null;
      }
      return onRenderNextButton
        ? onRenderNextButton(bProps, defaultRenderNextButton)
        : defaultRenderNextButton(bProps);
    };
    return (
      <Stack
        horizontal
        className={classnames("tabsFooterWrapper", classNames.footer)}
      >
        <div className={classnames("tabsFooterLeft", classNames.footerLeft)}>
          <PreviousButton
            onClick={() => changeTab(-1)}
            data={data}
            classNames={classNames}
          />
        </div>
        <div className={classnames("tabsFooterRight", classNames.footerRight)}>
          <NextButton
            onClick={() => changeTab(1)}
            data={data}
            classNames={classNames}
          />
        </div>
      </Stack>
    );
  };
  const {
    theme,
    styles,
    changeTab,
    data,
    isFirstTab,
    isLastTab,
    onRender,
    onRenderPreviousButton,
    onRenderNextButton,
  } = props;
  const classNames: IProcessedStyleSet<ITabsFooterStyles> =
    classNamesFunction()(styles, theme);
  const renderProps: ContentProps = {
    classNames,
    changeTab,
    isFirstTab,
    isLastTab,
    onRenderPreviousButton:
      onRenderPreviousButton ||
      (defaultRenderPrevButton as IRenderFunction<ButtonProps>),
    onRenderNextButton:
      onRenderNextButton ||
      (defaultRenderNextButton as IRenderFunction<ButtonProps>),
    data,
  };

  return (
    <>
      {(onRender &&
        onRender(
          renderProps,
          defaultRender as (p: ContentProps | undefined) => JSX.Element | null,
        )) ||
        defaultRender(renderProps)}
    </>
  );
}

/**
 * Renders a TabsFooter for the possible values of a ParameterTO.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/TabsFooter/TabsFooter.tsx)
 * - [TabsFooterStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/TabsFooter/TabsFooter.styles.ts)
 * - [TabsFooterProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/TabsFooter/TabsFooter.types.ts)
 *
 * @visibleName TabsFooter
 */
export const TabsFooter = styled(ITabsFooter, TabsFooterStyles);
