import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IDisplayStyles } from "./Display.types";

export const DisplayStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IDisplayStyles>
> = (theme: IPartialTheme) => ({
  root: {},
  horizontalStack: {
    selectors: {
      ">*:not(:last-child)": {
        marginRight: "0.5em",
      },
    },
  },
  textField: {
    selectors: {
      input: {
        padding: 0,
      },
      ".ms-TextField-fieldGroup": {
        height: "auto",
        border: "none",
      },
      ".ms-TextField-field": {
        color: theme?.semanticColors?.disabledText,
        background: "transparent !important",
      },
      ".ms-TextField-suffix": {
        backgroundColor: "transparent !important",
      },
    },
  },
  textFieldEmpty: {
    selectors: {
      ".ms-TextField-field": {
        color: theme?.semanticColors?.disabledText,
        fontStyle: "italic",
      },
    },
  },
});
