import { AxiosInstance } from "axios";
import { replace } from "ramda";

import { GuiTO } from "@encoway/c-services-js-client";

export function getCustomConfigurationView(
  axios: AxiosInstance,
  configurationId: string,
  guiModel: string,
  language: string,
  mappingProfile: string = "MINIMUM_CONTENT_MAPPING",
) {
  // de-AT will be "de" - custom hack for PSU Error will be fixed in 25.0.x
  const acceptLanguage = replace(/-[^-]+/, "", language);
  return axios.get<GuiTO>(
    `api/configuration/configurations/${configurationId}?mappingProfile=${mappingProfile}&guiModel=${guiModel}`,
    {
      headers: {
        "Accept-Language": acceptLanguage,
        Accept: "application/vnd.encoway.conf.src.gui+json",
      },
    },
  );
}
