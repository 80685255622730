import { all, any, equals } from "ramda";
import React, { useEffect, useState } from "react";

import { EpriProps } from "@encoway/cui-configurator-components";

import { useDenyEpri } from "../../../hooks/useDenyEpri";
import { ConfDenyEpri } from "./ConfDenyEpri";
import { Epri } from "./standard/Epri/Epri";

export function ConfEpri(props: EpriProps) {
  const [hide, setHide] = useState<boolean>(false);
  const { refAffectedParameters, affectedEpriCharacteristics } = useDenyEpri(
    props.affectedParameters,
  );

  useEffect(() => {
    if (
      all(
        (parameter) => equals(parameter.name, "C_PRIORITY"),
        props.affectedParameters,
      )
    ) {
      setHide(true);
      props.onAccept();
    }
  }, []);

  if (hide) {
    return null;
  }

  if (any((char) => char.disableEpri, affectedEpriCharacteristics)) {
    return <ConfDenyEpri {...props} />;
  }

  return <Epri {...props} affectedParameters={refAffectedParameters} />;
}
