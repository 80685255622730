import { InfoIcon } from "@fluentui/react-icons-mdl2";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { Link } from "@fluentui/react/lib/Link";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { Stack, StackItem } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, registerIcons } from "@fluentui/react/lib/Styling";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { useState } from "react";

import { determineLayerClass } from "../helperFunctions";
import { InfoButtonStyles } from "./InfoButton.styles";
import { IInfoButtonStyles, InfoButtonProps } from "./InfoButton.types";

const INFO = "Info";
registerIcons(
  {
    icons: {
      [INFO]: <InfoIcon />,
    },
  },
  { disableWarnings: true },
);

interface SubElementProps extends InfoButtonProps {
  classNames: IProcessedStyleSet<IInfoButtonStyles>;
}

const InfoPanelImage = (props: SubElementProps) => {
  const { data, classNames, hideImage } = props;
  if (!hideImage && data.imageUrl) {
    return (
      <StackItem className={"infoPanelImageStackItem"}>
        <Image
          className={classnames("infoPanelImage", classNames.image)}
          src={props.mediaLink?.(data.imageUrl || "")}
          width={"275px"}
          height={"275px"}
          imageFit={ImageFit.centerContain}
        />
      </StackItem>
    );
  }
  return <StackItem />;
};

const InfoPanelLongText = (props: SubElementProps) => {
  const { data, classNames } = props;
  if (data.longText) {
    return (
      <StackItem
        className={classnames("infoPanelLongText", classNames.longText)}
        align="start"
        grow={2}
      >
        <div dangerouslySetInnerHTML={{ __html: data.longText }} />
      </StackItem>
    );
  }
  return null;
};
const InfoPanelShortText = (props: SubElementProps) => {
  const { data, classNames } = props;
  return (
    <StackItem
      className={classnames("infoPanelShortText", classNames.shortText)}
    >
      {data.shortText}
    </StackItem>
  );
};

const isInfoAvailable = (props: InfoButtonProps) => {
  if (props.isInfoAvailable) {
    return props.isInfoAvailable(props);
  }

  return (
    props.data.longText ||
    props.data.shortText ||
    (props.data.imageUrl && !props.hideImage)
  );
};

const getHeaderText = (data: Record<string, unknown>) => {
  return (data.translatedName || data.translatedValue) as string;
};

/**
 * The InfoButton component renders additional information of a ParameterTO as a MS Fluent Link, which opens a MS Fluent Panel.
 * The panel opens on click (or Enter) and renders the image, short text and long text of the given position.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/InfoButton/InfoButton.tsx)
 * - [InfoButtonStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/InfoButton/InfoButton.styles.ts)
 * - [InfoButtonProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/InfoButton/InfoButton.types.ts)
 * - [MS Fluent Link](https://developer.microsoft.com/de-DE/fluentui#/controls/web/link)
 * - [MS Fluent Panel](https://developer.microsoft.com/de-DE/fluentui#/controls/web/panel)
 *
 * @visibleName InfoButton
 */
const IInfoButton = (props: InfoButtonProps) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const { styles, theme } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IInfoButtonStyles>;

  if (!isInfoAvailable(props)) {
    return null;
  }

  return (
    <div className={classnames("infoButtonIconRoot", classNames.root)}>
      <Link
        as={"a"} // otherwise this link will be displayed as a button
        className={classnames("infoButtonIconLink", classNames.iconButton)}
        onClick={(event) => {
          setPanelOpen(true);
          event.stopPropagation();
        }}
        onKeyPress={(event) => {
          // Prevent side effects by keyPress events
          event.preventDefault();
        }}
        onKeyUp={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            setPanelOpen(true);
          }
          event.preventDefault();
        }}
        tabIndex={0}
      >
        <FontIcon
          aria-label="Info"
          iconName={INFO}
          className={classnames("infoButtonIcon", classNames.icon)}
        />
      </Link>
      <Panel
        className={"infoButtonPanel"}
        headerText={getHeaderText(
          props.data as unknown as Record<string, unknown>,
        )}
        isOpen={isPanelOpen}
        isLightDismiss={true}
        onDismiss={() => setPanelOpen(false)}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
        type={props.panelType || PanelType.smallFixedFar}
        layerProps={{
          className: determineLayerClass(props.layerProps, classNames.layer),
          ...props.layerProps,
        }}
      >
        <Stack
          className={classnames("infoButtonContent", classNames.content)}
          tokens={{ childrenGap: 10 }}
        >
          <InfoPanelImage {...props} classNames={classNames} />
          <InfoPanelShortText {...props} classNames={classNames} />
          <InfoPanelLongText {...props} classNames={classNames} />
        </Stack>
      </Panel>
    </div>
  );
};

export const InfoButton = styled(IInfoButton, InfoButtonStyles);
InfoButton.displayName = "InfoButton";
