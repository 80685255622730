import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { ITabsStyles } from "./Tabs.types";

export const TabsStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<ITabsStyles>
> = (theme: IPartialTheme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
  },
  tabsBar: {
    backgroundColor: theme.palette?.white,
    height: "2.5rem",
  },
  tabsStack: {
    display: "flex",
    flex: 1,
    overflow: "auto",
    scrollbarWidth: "none",
    selectors: {
      "::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  content: {
    flex: 1,
    padding: "0",
    overflow: "auto",
  },
});
