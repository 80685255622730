import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IEpriStyles } from "./Epri.types";

export const EpriStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IEpriStyles>
> = (theme: IPartialTheme) => ({
  modal: {},
  overlay: {},
  dialog: {},
  content: {},
  section: {
    marginBottom: "2em",
  },
  headline: {
    fontWeight: "bold",
  },
  parameter: {
    paddingRight: "1em",
  },
  parameterName: {
    width: "30%",
  },
  parameterValue: {
    width: "60%",
  },
  tooltip: {},
  text: {},
  footer: {},
  acceptButton: {
    border: "none",
    borderRadius: "0",
    selectors: {
      ":focus&::after": {
        outlineOffset: "3",
        outlineColor: theme.semanticColors?.focusBorder,
      },
      ":hover": {
        border: "none",
      },
      ":active": {
        border: "none",
      },
    },
  },
  rejectButton: {
    border: "none",
    borderRadius: "0",
    marginLeft: ".5em",
    selectors: {
      ":focus&::after": {
        outlineOffset: "3",
        outlineColor: theme.semanticColors?.focusBorder,
      },
    },
  },
});
