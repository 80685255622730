import {
  GlobalNavButtonIcon,
  AddIcon,
  DeleteIcon,
} from "@fluentui/react-icons-mdl2";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { Separator } from "@fluentui/react/lib/Separator";
import { IStackTokens, Stack, StackItem } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, registerIcons } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import { isEqual } from "lodash";
import React from "react";

import {
  Constants as RestApiConstants,
  ParameterTO,
  Value,
} from "@encoway/c-services-js-client";
import { L10n } from "@encoway/l10n";
import { ComponentFactory, mediaLink } from "@encoway/react-configurator";

import { QuantityProps } from "../Quantity/Quantity.types";
import { ParameterMenuItem } from "../Section/Section.types";
import { ComponentName, NO_VALUE } from "../constants";
import {
  determineBinaryZeroValue,
  determineBinaryOneValue,
  isBinary,
} from "../helperFunctions";
import { OptionalPositionDisplayStyles } from "./OptionalPositionDisplay.styles";
import {
  OptionalPositionDisplayProps,
  IOptionalPositionDisplayStyles,
} from "./OptionalPositionDisplay.types";

const MENU = "GlobalNavButton";
const ACCEPT = "Accept";
const CLEAR = "Clear";
const DELETE = "Delete";
const OPTIONAL_POSITION = "OptionalPosition";
registerIcons(
  {
    icons: {
      [MENU]: <GlobalNavButtonIcon />,
      [DELETE]: <DeleteIcon />,
      [OPTIONAL_POSITION]: <AddIcon />,
    },
  },
  { disableWarnings: true },
);

const translate = (key: string) => {
  return L10n.format(key, undefined);
};

const OptionalPositionItem = (disabled: boolean, onClick?: () => void) => {
  return {
    key: "optionalPosition",
    iconProps: { iconName: OPTIONAL_POSITION },
    text: translate("Configuration.Optional.Position.label"),
    onClick,
    disabled,
    order: 1,
  } as ParameterMenuItem;
};

const StateIcon = (props: any) => {
  const stateiconProps = {
    ...props,
    onlyPlaceholder: true,
  };
  return ComponentFactory.instanceOf(ComponentName.StateIcon, stateiconProps);
};

interface ExtendedQuantityProps extends QuantityProps {
  visible: boolean;
}

const Quantity = (props: ExtendedQuantityProps) => {
  const { visible, ...delegatedProps } = props;
  return visible
    ? ComponentFactory.instanceOf(ComponentName.Quantity, delegatedProps)
    : null;
};

interface PositionPriceProps {
  price?: number;
  options?: Record<string, unknown>;
}

const PositionPrice = (props: PositionPriceProps) => {
  const { options, price } = props;
  const currency = options?.currency;
  const showPrices = options?.showPrices;

  return ComponentFactory.instanceOf(ComponentName.Price, {
    price,
    currency,
    showPrices,
  });
};

const InfoButton = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.InfoButton, props);
};

interface ValuesProps {
  parameter: ParameterTO;
  classNames: IProcessedStyleSet<IOptionalPositionDisplayStyles>;
  valueItems?: Value[];
  optionalValues: Record<string, number>;
  setOptional: (v: string, quantity: number) => void;
  options?: Record<string, unknown>;
  mediaLink?: mediaLink;
}

const Values = (valueProps: ValuesProps) => {
  const {
    classNames,
    valueItems,
    parameter,
    optionalValues,
    setOptional,
    ...delegatedProps
  } = valueProps;

  const renderValueItem = (value: Value) => {
    const onChange = (e: any, checked?: boolean) =>
      setOptional(
        value.value,
        checked ? value.quantity?.minimumQuantity || 1 : 0,
      );
    const currentQuantity = optionalValues[value.value];
    const checked = currentQuantity > 0;
    const quantity = {
      ...value.quantity,
      currentQuantity,
    };
    const stackTokens: IStackTokens = { childrenGap: 5 };
    return (
      <Stack
        key={value.value}
        className={classnames(
          "optionalPositionDisplayValueStack",
          classNames.value,
        )}
        tokens={stackTokens}
        horizontal
      >
        <Checkbox
          className={classnames(
            "optionalPositionDisplayCheckbox",
            classNames.checkbox,
          )}
          onChange={onChange}
          checked={checked}
          label={value.translatedValue}
        />
        <InfoButton
          className={"optionalPositionDisplayInfoButton"}
          data={value}
          {...delegatedProps}
        />
        <StackItem className={"optionalPositionDisplayStackItem"} grow={1}>
          <Quantity
            visible={checked}
            data={parameter}
            quantityId={`quantity-${parameter.id}-${value.value}`}
            quantity={quantity}
            ignoreEditable={!valueProps.options?.readOnly}
            onValueChanged={(_id, quantityValue) =>
              setOptional(value.value, quantityValue.quantity)
            }
          />
        </StackItem>
        <StackItem
          className={classnames(
            "optionalPositionDisplayPrice",
            classNames.price,
          )}
        >
          <PositionPrice price={value.price} {...delegatedProps} />
        </StackItem>
      </Stack>
    );
  };

  const renderValues =
    valueItems?.filter(isPossibleOptionalValue).map(renderValueItem) || null;

  return (
    <div
      className={classnames(
        "optionalPositionDisplayHeadlineWrapper",
        classNames?.section,
      )}
    >
      <Text
        variant="mediumPlus"
        className={classnames(
          "optionalPositionDisplayHeadline",
          classNames.headline,
        )}
      >
        {L10n.format("Configuration.Optional.Position.Dialog.Text")}
      </Text>
      <Separator className={"optionalPositionDisplayHeadlineSeparator"} />
      <Stack
        className={classnames(
          "optionalPositionDisplayValuesSection",
          classNames.section,
        )}
        tokens={{ childrenGap: "9px" }}
      >
        {renderValues}
      </Stack>
    </div>
  );
};

const getCurrentOptionalValues = (
  parameterProperties: Record<string, unknown>,
) => {
  const values: string | undefined =
    parameterProperties &&
    (parameterProperties["BomConfigurationConstants.OptionalValues"] as string);
  return (values ? JSON.parse(values) : {}) as Record<string, number>;
};

const getOptionalValues = (parameter: ParameterTO) => {
  const current: Record<string, number> = getCurrentOptionalValues(
    parameter.properties as Record<string, unknown>,
  );
  parameter.values
    ?.filter(isPossibleOptionalValue)
    .filter((v: Value) => current[v.value] === undefined)
    .map((v: Value) => v.value)
    .forEach((v: string) => {
      current[v] = 0;
    });
  return current;
};

const isPossibleOptionalValue = (v: Value) => {
  return !v.selected && v.value !== NO_VALUE;
};

/**
 * Displays everything for optional positions.
 */
const IOptionalPositionDisplay = (props: OptionalPositionDisplayProps) => {
  const { styles, theme, parameterMenuData, data, factory } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IOptionalPositionDisplayStyles>;

  const [isVisible, setIsVisible] = React.useState(false);
  const initialOptionalValues: Record<string, number> = React.useMemo(
    () => getOptionalValues(data),
    [data],
  );
  const [optionalValues, setOptionalValues] = React.useState(
    getOptionalValues(data),
  );
  const menuItem = React.useMemo(() => OptionalPositionItem(true), []);
  const valueComponent = factory && factory();
  if (!valueComponent) {
    return null;
  }

  if (isBinary(data)) {
    const values = data.values || [];
    const value = determineBinaryOneValue(
      values,
      determineBinaryZeroValue(values),
    );
    const quantity =
      value === undefined ||
      value.selected ||
      initialOptionalValues[value.value] > 0
        ? 0
        : value.quantity?.minimumQuantity || 1;

    menuItem.onClick = () => {
      const newState = { ...initialOptionalValues };
      if (value) {
        newState[value.value] = quantity;
      }
      submit(newState);
    };
    menuItem.disabled = quantity === 0;
  } else {
    menuItem.onClick = () => setIsVisible(true);
    menuItem.disabled = false;
  }
  parameterMenuData.add(menuItem);

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
  };
  const dialogContentProps = {
    type: DialogType.normal,
    showCloseButton: true,
    title: translate("Configuration.Optional.Position.label"),
  };

  const submit = (newState?: Record<string, number>) => {
    const update: Record<string, number> = {};
    const source = newState || optionalValues;
    for (const key in source) {
      const value = source[key];
      if (value > 0) {
        update[key] = value;
      }
    }
    const paramProps = {
      "BomConfigurationConstants.OptionalValues": JSON.stringify(update),
    };
    props.onValueChanged(
      data,
      { parameterProperties: paramProps },
      RestApiConstants.ValueFormat.Unformatted,
    );
    setIsVisible(false);
  };

  const discard = () => {
    setIsVisible(false);
    setOptionalValues(getOptionalValues(props.data));
  };

  const isSubmitDisabled = () => {
    return isEqual(optionalValues, initialOptionalValues);
  };

  const setOptionalValue = (v: string, quantity: number) => {
    setOptionalValues((prevState) => {
      const newState: Record<string, number> = { ...prevState };
      newState[v] = quantity;
      return newState;
    });
  };

  const deleteOptional = (v: Value) => {
    const update: Record<string, number> = {};
    for (const key in optionalValues) {
      if (key !== v.value) {
        const value = optionalValues[key];
        if (value > 0) {
          update[key] = value;
        }
      }
    }
    const paramProps = {
      "BomConfigurationConstants.OptionalValues": JSON.stringify(update),
    };
    props.onValueChanged(
      data,
      { parameterProperties: paramProps },
      RestApiConstants.ValueFormat.Unformatted,
    );
  };

  interface OptionalValuesProps {
    parameter: ParameterTO;
    classNames: IProcessedStyleSet<IOptionalPositionDisplayStyles>;
    valueItems?: Value[];
    optionalValues: Record<string, number>;
    setOptional: (v: string, quantity: number) => void;
    options?: Record<string, unknown>;
    mediaLink?: mediaLink;
  }

  const OptionalValues = (valueProps: OptionalValuesProps) => {
    const {
      classNames,
      valueItems,
      parameter,
      optionalValues,
      setOptional,
      ...delegatedProps
    } = valueProps;

    const deleteButtonClicked = (v: Value) => {
      setOptionalValue(v.value, 0);
      deleteOptional(v);
    };

    const deleteButton = (value: Value) => {
      if (valueProps.options?.readOnly) {
        return null;
      }

      return (
        <DefaultButton
          className={classnames(
            "optionalPositionDisplayDeleteButton",
            classNames.deleteButton,
          )}
          iconProps={{ iconName: DELETE }}
          onClick={() => deleteButtonClicked(value)}
        />
      );
    };

    const renderValueItem = (value: Value) => {
      const currentQuantity = optionalValues[value.value];
      const checked = currentQuantity > 0;
      const quantity = {
        ...value.quantity,
        currentQuantity,
      };
      const stackTokens: IStackTokens = { childrenGap: 5 };
      return (
        <Stack
          key={value.value}
          className={classnames(
            "optionalPositionDisplayValueStack",
            classNames.value,
          )}
          tokens={stackTokens}
          horizontal
        >
          <Stack
            horizontal
            className={classnames(
              "optionalPositionDisplayOptionalValueStack",
              classNames.optionalValues,
            )}
          >
            <Text className={"optionalPositionDisplayValueText"}>
              {value.translatedValue}
            </Text>
            <InfoButton
              className={"optionalPositionDisplayInfoButton"}
              data={value}
              {...delegatedProps}
            />
            <StackItem
              className={"optionalPositionDisplayItemWrapper"}
              grow={1}
            >
              <Quantity
                visible={checked}
                data={parameter}
                quantityId={`quantity-${parameter.id}-${value.value}`}
                quantity={quantity}
                ignoreEditable={!valueProps.options?.readOnly}
                onValueChanged={(_id, quantityValue) => {
                  const newState = {
                    ...optionalValues,
                  };
                  newState[value.value] = quantityValue.quantity;
                  submit(newState);
                  setOptional(value.value, quantityValue.quantity);
                }}
              />
            </StackItem>
            <StackItem
              className={classnames(
                "optionalPositionDisplayItemPrice",
                classNames.price,
              )}
            >
              <PositionPrice price={value.price} {...delegatedProps} />
            </StackItem>
          </Stack>
          <StateIcon
            className={"optionalPositionDisplayStateIcon"}
            {...props}
          />
          {deleteButton(value)}
        </Stack>
      );
    };

    const isOptionalValue = (v: Value) =>
      !v.selected && optionalValues[v.value] > 0;

    const renderValues =
      valueItems?.filter(isOptionalValue).map(renderValueItem) || null;

    const optionalHeader = (
      <div
        className={classnames(
          "optionalPositionDisplayTitleWrapper",
          classNames.optionalValuesTitle,
        )}
      >
        <Text
          className={classnames(
            "optionalPositionDisplayTitle",
            classNames.optionalValuesTitle,
          )}
        >
          {translate("Configuration.Optional.Position.Selected.Title")}
        </Text>
      </div>
    );

    return renderValues ? (
      <div
        className={classnames(
          "optionalPositionDisplaySection",
          classNames.section,
        )}
      >
        {renderValues.length > 0 ? optionalHeader : null}
        <Stack
          className={classnames(
            "optionalPositionDisplaySectionStack",
            classNames.section,
          )}
        >
          {renderValues}
        </Stack>
      </div>
    ) : null;
  };

  return (
    <>
      <Dialog
        className={"optionalPositionDisplayModal"}
        hidden={!isVisible}
        onDismiss={discard}
        minWidth={500}
        isDarkOverlay={false}
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}
      >
        <div
          className={classnames(
            "optionalPositionDisplayContent",
            classNames.content,
          )}
        >
          <Values
            parameter={data}
            classNames={classNames}
            valueItems={data?.values}
            optionalValues={optionalValues}
            setOptional={setOptionalValue}
            options={props.options}
            mediaLink={props.mediaLink}
          />
        </div>
        <DialogFooter
          className={classnames(
            "optionalPositionDisplayDialogFooter",
            classNames.footer,
          )}
        >
          <PrimaryButton
            onClick={() => submit()}
            disabled={isSubmitDisabled()}
            text={translate("Configuration.Optional.Position.Dialog.accept")}
            iconProps={{ iconName: ACCEPT }}
            className={classnames(
              "optionalPositionDisplayAcceptButton",
              classNames.acceptButton,
            )}
          />
          <DefaultButton
            onClick={discard}
            text={translate("Configuration.Optional.Position.Dialog.reject")}
            iconProps={{ iconName: CLEAR }}
            className={classnames(
              "optionalPositionDisplayRejectButton",
              classNames.rejectButton,
            )}
          />
        </DialogFooter>
      </Dialog>
      {valueComponent}
      <div
        className={classnames(
          "optionalPositionDisplayOptionalContent",
          classNames.content,
        )}
      >
        <OptionalValues
          parameter={data}
          classNames={classNames}
          valueItems={data?.values}
          optionalValues={initialOptionalValues}
          setOptional={setOptionalValue}
          options={props.options}
          mediaLink={props.mediaLink}
        />
      </div>
    </>
  );
};

export const OptionalPositionDisplay = styled(
  IOptionalPositionDisplay,
  OptionalPositionDisplayStyles,
);
OptionalPositionDisplay.displayName = "OptionalPositionDisplay";
