import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IStateIconStyles } from "./StateIcon.types";

export const StateIconStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IStateIconStyles>
> = (theme: IPartialTheme) => ({
  root: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  callout: {
    padding: "1em",
  },
  icon: {
    color: theme.palette?.neutralTertiary,
  },
  warningIcon: {
    color: theme.semanticColors?.warningIcon,
  },
  errorIcon: {
    color: theme.semanticColors?.errorIcon,
  },
});
