import { comparator, gt, path, pathOr, reduce, sort } from "ramda";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ParameterTO,
  PossibleValue,
  Value,
} from "@encoway/c-services-js-client";

import { useProductContext } from "../context/useProduct";

type Sorting = { [key: string]: { [key: string]: number } };

function getSortedValues(sorting: { [key: string]: number }, values: Value[]) {
  const getSortValue = (value: Value) =>
    pathOr(Number.MAX_VALUE, [value.value], sorting);
  const productComparator = comparator(
    (a: Value, b: Value) => getSortValue(a) < getSortValue(b),
  );
  return sort(productComparator, values);
}

export function useSortParameter(parameters: ParameterTO[]): Sorting {
  const { article } = useParams();
  const { products } = useProductContext();
  const [sorting, setSorting] = useState<Sorting>({});
  useEffect(() => {
    (async () => {
      if (gt(parameters.length, 0)) {
        const sortValues = reduce(
          (acc: Sorting, parameterTO) => {
            const possibleValues = path<{ [key: string]: PossibleValue }>(
              [parameterTO.name, "possibleValues"],
              products[article!].characteristicValues,
            );
            if (possibleValues) {
              const charSorting = reduce(
                (acc1, value) => ({
                  ...acc1,
                  [value.value]: pathOr(
                    0,
                    [
                      value.value,
                      "characteristicValues",
                      "C_SORT",
                      "values",
                      0,
                      "value",
                    ],
                    possibleValues,
                  ),
                }),
                {},
                parameterTO.values!,
              );
              return { ...acc, ...{ [parameterTO.name]: charSorting } };
            }
            return acc;
          },
          {},
          parameters,
        );
        setSorting(sortValues);
      }
    })();
  }, [parameters]);

  return reduce(
    (acc, parameter) => {
      return {
        ...acc,
        [parameter.name]: getSortedValues(
          sorting[parameter.name],
          parameter.values!,
        ),
      };
    },
    {},
    parameters,
  );
}
