import { includes } from "ramda";

import { ParameterTO } from "@encoway/c-services-js-client";

import { SETTINGS } from "../../../settings";
import { equals1, getCustomViewportProperty } from "../configuratorUtils";
import { CHECKBOX_POSITIONS, getCheckboxSide } from "./ConfCheckboxUtils";

export function isCharacteristicLabelHidden(data: ParameterTO) {
  const checkBoxSide = getCheckboxSide(data);
  if (
    includes(checkBoxSide, [CHECKBOX_POSITIONS.START, CHECKBOX_POSITIONS.END])
  ) {
    return true;
  }
  return equals1(
    getCustomViewportProperty(
      data,
      SETTINGS.configuration.viewportProperties.hideCharacteristicLabel,
    ),
  );
}
