import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IMessageStyles } from "./Message.types";

export const MessageStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IMessageStyles>
> = () => ({
  root: {},
});
