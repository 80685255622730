import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { ITabButtonStyles } from "./TabButton.types";

export const TabButtonStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<ITabButtonStyles>
> = () => ({
  button: {
    border: "none",
    borderRadius: 0,
    minWidth: 0,
    height: "100%",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
  disabledButton: {},
});
