import { any, equals, isEmpty, keys, pathOr, reduce, values } from "ramda";

import {
  Characteristic,
  CharacteristicValue,
} from "@encoway/c-services-js-client";

import { Categories, Category } from "../types/category";
import {
  ExtendedCharacteristic,
  ExtendedProduct,
  Products,
} from "../types/product";

export function toExtendedCharacteristic(characteristic: {
  [characteristicId: string]: CharacteristicValue;
}) {
  return function (acc: {}, ele: Characteristic): ExtendedCharacteristic {
    return {
      ...acc,
      [ele.id]: {
        ...characteristic[ele.id],
        ...ele,
      },
    };
  };
}

export function matchCategory(
  id: string | number,
  group: Categories,
): Partial<Category> {
  return reduce(
    (acc: Partial<Category>, groupId): Partial<Category> => {
      if (isEmpty(acc)) {
        if (equals(id, group[groupId].id)) {
          return group[groupId];
        }
        return matchCategory(id, group[groupId].categories);
      }
      return acc;
    },
    {},
    keys(group),
  );
}

export function matchProduct(
  id: keyof Products,
  group: Categories,
): Partial<Category> {
  return reduce(
    (acc: Partial<Category>, category: Category): Partial<Category> => {
      if (isEmpty(acc)) {
        if (any(equals(id), category.products)) {
          return category;
        }
        return matchProduct(id, category.categories);
      }
      return acc;
    },
    {},
    values(group),
  );
}

export function getCharacteristicValue(
  product: ExtendedProduct | undefined,
  characteristic: string,
): string {
  return `${pathOr("", ["characteristicValues", characteristic, "values", 0], product)}`;
}
