import { DefaultButton } from "@fluentui/react/lib/Button";
import { Stack, StackItem } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { TooltipDelay, TooltipHost } from "@fluentui/react/lib/Tooltip";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React from "react";

import { ComponentFactory } from "@encoway/react-configurator";

import { ComponentName, READY_STATE_NOT_READY } from "../constants";
import { containsVisibleParameter } from "../helperFunctions";
import { TabStyles } from "./Tab.styles";
import { ITabStyles, TabProps } from "./Tab.types";

/**
 * The Tab component renders the title of a ContainerTO as an MS Fluent Default Button.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Tab/Tab.tsx)
 * - [TabStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Tab/Tab.styles.ts)
 * - [TabProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Tab/Tab.types.ts)
 * - [MS Fluent Tooltip](https://developer.microsoft.com/de-DE/fluentui#/controls/web/tooltip)
 * - [MS Fluent Button](https://developer.microsoft.com/de-DE/fluentui#/controls/web/button)
 * - [MS Fluent Stack](https://developer.microsoft.com/de-DE/fluentui#/controls/web/stack)
 * - [MS Fluent Text](https://developer.microsoft.com/de-DE/fluentui#/controls/web/text)
 *
 * @visibleName Tab
 */
const ITab = (props: TabProps) => {
  const { styles, theme, data } = props;
  const classNames: IProcessedStyleSet<ITabStyles> = classNamesFunction()(
    styles,
    theme,
  );
  const id = `encoway-cui-tab-${props.data.id}`;
  const isNotReady = data.readyState === READY_STATE_NOT_READY;

  if (!containsVisibleParameter(data)) {
    return null;
  }

  let className = classNames.tab;
  if (isNotReady) {
    className = mergeStyles(className, classNames.notReady);
  }
  if (props.selected) {
    className = mergeStyles(className, classNames.selected);
  }

  const notReadyCount = ComponentFactory.instanceOf(
    ComponentName.NotReadyCount,
    { data: props.data },
  );

  return (
    <TooltipHost
      className={"tabTooltipHost"}
      content={props.data.translatedName}
      delay={TooltipDelay.long}
    >
      <DefaultButton
        id={id}
        className={classnames("tabTooltipButton", className)}
        onClick={() => props.onClick?.(props.data)}
      >
        <Stack
          className={"tabTooltipHostStack"}
          grow={1}
          horizontal
          tokens={{ childrenGap: "0.5em" }}
        >
          <StackItem
            grow={1}
            className={classnames("tabTooltipHostTextWrapper", classNames.text)}
          >
            <Text
              className={classnames("tabTooltipHostText", classNames.text)}
              nowrap
            >
              {props.data.translatedName}
            </Text>
          </StackItem>
          {isNotReady && notReadyCount}
        </Stack>
      </DefaultButton>
    </TooltipHost>
  );
};

/**
 * The Tab component renders the title of a ContainerTO as an MS Fluent Default Button.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Tab/Tab.tsx)
 * - [TabStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Tab/Tab.styles.ts)
 * - [TabProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Tab/Tab.types.ts)
 * - [MS Fluent Tooltip](https://developer.microsoft.com/de-DE/fluentui#/controls/web/tooltip)
 * - [MS Fluent Button](https://developer.microsoft.com/de-DE/fluentui#/controls/web/button)
 * - [MS Fluent Stack](https://developer.microsoft.com/de-DE/fluentui#/controls/web/stack)
 * - [MS Fluent Text](https://developer.microsoft.com/de-DE/fluentui#/controls/web/text)
 *
 * @visibleName Tab
 */
export const Tab = styled(ITab, TabStyles);
Tab.displayName = "Tab";
