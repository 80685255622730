import { FocusZone } from "@fluentui/react/lib/FocusZone";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { IPageProps, List } from "@fluentui/react/lib/List";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React from "react";

import {
  Constants as RestApiConstants,
  specState,
  Value,
} from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { InfoButtonProps } from "../InfoButton/InfoButton.types";
import { ParameterProps } from "../Parameter/Parameter.types";
import {
  ComponentName,
  READY_STATE_NOT_READY,
  SPEC_STATE_SET_BY_SYSTEM,
} from "../constants";
import { getDisplayValue } from "../helperFunctions";
import { DetailButtonStyles } from "./DetailButton.styles";
import {
  DetailButtonClassNames,
  DetailButtonOption,
  DetailButtonProps,
  IDetailButtonStyles,
} from "./DetailButton.types";
import placeholderIcon from "./placeholder_icon.png";

const InfoButton = (props: InfoButtonProps) =>
  ComponentFactory.instanceOf(ComponentName.InfoButton, props);

const Parameter = (props: ParameterProps) =>
  ComponentFactory.instanceOf(ComponentName.Parameter, props);

function IDetailButton(props: DetailButtonProps) {
  const { styles, theme, data, imageResolution, ...delegatedProps } = props;
  const classNames: IProcessedStyleSet<IDetailButtonStyles> =
    classNamesFunction()(styles, theme);
  const readOnly = !data.editable || props.options?.readOnly;
  const specStateValue = specState(data);
  const isMandatory = data.readyState === READY_STATE_NOT_READY;

  const changeValue = (newValue: Value) => {
    if (!newValue.selected) {
      props.onValueChanged(
        data,
        newValue.value,
        RestApiConstants.ValueFormat.Unformatted,
      );
    }
  };

  function createInfoButton(className: string, item?: Value) {
    return (
      <div className={classnames("detailButtonInfoDiv", className)}>
        <InfoButton {...delegatedProps} data={item || ({} as Value)} />
      </div>
    );
  }

  function createPrice(className: string, item?: DetailButtonOption) {
    const { options, ...otherProps } = props;
    if (item?.price === undefined || (readOnly && !item?.selected)) return null;

    const priceProps = {
      price: item?.price,
      currency: options?.currency,
      showPrices: options?.showPrices,
      ...otherProps,
    };

    return (
      <Text className={classnames("detailButtonPriceText", className)}>
        {ComponentFactory.instanceOf(ComponentName.Price, priceProps)}
      </Text>
    );
  }

  const classes: DetailButtonClassNames = {
    rootClass: classNames.root || "",
    listClass: classNames.list || "",
    detailButtonClass: "",
    rightContainerClass: classNames.rightContainer || "",
    imageContainerClass: "",
    upperRowClass: classNames.upperRow || "",
    lowerRowClass: classNames.lowerRow || "",
    textClass: "",
    shortTextClass: "",
    priceClass: "",
    infoButtonClass: "",
  };

  const updateClasses = (value: Value) => {
    let detailButtonClass = classNames.detailButton;
    let imageContainerClass = classNames.imageContainer;
    let textClass = classNames.text;
    let shortTextClass = classNames.shortText;
    let priceClass = classNames.price;
    let infoButtonClass = classNames.infoButton;
    let setNotSelectedClass = false;

    if (isMandatory && !readOnly) {
      detailButtonClass = mergeStyles(
        detailButtonClass,
        classNames.detailButtonNotReady,
      );
      if (!value.selectable) setNotSelectedClass = true;
    } else if (readOnly || !value.enabled) {
      detailButtonClass = mergeStyles(
        detailButtonClass,
        classNames.detailButtonDisabled,
      );
      imageContainerClass = mergeStyles(
        imageContainerClass,
        classNames.imageContainerDisabled,
      );
      textClass = mergeStyles(textClass, classNames.textDisabled);
      shortTextClass = mergeStyles(
        shortTextClass,
        classNames.shortTextDisabled,
      );
      infoButtonClass = mergeStyles(
        infoButtonClass,
        classNames.infoButtonDisabled,
      );
      priceClass = mergeStyles(priceClass, classNames.priceDisabled);
      if (value.selected) {
        detailButtonClass = mergeStyles(
          detailButtonClass,
          classNames.detailButtonSelectedDisabled,
        );
      }
    } else if (value.selected) {
      detailButtonClass = mergeStyles(
        detailButtonClass,
        classNames.detailButtonSelected,
      );
      textClass = mergeStyles(textClass, classNames.textSelected);
      shortTextClass = mergeStyles(
        shortTextClass,
        classNames.shortTextSelected,
      );
      priceClass = mergeStyles(priceClass, classNames.priceSelected);
      infoButtonClass = mergeStyles(
        infoButtonClass,
        classNames.infoButtonSelected,
      );
    } else if (!value.selectable) {
      setNotSelectedClass = true;
    } else if (specStateValue === SPEC_STATE_SET_BY_SYSTEM) {
      imageContainerClass = classNames.imageContainerConflict;
    }

    if (setNotSelectedClass) {
      imageContainerClass = mergeStyles(
        imageContainerClass,
        classNames.imageContainerNotSelected,
      );
      textClass = mergeStyles(textClass, classNames.textNotSelected);
      shortTextClass = mergeStyles(
        shortTextClass,
        classNames.shortTextNotSelected,
      );
      priceClass = mergeStyles(priceClass, classNames.priceNotSelected);
    }

    classes.detailButtonClass = detailButtonClass || "";
    classes.imageContainerClass = imageContainerClass || "";
    classes.textClass = textClass || "";
    classes.shortTextClass = shortTextClass || "";
    classes.priceClass = priceClass || "";
    classes.infoButtonClass = infoButtonClass || "";
  };

  const defaultRenderText = (item?: DetailButtonOption) => {
    const translatedValue = getDisplayValue(
      item?.translatedValue,
      data.displayUnit,
    );
    const shortText = item?.shortText;

    const infoButton = createInfoButton(classes.infoButtonClass, item);
    const price = createPrice(classes.priceClass, item);

    return (
      <Stack
        className={classnames(
          "detailButtonRightContainer",
          classes.rightContainerClass,
        )}
      >
        <Stack
          horizontal
          className={classnames("detailButtonUpperRow", classes.upperRowClass)}
          tokens={{ childrenGap: "0.5em" }}
        >
          <Text
            block
            nowrap={false}
            className={classnames(
              "detailButtonUpperRowText",
              classes.textClass,
            )}
          >
            {translatedValue}
          </Text>
          {infoButton}
          {price}
        </Stack>
        <Stack
          horizontal
          className={classnames("detailButtonLowerRow", classes.lowerRowClass)}
        >
          <Text
            block
            nowrap={false}
            className={classnames(
              "detailButtonLowerRowShortText",
              classes.shortTextClass,
            )}
          >
            {shortText}
          </Text>
        </Stack>
      </Stack>
    );
  };

  const onRenderCellCallback = React.useCallback(
    (item: DetailButtonOption) => {
      const image = item.imageUrl as string;
      const imageSrc = image
        ? props.mediaLink(image, imageResolution || "small")
        : placeholderIcon;
      const imageSize = props.imageSize ? props.imageSize : "8rem";
      const enabled = !readOnly && item.enabled;
      let preventFocusWhenDisabled;
      if (!enabled) {
        preventFocusWhenDisabled = (e: React.MouseEvent) => {
          e.preventDefault();
        };
      }
      updateClasses(item);
      return (
        <Stack
          horizontal
          key={item.value}
          onClick={() => enabled && changeValue(item)}
          onMouseDown={preventFocusWhenDisabled}
          data-is-focusable={enabled}
          className={classnames("detailButtonCell", classes.detailButtonClass)}
        >
          <Stack.Item
            className={classnames(
              "detailButtonCellImageContainer",
              classes.imageContainerClass,
            )}
          >
            <Image
              className={"detailButtonCellImage"}
              src={imageSrc}
              imageFit={ImageFit.centerContain}
              width={imageSize}
              height={imageSize}
            />
          </Stack.Item>
          <Stack.Item
            style={{ width: "calc(100% - 0.1rem)" }}
            className={"detailButtonCellText"}
          >
            {props.onRenderText
              ? props.onRenderText(item, defaultRenderText)
              : defaultRenderText(item)}
          </Stack.Item>
        </Stack>
      );
    },
    [data],
  );

  const onRenderPageCallback = React.useCallback(
    (pageProps: IPageProps<Value> | undefined) => {
      return (
        <FocusZone key={data.id} className={"detailButtonFocusZone"}>
          <Stack
            horizontal
            wrap
            tokens={{ childrenGap: "1rem" }}
            className={"detailButtonFocusStack"}
          >
            {pageProps?.page?.items?.map(onRenderCellCallback)}
          </Stack>
        </FocusZone>
      );
    },
    [onRenderCellCallback],
  );

  return (
    <Stack className={classnames("detailButtonListRoot", classes.rootClass)}>
      <Parameter data={data} {...delegatedProps} />
      <List
        className={classnames("detailButtonList", classes.listClass)}
        items={data.values}
        getItemCountForPage={() => data.values?.length || 0}
        onRenderPage={onRenderPageCallback}
      />
    </Stack>
  );
}

/**
 * Renders a DetailButton for the possible values of a ParameterTO.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/DetailButton/DetailButton.tsx)
 * - [DetailButtonStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/DetailButton/DetailButton.styles.ts)
 * - [DetailButtonProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/DetailButton/DetailButton.types.ts)
 *
 * @visibleName DetailButton
 */
export const DetailButton = styled(IDetailButton, DetailButtonStyles);
DetailButton.displayName = "DetailButton";
