import { mergeStyleSets } from "@fluentui/react";
import classNames from "classnames";
import { length, map, path, pathOr } from "ramda";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";

import { SideBarContext } from "../../../context/useSidebar";

const summaryStyled = mergeStyleSets({
  entryName: {
    fontWeight: 600,
  },
  entryValue: {
    padding: ".2em",
  },
});

function SummaryEntry({ parameterTO }: { parameterTO: ParameterTO }) {
  const { entryName, entryValue } = summaryStyled;
  const { t } = useTranslation();
  const value = pathOr(
    t("t:summary_no_value"),
    ["selectedValues", 0, "translatedValue"],
    parameterTO,
  );
  const unit = path(["baseUnit", "translatedUnit"], parameterTO);
  return (
    <li className={classNames("sidebarSummaryEntry", entryValue)}>
      <span className={classNames("sideBarSummaryEntryName", entryName)}>
        {parameterTO.translatedName}
      </span>
      <span className={classNames("sidebarSummaryEntrySpacer", entryName)}>
        :
      </span>
      <span className={classNames("sideBarSummaryEntryValue", entryValue)}>
        {value}
      </span>
      {unit && (
        <span className={classNames("sideBarSummaryUnit", entryValue)}>
          {unit}
        </span>
      )}
    </li>
  );
}

export function SidebarSummary() {
  const { summary } = useContext(SideBarContext);

  if (summary) {
    return (
      <>
        {map(
          (containerTO) => (
            <>
              <h2 className="sidebarSummaryHeading">
                {containerTO.translatedName}
              </h2>
              <ul className="sidebarSummary">
                {map(
                  (parameterTO) => (
                    <SummaryEntry
                      key={parameterTO.name}
                      parameterTO={parameterTO}
                    />
                  ),
                  containerTO.parameters,
                )}
              </ul>
            </>
          ),
          summary.children,
        )}
        {length(summary.parameters) > 0 && (
          <ul className="sidebarSummary">
            {map(
              (parameterTO) => (
                <SummaryEntry
                  key={parameterTO.name}
                  parameterTO={parameterTO}
                />
              ),
              summary.parameters,
            )}
          </ul>
        )}
      </>
    );
  }
  return null;
}
