import { DirectionalHint } from "@fluentui/react";
import { FilterIcon } from "@fluentui/react-icons-mdl2";
import { Callout, ICalloutProps } from "@fluentui/react/lib/Callout";
import { IDropdownProps as IFluentDropdownProps } from "@fluentui/react/lib/Dropdown";
import { IIconProps } from "@fluentui/react/lib/Icon";
import { ISearchBox, SearchBox } from "@fluentui/react/lib/SearchBox";
import {
  IProcessedStyleSet,
  IStyle,
  mergeStyles,
  registerIcons,
} from "@fluentui/react/lib/Styling";
import {
  classNamesFunction,
  getId,
  IRenderFunction,
  styled,
} from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import { merge } from "lodash";
import React, {
  FocusEvent,
  ForwardedRef,
  KeyboardEvent,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import { Value } from "@encoway/c-services-js-client";
import { L10n } from "@encoway/l10n";

import { Dropdown } from "../Dropdown/Dropdown";
import { DropdownStyles } from "../Dropdown/Dropdown.styles";
import { IDropdownWithState, onRenderItem } from "../Dropdown/Dropdown.types";
import { VIEWPORT_PROPERTY_VALUE } from "../constants";
import {
  IFilterDropdownProps,
  IFilterDropdownStyles,
} from "./FilterDropdown.types";

const FILTER = "Filter";
registerIcons(
  {
    icons: {
      [FILTER]: <FilterIcon />,
    },
  },
  { disableWarnings: true },
);

const searchBoxBaseStyles: IStyle = {
  minHeight: "2.0rem",
  display: "flex",
  alignItems: "center",
  marginBottom: "-2.0rem",
  position: "relative",
  zIndex: "999 !important",
};

const searchBoxDefault = mergeStyles(
  {
    visibility: "hidden",
  },
  searchBoxBaseStyles,
);

const searchBoxVisible = mergeStyles(
  {
    visibility: "visible",
  },
  searchBoxBaseStyles,
);

const searchRegExp = (searchValue?: string) => {
  if (searchValue && searchValue.length > 0) {
    const escapedString = searchValue
      .replace(/[.+?^${}()|[\]\\]/g, "\\$&")
      .replace(/[* ]/g, "(.*)");
    return new RegExp(`${escapedString}`, "i");
  }
};

const filterOptions = (options?: Value[], filterRegExp?: RegExp) => {
  // A) no options -> []
  // B) no filter value -> all original options
  // C) filtered options

  let filteredOptions = options;
  if (options && filterRegExp) {
    filteredOptions = options.filter((option) =>
      option.translatedValue.match(filterRegExp),
    );
  }
  return filteredOptions || [];
};

const determineMaxOptionsCount = (
  max: number,
  limitEnabled: boolean,
  viewportProperty?: string,
) => {
  let resultMax = max;
  if (limitEnabled && viewportProperty) {
    const matched = viewportProperty.match(/\{([\d.]+)}/);
    if (matched && matched.length === 2) {
      const value = matched[1];
      const index = value.indexOf(".");
      const parsed = parseInt(
        value.substring(0, index < 0 ? value.length : index),
      );
      if (!isNaN(parsed)) {
        resultMax = parsed;
      }
    }
  }
  return resultMax;
};

const ID_PREFIX = "encoway-search-dropdrown-wrapper";

interface ISearchBoxState {
  className: string;
  hasFocus: boolean;
}

interface ViewportProperties {
  FILTER_DROPDOWN_LIMITED?: string;
  FILTER_DROPDOWN_MAX_OPTIONS?: string;
}

interface SearchInfo {
  value?: string;
  test?: RegExp;
}

/**
 * Renders a FilterDropdown for the possible values of a ParameterTO.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/FilterDropdown/FilterDropdown.tsx)
 * - [IFilterDropdownStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/FilterDropdown/FilterDropdown.styles.ts)
 * - [IFilterDropdownProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/FilterDropdown/FilterDropdown.types.ts)
 * - [MS Fluent Dropdown](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dropdown)
 *
 * @visibleName FilterDropdown
 */
function IFilterDropdown(
  props: IFilterDropdownProps,
  dropdownForwardRef: ForwardedRef<IDropdownWithState>,
) {
  const {
    // Maximum number of options shown in the filterdropdown list.
    styles,
    theme,
    data,
    onRenderInputComponent,
    calloutProps,
    focusProps,
    ...delegatedProps
  } = props;
  const viewPortProperties = props.data
    .viewPortProperties as ViewportProperties;
  const limitOptions =
    viewPortProperties?.FILTER_DROPDOWN_LIMITED ===
    VIEWPORT_PROPERTY_VALUE.True;
  const [showOptionsMaxCount] = useState(
    determineMaxOptionsCount(
      data.values?.length || 0,
      limitOptions,
      viewPortProperties?.FILTER_DROPDOWN_MAX_OPTIONS,
    ),
  );
  const [dropdownData, setDropdownData] = useState({ ...data });
  const [invisibleOptions, setInvisibleOptions] = useState<string[]>([]);
  const [lastSearchValueSearch, setLastSearchValueSearch] =
    useState<SearchInfo>({} as SearchInfo);
  const [maxOptionsInfoIsVisible, setMaxOptionsInfoIsVisible] = useState(false);
  const [countFilteredOptions, setCountFilteredOptions] = useState(
    data.values?.length || 0,
  );
  const [searchBoxWrapperId] = useState(getId(ID_PREFIX));

  const [searchBoxState, setSearchBoxState] = useState<ISearchBoxState>({
    className: searchBoxDefault,
    hasFocus: false,
  });
  // (Sometimes) required to trigger a re-filtering when the selection is changed
  const currentSelection =
    data.values
      ?.filter((v) => v.selected)
      .map((v) => v.value)
      .join() || "";
  const classNames: IProcessedStyleSet<IFilterDropdownStyles> =
    classNamesFunction()(styles, theme);

  // refs
  const newDropDownRef = useRef<IDropdownWithState>(null);
  const dropdownRef =
    (dropdownForwardRef as MutableRefObject<IDropdownWithState>) ||
    newDropDownRef;

  const searchBoxRef = useRef<ISearchBox>(null);

  // Settings for the SearchBox.
  const filterIconSearchBox: IIconProps = { iconName: FILTER };

  const maxOptionsInfo =
    maxOptionsInfoIsVisible &&
    searchBoxState.hasFocus &&
    L10n.format("Configuration.FilterDropdown.OptionsInfo", {
      showMaxOptions: showOptionsMaxCount,
      countOptions: countFilteredOptions,
    });

  const toggleSearchBox = () => {
    const isDropdownOpen: boolean = dropdownRef.current?.state?.isOpen;
    setSearchBoxState({
      className: isDropdownOpen ? searchBoxVisible : searchBoxDefault,
      hasFocus: isDropdownOpen,
    });
  };
  /**
   * When the value list changes (e.g. via the configuration) or
   * when the filter/search-string changes,
   * we need to update the value list of the underlying dropdown,
   * which is restricted by the filter/search-string entered by the user.
   */
  useEffect(() => {
    const newData = merge({}, data); // deep-copy
    const filteredValues = filterOptions(
      data.values,
      lastSearchValueSearch.test,
    );
    // what we wanna see (cut of filtered values with options max count)
    const visibleValues = limitOptions
      ? filteredValues.slice(0, showOptionsMaxCount)
      : filteredValues;
    // what we additionally need in the background
    // - all selected values NOT in visible values
    const invisibleValues =
      data.values?.filter((v) => v.selected && visibleValues.indexOf(v) < 0) ||
      [];

    // for the UI
    setCountFilteredOptions(filteredValues.length);

    newData.values = [...invisibleValues, ...visibleValues];

    // remember the ones that should be hidden
    setInvisibleOptions(invisibleValues.map((v) => v.value));
    setMaxOptionsInfoIsVisible(filteredValues.length > showOptionsMaxCount);
    setDropdownData(newData);
  }, [data.values, lastSearchValueSearch, currentSelection]);

  useEffect(() => {
    if (dropdownRef.current && searchBoxState.hasFocus) {
      // Without setTimeout the SearchBox looses focus after being opened, and it starts to flicker.
      setTimeout(() => {
        searchBoxRef.current?.focus();
      }, 50);
    }
  }, [searchBoxState]);

  const onSearching = (
    _event?: React.ChangeEvent<HTMLInputElement>,
    searchValue?: string,
  ) => {
    if (lastSearchValueSearch.value !== searchValue) {
      setLastSearchValueSearch({
        value: searchValue,
        test: searchRegExp(searchValue),
      });
    }
  };

  // Needed a wrapper for the SearchBox and FluentDropdown, to display the combined components like before.
  const renderDropdown: IRenderFunction<IFluentDropdownProps> = (
    dropdownProps,
    defaultRender,
  ) => (
    <div style={{ width: "100%" }} className={"filterDropdownRootDiv"}>
      <div
        className={classnames(
          "filterDropdownSearchBoxContainer",
          classNames.searchBoxContainer,
        )}
        id={searchBoxWrapperId}
      >
        <SearchBox
          className={classnames(
            "filterDropdownSearchBox",
            mergeStyles(classNames.searchBox, searchBoxState.className),
          )}
          autoComplete="off"
          showIcon={true}
          componentRef={searchBoxRef}
          placeholder={FILTER}
          iconProps={filterIconSearchBox}
          onChange={onSearching}
          onKeyUp={(e) => {
            if (e.code === "ArrowDown" && dropdownRef.current) {
              setSearchBoxState({
                className: searchBoxState.className,
                hasFocus: false,
              });
              dropdownRef.current.focus();
              e.preventDefault();
            }
          }}
        />
        {maxOptionsInfo && (
          <Callout
            className={"filterDropdownCallout"}
            style={{ padding: "10px 12px" }}
            target={`#${searchBoxWrapperId}`}
            directionalHint={
              dropdownRef.current?.state.calloutRenderEdge === 1
                ? DirectionalHint.bottomLeftEdge
                : DirectionalHint.topLeftEdge
            }
            gapSpace={3}
          >
            {maxOptionsInfo}
          </Callout>
        )}
      </div>
      {defaultRender && defaultRender(dropdownProps)}
    </div>
  );

  const renderInputComponent: IRenderFunction<IFluentDropdownProps> = (
    ddProps,
    defaultRender,
  ) => {
    if (onRenderInputComponent) {
      return onRenderInputComponent(ddProps, (p) =>
        renderDropdown(p, defaultRender),
      );
    }
    return renderDropdown(ddProps, defaultRender);
  };

  const propsPreventDismissOnEvent: (
    ev: Event | FocusEvent | KeyboardEvent | MouseEvent,
  ) => boolean = (e) =>
    (calloutProps?.preventDismissOnEvent &&
      calloutProps.preventDismissOnEvent(e)) ||
    false;

  const myCalloutProps: ICalloutProps = {
    // Don't close the FilterDropdown options list when searchable and the SearchBox has focus.
    preventDismissOnEvent: (event) =>
      (typeof (event.target as HTMLDivElement)?.closest === "function"
        ? (event.target as HTMLDivElement).closest(`#${searchBoxWrapperId}`) !==
          null
        : false) || propsPreventDismissOnEvent(event),
    // When the FilterDropdown option list opens or close, then show / hide the SearchBox.
    onLayerMounted: calloutProps?.onLayerMounted
      ? () => {
          toggleSearchBox();
          calloutProps.onLayerMounted && calloutProps.onLayerMounted();
        }
      : toggleSearchBox,
    onRestoreFocus: calloutProps?.onRestoreFocus
      ? (p) => {
          toggleSearchBox();
          calloutProps.onRestoreFocus && calloutProps.onRestoreFocus(p);
        }
      : toggleSearchBox,
  };

  const onRenderItemFiltered: onRenderItem = (
    option,
    changeValue,
    defaultRender,
  ) => {
    if (
      // performance improvement (only selected values can be invisible)
      option.selected &&
      invisibleOptions.indexOf(`${option.key}`) >= 0
    ) {
      return null;
    } else if (props.onRenderItem) {
      return props.onRenderItem(option, changeValue, defaultRender);
    }
    return defaultRender(option, changeValue, true);
  };
  return (
    <Dropdown
      data={dropdownData}
      onRenderInputComponent={renderInputComponent}
      focusProps={merge({}, focusProps || {}, {
        forceFocusInsideTrap: false,
        isClickableOutsideFocusTrap: true,
      })}
      calloutProps={merge({}, calloutProps || {}, myCalloutProps)}
      styles={styles}
      theme={theme}
      ref={dropdownRef}
      {...delegatedProps}
      onRenderItem={onRenderItemFiltered}
    />
  );
}

const IFilterDropdownWithForwardRef = React.forwardRef(
  (
    props: IFilterDropdownProps,
    dropdownForwardRef: ForwardedRef<IDropdownWithState>,
  ) => IFilterDropdown(props, dropdownForwardRef),
);
IFilterDropdownWithForwardRef.displayName = "FilterDropdown";

export const FilterDropdown = styled(
  IFilterDropdownWithForwardRef,
  DropdownStyles,
);
