import {
  CompletedIcon,
  ContactIcon,
  ErrorBadgeIcon,
  ThisPCIcon,
  WarningIcon,
} from "@fluentui/react-icons-mdl2";
import { Callout, DirectionalHint } from "@fluentui/react/lib/Callout";
import { Icon } from "@fluentui/react/lib/Icon";
import { StackItem } from "@fluentui/react/lib/Stack";
import {
  IProcessedStyleSet,
  mergeStyles,
  registerIcons,
} from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { useState, useEffect } from "react";

import { ParameterTO, specState } from "@encoway/c-services-js-client";
import { L10n } from "@encoway/l10n";

import { ERROR_STATE, READY_STATE_NOT_READY } from "../constants";
import { StateIconStyles } from "./StateIcon.styles";
import { StateIconProps, IStateIconStyles } from "./StateIcon.types";

const defaultIcons = {
  NOT_READY: "Warning",
  NOT_SET: "",
  SET_BY_DEFAULT: "Completed",
  SET_BY_SYSTEM: "ThisPC",
  SET_BY_USER: "Contact",
  ERROR: "ErrorBadge",
};

registerIcons(
  {
    icons: {
      [defaultIcons.NOT_READY]: <WarningIcon />,
      [defaultIcons.SET_BY_DEFAULT]: <CompletedIcon />,
      [defaultIcons.SET_BY_SYSTEM]: <ThisPCIcon />,
      [defaultIcons.SET_BY_USER]: <ContactIcon />,
      [defaultIcons.ERROR]: <ErrorBadgeIcon />,
    },
  },
  { disableWarnings: true },
);

const getSelectedValue = (parameterTO: ParameterTO) => {
  if (parameterTO.terminal) {
    return parameterTO.values?.find((v) => v?.selected)?.translatedValue;
  }
};
interface StateCalloutProps {
  iconID: string;
  classNames: IProcessedStyleSet<IStateIconStyles>;
  stateId?: string;
  calloutVisible: boolean;
  dismissCallout: () => void;
}
const StateCallout = (props: StateCalloutProps) => {
  return props.calloutVisible ? (
    <Callout
      className={"stateIconCallout"}
      target={`#${props.iconID}`}
      onDismiss={() => props.dismissCallout()}
      directionalHint={DirectionalHint.topAutoEdge}
      setInitialFocus
    >
      <div
        className={classnames(
          "stateIconCalloutTextWrapper",
          props.classNames.callout,
        )}
      >
        <Text className={"stateIconCalloutText"}>
          {L10n.format(`Configuration.IconState.${props.stateId}`)}
        </Text>
      </div>
    </Callout>
  ) : null;
};

/**
 * The StateIcon component renders the `READY_STATE`, `ERROR_STATE` and `SPEC_STATE` of a parameterTO as a MS Fluent Icon. Clicking the icon opens a MS Fluent Callout, which in turn contains a MS Fluent Text explaining the icon.
 * The rendered icon is either a warning icon representing the `READY_STATE` `'NOT_READY'`, an error icon representing the `ERROR_STATE` `'ERROR'` or an icon representing the `SPEC_STATE`.
 *
 * - `NOT_SET`: empty icon
 * - `SET_BY_DEFAULT`: The MS Fluent "Completed" icon
 * - `SET_BY_SYSTEM`: The MS Fluent "ThisPC" icon
 * - `SET_BY_USER`: The MS Fluent "Contact" icon
 * - `NOT_READY`: The MS Fluent "Warning" icon
 * - `ERROR`: The MS Fluent "ErrorBadge" icon
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/StateIcon/StateIcon.tsx)
 * - [StateIconStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/StateIcon/StateIcon.styles.ts)
 * - [StateIconProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/StateIcon/StateIcon.types.ts)
 * - [MS Fluent Icon](https://developer.microsoft.com/de-DE/fluentui#/controls/web/icon)
 * - [MS Fluent Callout](https://developer.microsoft.com/de-DE/fluentui#/controls/web/callout)
 * - [MS Fluent Text](https://developer.microsoft.com/de-DE/fluentui#/controls/web/text)
 *
 * @visibleName StateIcon
 */
const IStateIcon = (props: StateIconProps) => {
  const { styles, theme, onlyPlaceholder, data, error } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IStateIconStyles>;

  const [calloutVisible, setCalloutVisible] = useState(false);
  const [icons, setIcons] = useState<any>(defaultIcons);
  const [stateId, setStateId] = useState<string | undefined>();
  const [iconID] = useState<string>(`state-icon-${props.data?.id}`);

  useEffect(() => {
    if (props.icons && !onlyPlaceholder) {
      setIcons({ ...defaultIcons, ...props.icons });
    }
  }, [props.icons, onlyPlaceholder]);

  useEffect(() => {
    if (!onlyPlaceholder) {
      if (data?.id === error?.id && getSelectedValue(data) === error?.value) {
        setStateId(ERROR_STATE);
      } else if (props.data?.readyState === READY_STATE_NOT_READY) {
        setStateId(READY_STATE_NOT_READY);
      } else {
        setStateId(specState(props.data).trim());
      }
    }
  }, [data, error, onlyPlaceholder]);

  if (onlyPlaceholder) {
    return (
      <Icon
        className={classnames("stateIconPlaceholderIcon", classNames.icon)}
        iconName={""}
      />
    );
  } else if (!stateId) {
    return null;
  }

  let iconClassName = classNames.icon;
  if (stateId === ERROR_STATE) {
    iconClassName = mergeStyles(classNames.icon, classNames.errorIcon);
  } else if (stateId === READY_STATE_NOT_READY) {
    iconClassName = mergeStyles(classNames.icon, classNames.warningIcon);
  }

  return (
    <StackItem className={classnames("stateIconRootWrapper", classNames.root)}>
      <StateCallout
        calloutVisible={calloutVisible}
        iconID={iconID}
        stateId={stateId}
        dismissCallout={() => setCalloutVisible(false)}
        classNames={classNames}
      />
      <Icon
        id={iconID}
        style={{ cursor: "pointer", verticalAlign: "center" }}
        className={classnames("stateIconClass", iconClassName)}
        iconName={icons[stateId]}
        onClick={() => setCalloutVisible(!calloutVisible)}
      />
    </StackItem>
  );
};
/**
 * The StateIcon component renders the `READY_STATE`, `ERROR_STATE` and `SPEC_STATE` of a parameterTO as a MS Fluent Icon. Clicking the icon opens an MS Fluent
 * Callout, which in turn contains an MS Fluent Text explaining the icon.
 * The rendered icon is either a warning icon representing the `READY_STATE` `NOT_READY`, an error icon representing the ERROR\_STATE `ERROR` or an icon
 * representing the `SPEC_STATE`.
 *
 * - `NOT_SET`: empty icon
 * - `SET_BY_DEFAULT`: The MS Fluent "Completed" icon
 * - `SET_BY_SYSTEM`: The MS Fluent "ThisPC" icon
 * - `SET_BY_USER`: The MS Fluent "Contact" icon
 * - `NOT_READY`: The MS Fluent "Warning" icon
 * - `ERROR`: The MS Fluent "ErrorBadge" icon
 *
 * @see StateIconStyles
 * @see StateIconProps
 */
export const StateIcon = styled(IStateIcon, StateIconStyles);
StateIcon.displayName = "StateIcon";
