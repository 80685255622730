import {
  DefaultButton,
  ITheme,
  mergeStyleSets,
  Stack,
  useTheme,
} from "@fluentui/react";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { equals, head, length, map, pathOr, replace } from "ramda";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { ParameterTO } from "@encoway/c-services-js-client";

import { SideBarContext } from "../../../context/useSidebar";
import { useTooltip } from "../../../hooks/useTooltip";
import { SETTINGS } from "../../../settings";
import { ConfiguratorComponentProps } from "../../../types/configuration";

const SELECT_SIZE_PATH = ["viewPortProperties", "C_SELECT_SIZE"];

interface StyledButtonProps {
  label: string;
  selected: boolean;
  image?: string;
  resolution?: string;
  inline?: boolean;
  grid?: boolean;
  selectable?: boolean;
  tileSize?: string;

  onClick(): void;

  onMouseEnter(event: React.MouseEvent<HTMLDivElement>): void;

  onMouseLeave: () => void;
}

function selectStyles(
  selected: boolean,
  grid: boolean | undefined,
  inline: boolean | undefined,
  theme: ITheme,
) {
  return mergeStyleSets({
    root: {
      position: "relative",
      cursor: "pointer",
      border: "1px solid #dbdbdb",
      padding: "5px",
      width: "auto",
      minWidth: "120px",
      height: "auto",
      margin: ".125em",
      ...(grid && {
        minWidth: "131px",
        maxWidth: "131px",
      }),
      selectors: {
        "&.selected": {
          backgroundColor: "#232020",
          color: "#fff",
          border: "1px solid #232020",
        },
        "&:active > span": {
          position: "initial",
        },
      },
    },
    image: {
      flex: 1,
      marginBottom: ".2em",
    },
    bigImage: {
      width: "120px",
      height: "auto",
      border: "1px solid #dbdbdb",
    },
    label: {
      ...(inline && {
        position: "absolute",
        bottom: "10px",
        right: "14px",
        ...(selected && {
          fontWeight: 600,
        }),
      }),
    },
    bottomBorder: {
      width: "100%",
      position: "absolute",
      height: "3px",
      background: selected ? "#ce1719" : "transparent",
      bottom: 0,
      left: 0,
    },
  });
}

function StyledButton({
  label,
  selected,
  onClick,
  image,
  inline,
  grid,
  onMouseEnter,
  onMouseLeave,
  selectable,
  tileSize,
}: Readonly<StyledButtonProps>) {
  const selectStyled = selectStyles(selected, grid, inline, useTheme());

  return (
    <DefaultButton
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames(
        selectStyled.root,
        tileSize,
        "customConfSelect conf-button no-select",
        { selected },
        { disabled: !selectable },
      )}
      onClick={onClick}
    >
      <Stack>
        <div
          className={classNames(
            "customConfSelectImageWrapper",
            selectStyled.image,
          )}
        >
          {image && (
            <img
              className={classNames(
                "customConfSelectImage",
                selectStyled.bigImage,
              )}
              src={image}
              alt={label}
            />
          )}
        </div>
        <div
          className={classNames("customConfSelectLabel", selectStyled.label)}
        >
          {label}
        </div>
        <div
          className={classNames(
            "customConfSelectBorderBottom",
            selectStyled.bottomBorder,
          )}
        />
      </Stack>
    </DefaultButton>
  );
}
export function ConfSelect({
  data,
  onValueChanged,
  resolution,
  inline,
}: ConfiguratorComponentProps<ParameterTO> & {
  resolution?: string;
  inline?: boolean;
}) {
  const { getTooltip } = useTooltip(data);
  const { setInfo, releaseTooltip } = useContext(SideBarContext);
  const [localSelected, setLocalSelected] = useState<string | undefined>(
    head(data?.selectedValues || [{ value: undefined }])?.value,
  );
  const [isUserChange, setIsUserChange] = useState(false);
  const debouncedOnValueChanged = useCallback(
    debounce((data, value) => {
      onValueChanged(data, value);
    }, 300),
    [onValueChanged],
  );

  useEffect(() => {
    if (!isUserChange) {
      setLocalSelected(
        head(data?.selectedValues || [{ value: undefined }])?.value,
      );
    }
  }, [data?.selectedValues, isUserChange]);

  const selectSize = replace(
    /[^a-zA-Z]/g,
    "",
    pathOr("", SELECT_SIZE_PATH, data),
  );

  function onClickSelect(value: string) {
    return function () {
      setLocalSelected(value);
      setIsUserChange(true);
      debouncedOnValueChanged(data, value);

      setTimeout(() => setIsUserChange(false), 3000);
    };
  }

  function onMouseEnter(value: string) {
    const tooltipInfo = getTooltip(value);
    tooltipInfo && setInfo(tooltipInfo);
  }

  const _resolution = equals(resolution, "wide") ? "tiny" : resolution;
  return (
    <>
      {map((valueTO) => {
        const image = valueTO.imageUrl
          ? `${SETTINGS.showroom.url}/api/catalog/media?id=${valueTO.imageUrl}&resolution=${_resolution}`
          : undefined;
        return (
          <StyledButton
            grid={length(data.values || []) > 10}
            inline={inline}
            resolution={resolution}
            key={valueTO.value}
            selected={equals(localSelected, valueTO.value)}
            label={valueTO.translatedValue}
            onClick={onClickSelect(valueTO.value)}
            image={resolution ? image : undefined}
            onMouseEnter={() => onMouseEnter(valueTO.value)}
            onMouseLeave={() => releaseTooltip()}
            selectable={valueTO.selectable}
            tileSize={selectSize}
          />
        );
      }, data.values || [])}
    </>
  );
}
