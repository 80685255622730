import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IDetailDropdownStyles } from "./DetailDropdown.types";

export const DetailDropdownStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IDetailDropdownStyles>
> = (theme: IPartialTheme) => ({
  detailOption: {
    width: "100%",
    padding: "0rem 0rem 0rem 0.2rem",
    cursor: "pointer",
    selectors: {
      ":hover": {
        backgroundColor: theme.palette?.themeLighterAlt,
      },
      ":focus": {
        outline: `1px solid ${theme.palette?.themePrimary}`,
        outlineOffset: "-1px",
      },
    },
  },
  detailOptionSelected: {
    paddingRight: "0.5rem",
  },
  imageContainer: {
    marginBottom: "0.3rem",
    marginTop: "0.33rem",
  },
  shortTextContainer: {
    marginTop: "-0.3rem !important",
    padding: "0rem 0rem 0.75rem 0.5rem",
    selectors: {
      span: {
        color: theme.palette?.neutralSecondary,
      },
    },
  },
  shortTextNotSelected: {
    selectors: {
      span: {
        color: theme.palette?.neutralSecondary,
      },
    },
  },
});
