import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IBurgerMenuStyles } from "./BurgerMenu.types";

export const BurgerMenuStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IBurgerMenuStyles>
> = (theme: IPartialTheme) => ({
  button: {
    padding: "0px",
    border: "none",
    borderRadius: "0em",
    backgroundColor: "transparent",
    minWidth: "0",
    fontSize: "14",
    color: theme.semanticColors?.link,
    selectors: {
      ":active": {
        background: "transparent",
      },
      i: {
        color: "inherit",
        fontSize: "inherit",
        height: "auto",
        lineHeight: "normal",
        margin: "0",
      },
      "i > span": {
        height: "1.1em",
      },
      "i > span > svg": {
        height: "14px",
        width: "14px",
      },
    },
  },
  visible: {
    selectors: {
      ":hover": {
        backgroundColor: "inherit",
        color: theme.semanticColors?.linkHovered,
      },
      ":focus&::after": {
        //                outlineColor: theme.semanticColors?.focusBorder
        outline: `1px solid ${theme.semanticColors?.focusBorder}`,
        outlineOffset: "0.25rem",
      },
    },
  },
  callout: {
    selectors: {
      ".ms-Callout-main": {
        display: "block",
        overflow: "inherit",
      },
    },
  },
});
