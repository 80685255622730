import {
  forEach,
  has,
  is,
  isEmpty,
  isNil,
  keys,
  mergeDeepLeft,
  path,
  pathOr,
  reduce,
  replace,
} from "ramda";

import { ContainerTO } from "@encoway/c-services-js-client";
import { Visualization } from "@encoway/visual-editor";

import { SETTINGS } from "../settings";
import { FlattenedState, State } from "../types/visualization";

export const C_PROPERTY_NAMES = {
  C_VIS_BG_COLOR: "",
  C_VIS_FOG_DENSITY: "",
  C_VIS_AMBIENT_LIGHT_INTENSITY: "",
  C_VIS_BACK_LIGHT_INTENSITY: "",
  C_VIS_FRONT_LIGHT_INTENSITY: "",
  C_VIS_TOP_FRONT_LIGHT_INTENSITY: "",
  C_VIS_TOP_LIGHT_INTENSITY: "",
  C_VIS_MIN_AZIMUTH_ANGLE: "",
  C_VIS_MAX_AZIMUTH_ANGLE: "",
  C_VIS_MIN_POLAR_ANGLE: "",
  C_VIS_MAX_POLAR_ANGLE: "",
};

const MATH_NUMBER_MAP: Record<string, number> = {
  "Math.PI": Math.PI,
  "Math.PI / 2": Math.PI / 2,
  Infinity: Infinity,
  "-Infinity": -Infinity,
};

export function toSelectedValueMap<T extends Record<string, string>>(
  container: ContainerTO,
  desiredParameters: T,
): T {
  forEach((parameter) => {
    if (has(parameter.name, desiredParameters)) {
      // typing needed
      (desiredParameters as Record<string, string>)[parameter.name] =
        pathOr<string>("", ["selectedValues", 0, "value"], parameter);
    }
  }, container.parameters);

  if (!isEmpty(container.children)) {
    forEach(
      (child) => toSelectedValueMap(child, desiredParameters),
      container.children,
    );
  }

  return desiredParameters;
}

const colorToHex = (color: string) => +`0x${color}`;

const stringToNumber = (numberString: string) =>
  parseFloat(replace(",", ".", numberString));

export function setBackgroundColor(
  visBgColor: string,
  visContext?: Visualization,
) {
  if (isEmpty(visBgColor) || !visContext) return;
  const bgColor = colorToHex(visBgColor);
  // @ts-expect-error Property 'THREE' does not exist on type 'Window & typeof globalThis'
  visContext.cloud.graph().scene().background = new window.THREE.Color(bgColor);
}

export function setFog(
  visBgColor: string,
  visFogDensity: string,
  visContext?: Visualization,
) {
  if (isEmpty(visBgColor) || isEmpty(visFogDensity) || !visContext) return;
  const bgColor = colorToHex(visBgColor);
  const fogDensity = stringToNumber(visFogDensity);
  // @ts-expect-error Property 'THREE' does not exist on type 'Window & typeof globalThis'
  visContext.cloud.graph().scene().fog = new window.THREE.FogExp2(
    bgColor,
    fogDensity,
  );
}

export function setLightIntensity(
  lightName: string,
  visLightIntensity: string,
  visContext?: Visualization,
) {
  if (isEmpty(visLightIntensity) || !visContext) return;
  const light = visContext.cloud
    .graph()
    .scene()
    .getObjectByName(lightName, true);
  if (!light) return;
  const lightIntensity = stringToNumber(visLightIntensity);
  light.intensity = lightIntensity;
}

const getBackgroundColor = (value: string) =>
  !isEmpty(value) && { background: colorToHex(value) };

function getLightIntensities(
  flattenedParameterMap: Record<keyof typeof C_PROPERTY_NAMES, string>,
) {
  const ambientLightIntensity =
    flattenedParameterMap["C_VIS_AMBIENT_LIGHT_INTENSITY"];
  const backLightIntensity =
    flattenedParameterMap["C_VIS_BACK_LIGHT_INTENSITY"];
  const frontLightIntensity =
    flattenedParameterMap["C_VIS_FRONT_LIGHT_INTENSITY"];
  const topFrontLightIntensity =
    flattenedParameterMap["C_VIS_TOP_FRONT_LIGHT_INTENSITY"];
  const topLightIntensity = flattenedParameterMap["C_VIS_TOP_LIGHT_INTENSITY"];
  return {
    ...(!isEmpty(ambientLightIntensity) && {
      0: stringToNumber(ambientLightIntensity),
    }),
    ...(!isEmpty(backLightIntensity) && {
      1: stringToNumber(backLightIntensity),
    }),
    ...(!isEmpty(frontLightIntensity) && {
      2: stringToNumber(frontLightIntensity),
    }),
    ...(!isEmpty(topFrontLightIntensity) && {
      3: stringToNumber(topFrontLightIntensity),
    }),
    ...(!isEmpty(topLightIntensity) && {
      4: stringToNumber(topLightIntensity),
    }),
  } as Record<number, number>;
}

const getCameraAngle = (
  propertyName:
    | "minAzimuthAngle"
    | "maxAzimuthAngle"
    | "minPolarAngle"
    | "maxPolarAngle",
  value: string,
) =>
  !isEmpty(value) && {
    [propertyName]: MATH_NUMBER_MAP[value] ?? stringToNumber(value),
  };

export function updateVisSettings(
  flattenedParameterMap: Record<string, string>,
): typeof SETTINGS.visualization.settings {
  const newLightIntensities = getLightIntensities(flattenedParameterMap);
  // fog settings cant be set in initial visualization settings
  const newVisSettings = {
    ui: {
      ...getBackgroundColor(flattenedParameterMap["C_VIS_BG_COLOR"]),
      controls: {
        ...getCameraAngle(
          "minAzimuthAngle",
          flattenedParameterMap["C_VIS_MIN_AZIMUTH_ANGLE"],
        ),
        ...getCameraAngle(
          "maxAzimuthAngle",
          flattenedParameterMap["C_VIS_MAX_AZIMUTH_ANGLE"],
        ),
        ...getCameraAngle(
          "minPolarAngle",
          flattenedParameterMap["C_VIS_MIN_POLAR_ANGLE"],
        ),
        ...getCameraAngle(
          "maxPolarAngle",
          flattenedParameterMap["C_VIS_MAX_POLAR_ANGLE"],
        ),
      },
      lights: SETTINGS.visualization.settings.ui.lights.map((light, index) => ({
        ...light,
        intensity: newLightIntensities[index] ?? light.intensity,
      })),
    },
  };
  // different type not working
  return mergeDeepLeft<any, typeof SETTINGS.visualization.settings>(
    newVisSettings,
    SETTINGS.visualization.settings,
  );
}

export async function applyParameters(
  context: Visualization | undefined,
  flattenedParameterMap: Record<keyof typeof C_PROPERTY_NAMES, string>,
) {
  if (isNil(context)) {
    return;
  }

  setBackgroundColor(flattenedParameterMap["C_VIS_BG_COLOR"], context);
  setFog(
    flattenedParameterMap["C_VIS_BG_COLOR"],
    flattenedParameterMap["C_VIS_FOG_DENSITY"],
    context,
  );
  setLightIntensity(
    "#AmbientLight",
    flattenedParameterMap["C_VIS_AMBIENT_LIGHT_INTENSITY"],
    context,
  );
  setLightIntensity(
    "#BackLight",
    flattenedParameterMap["C_VIS_BACK_LIGHT_INTENSITY"],
    context,
  );
  setLightIntensity(
    "#FrontLight",
    flattenedParameterMap["C_VIS_FRONT_LIGHT_INTENSITY"],
    context,
  );
  setLightIntensity(
    "#TopFrontLight",
    flattenedParameterMap["C_VIS_TOP_FRONT_LIGHT_INTENSITY"],
    context,
  );
  setLightIntensity(
    "#TopLight",
    flattenedParameterMap["C_VIS_TOP_LIGHT_INTENSITY"],
    context,
  );
}

export function flattenParameters(state: State): FlattenedState {
  const toFlattenedValue = (acc: FlattenedState, key: string | number) => {
    const PATH = is(String, path([key, "value"], state))
      ? [key, "valueTO", "value"]
      : [key, "value"];
    return {
      ...acc,
      [key]: pathOr("", PATH, state),
    };
  };

  return reduce(toFlattenedValue, {}, keys(state));
}
