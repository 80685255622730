import { EditIcon } from "@fluentui/react-icons-mdl2";
import {
  Dropdown as FluentDropdown,
  IDropdown as IFluentDropdown,
  IDropdownProps as IFluentDropdownProps,
} from "@fluentui/react/lib/Dropdown";
import {
  FocusTrapZone,
  IFocusTrapZone,
} from "@fluentui/react/lib/FocusTrapZone";
import { ResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { ISelectableDroppableTextProps } from "@fluentui/react/lib/SelectableOption";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { TooltipHost, TooltipOverflowMode } from "@fluentui/react/lib/Tooltip";
import {
  classNamesFunction,
  IRenderFunction,
  styled,
} from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import { merge } from "lodash";
import React, {
  FormEvent,
  ForwardedRef,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Constants as RestApiConstants,
  selectedRawValue,
  Value,
} from "@encoway/c-services-js-client";
import { L10n } from "@encoway/l10n";
import { ComponentFactory, Error } from "@encoway/react-configurator";

import { SideBarContext } from "../../../../../context/useSidebar";
import { useTooltip } from "../../../../../hooks/useTooltip";
import { PriceProps } from "../Price/Price.types";
import { ComponentName, READY_STATE_NOT_READY } from "../constants";
import { determineLayerClass } from "../helperFunctions";
import { DropdownStyles } from "./Dropdown.styles";
import {
  changeValue,
  DropdownProps,
  IDropdownOption,
  IDropdownStyles,
  IDropdownWithState,
} from "./Dropdown.types";

const DropdownPrice = (props: PriceProps) => {
  return ComponentFactory.instanceOf(ComponentName.Price, props);
};

interface DropdownClassNames {
  rootClass: string;
  dropdownContainerClass: string;
  dropdownClass: string;
  calloutClass: string;
  optionClass: string;
  textClass: string;
  priceClass: string;
  labelClass: string;
}

const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};
const BurgerMenu = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.BurgerMenu, props);
};
const StateIcon = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.StateIcon, props);
};
const Parameter = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.Parameter, {
    ...props,
    hideQuantity: false,
    hideStateIcon: true,
    hideBurgermenu: true,
  });
};

interface AdditionalValueIconProps {
  tooltipId: string;
  classNames: IProcessedStyleSet<IDropdownStyles>;
}

function AdditionalValueIcon({
  tooltipId,
  classNames,
}: AdditionalValueIconProps) {
  return (
    <TooltipHost
      id={tooltipId}
      className={classnames(
        "dropdownAdditionalValueIconTooltip",
        classNames.additionalValueIconTooltip,
      )}
      tooltipProps={{
        onRenderContent: () => (
          <Text className={classNames.additionalValueIconTooltipText}>
            {L10n.format("Configuration.AdditionalValue.SingleValue.Name")}
          </Text>
        ),
      }}
    >
      <EditIcon
        aria-describedby={tooltipId}
        className={classnames(
          "dropdownAdditionalValue",
          classNames.additionalValueIcon,
        )}
      />
    </TooltipHost>
  );
}

/**
 * Renders a Dropdown for the possible values of a ParameterTO.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Dropdown/Dropdown.tsx)
 * - [DropdownStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Dropdown/Dropdown.styles.ts)
 * - [DropdownProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Dropdown/Dropdown.types.ts)
 * - [MS Fluent Dropdown](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dropdown)
 *
 * @visibleName Dropdown
 */
function IDropdown(
  props: DropdownProps<IDropdownStyles>,
  dropdownForwardRef: ForwardedRef<IDropdownWithState>,
) {
  const { styles, theme, ...delegatedProps } = props;
  const classNames: IProcessedStyleSet<IDropdownStyles> = classNamesFunction()(
    styles,
    theme,
  );
  const hasValues = props.data.values && props.data.values.length > 0;
  const readOnly = props.options?.readOnly === true;
  const placeholderText = !readOnly
    ? L10n.format("Configuration.DropDown.placeholder", {})
    : "";
  const [selectedValue, setSelectedValue] = useState<string | null | undefined>(
    selectedRawValue(props.data),
  );
  const newDropDownRef = useRef<IDropdownWithState>(null);
  const dropdownRef =
    (dropdownForwardRef as MutableRefObject<IDropdownWithState>) ||
    newDropDownRef;

  const trapZoneRef = useRef<IFocusTrapZone>(null);

  const { getTooltip } = useTooltip(props.data);
  const { setInfo } = useContext(SideBarContext);

  function onHover(value: string) {
    return function () {
      const tooltipInfo = getTooltip(value);
      tooltipInfo && setInfo(tooltipInfo);
    };
  }

  useEffect(() => {
    const value = selectedRawValue(props.data);
    if (value !== selectedValue) {
      if (value === undefined) {
        setSelectedValue(null);
      } else {
        setSelectedValue(value);
      }
    }
  });

  const onChangeValue = (newValue?: IDropdownOption) => {
    if (newValue?.key !== selectedValue) {
      props.onValueChanged(
        props.data,
        newValue?.key,
        RestApiConstants.ValueFormat.Unformatted,
      );
    }
    dropdownRef.current?.setState &&
      dropdownRef.current.setState({ isOpen: false });
  };

  const onChange = (event: FormEvent, value?: IDropdownOption) => {
    if (event.type === "focus") {
      setSelectedValue(null);
      return;
    }
    if (event.type === "keydown") {
      dropdownRef.current?.setState &&
        dropdownRef.current.setState({ isOpen: true });
      return;
    }

    onChangeValue(value);
  };

  const extractDropdownOptions: () => IDropdownOption[] = () => {
    const { data, onCreateDropdownOption } = props;
    const unit = data.displayUnit?.translatedUnit || "";
    const func: (v: Value) => IDropdownOption = onCreateDropdownOption
      ? (v) =>
          onCreateDropdownOption(
            v,
            unit,
            props.error,
            createBasicDropdownOption,
          )
      : (v: Value) => createBasicDropdownOption(v, unit, props.error);
    return data.values?.map<IDropdownOption>(func) || [];
  };

  const createBasicDropdownOption: (
    value: Value,
    unit: string,
    currentError?: Error,
  ) => IDropdownOption = (value, unit, currentError) => {
    return {
      key: value.value as any,
      text: unit ? `${value.translatedValue} ${unit}` : value.translatedValue,
      price: value.price,
      selected: value.selected,
      selectable: value.selectable && currentError?.value !== value.value,
    } as IDropdownOption;
  };

  const createTooltipHost = (text?: string, className?: string) => {
    return (
      text && (
        <TooltipHost
          hostClassName={classnames("dropdownTooltipHost", className)}
          overflowMode={TooltipOverflowMode.Self}
          content={text}
        >
          {text}
        </TooltipHost>
      )
    );
  };

  function createPrice(price?: number, className?: string) {
    if (price === undefined) return null;

    const { options } = props;
    return (
      <Text className={classnames("dropdownPriceText", className)}>
        <DropdownPrice
          price={price}
          currency={options?.currency as string}
          showPrices={options?.showPrices as boolean}
        />
      </Text>
    );
  }

  function createInfoButton(infoButtonData?: Value) {
    return ComponentFactory.instanceOf(ComponentName.InfoButton, {
      ...delegatedProps,
      data: infoButtonData,
    });
  }

  const classes: DropdownClassNames = {
    rootClass: classNames.root || "",
    dropdownContainerClass: classNames.dropdownContainer || "",
    dropdownClass: "",
    calloutClass: classNames.callout || "",
    optionClass: "",
    textClass: "",
    priceClass: "",
    labelClass: "",
  };

  const renderTitle: (
    selectedDropdownOption?: IDropdownOption,
  ) => JSX.Element | null = (selectedDropdownOption) => {
    if (!selectedDropdownOption) {
      return null;
    }
    updateClasses(selectedDropdownOption);
    // Note: This code does not(!) support multiselection
    const price = createPrice(selectedDropdownOption.price, classes.priceClass);

    const selectedDropdownOptionText: string = selectedDropdownOption.text;

    const optionText = createTooltipHost(
      selectedDropdownOptionText,
      classes.labelClass,
    );

    return (
      <Stack
        horizontal
        className={classnames(
          "dropdownSelectedOption",
          classNames.optionSelected,
        )}
      >
        {optionText}
        {price}
      </Stack>
    );
  };

  const updateClasses = (value: IDropdownOption) => {
    let optionClass = classNames.option;
    let textClass = classNames.textContainer;
    let labelClass = classNames.textContainer;
    let priceClass = classNames.priceContainer;

    if (value.selected) {
      optionClass = mergeStyles(optionClass, classNames.optionSelected);
      textClass = mergeStyles(textClass, classNames.textSelected);
      priceClass = mergeStyles(priceClass, classNames.priceSelected);
    } else if (!value.selectable) {
      optionClass = mergeStyles(optionClass, classNames.optionConflict);
      textClass = mergeStyles(textClass, classNames.textConflict);
      labelClass = mergeStyles(labelClass, classNames.textConflict);
      priceClass = mergeStyles(priceClass, classNames.priceConflict);
    } else if (selectedValue) {
      // Only use NotSelected Style if any other option is selected
      optionClass = mergeStyles(optionClass, classNames.optionNotSelected);
      textClass = mergeStyles(textClass, classNames.textNotSelected);
      priceClass = mergeStyles(priceClass, classNames.priceNotSelected);
    }

    classes.textClass = textClass || "";
    classes.optionClass = optionClass || "";
    classes.priceClass = priceClass || "";
    classes.labelClass = labelClass || "";
  };

  const renderOption = (
    dropdownOption: IDropdownOption,
    changeValueFunction: changeValue,
    dataIsFocusable?: boolean,
  ) => {
    updateClasses(dropdownOption);

    const optionText = createTooltipHost(
      dropdownOption.text,
      classes.textClass,
    );

    const price = createPrice(dropdownOption.price, classes.priceClass);
    const infoButtonData: Value | undefined = props.data.values?.find(
      (v) => v.value === dropdownOption.key,
    );
    const infoButton = createInfoButton(infoButtonData);
    return (
      <Stack
        onMouseEnter={
          dropdownOption.text ? onHover(dropdownOption.text) : () => null
        }
        onClick={() => changeValueFunction(dropdownOption)}
        key={dropdownOption.key}
        data-is-focusable={dataIsFocusable === true}
        horizontal
        className={classnames(
          "dropdownOptionStack",
          `${classes.optionClass} ${
            dropdownOption.selected ? "encoway-cui-dropdown-selected-value" : ""
          }`,
        )}
        tokens={{ childrenGap: "0.5em" }}
      >
        {optionText}
        {infoButtonData?.properties?.isAdditional && (
          <AdditionalValueIcon
            tooltipId={`tooltipId_${dropdownOption.id}`}
            classNames={classNames}
          />
        )}
        {infoButton}
        {price}
      </Stack>
    );
  };

  const renderList: IRenderFunction<
    ISelectableDroppableTextProps<IFluentDropdown, HTMLDivElement>
  > = (listProps, defaultRender) => {
    const focusTrapZoneProps = merge(
      {
        isClickableOutsideFocusTrap: true,
        disableFirstFocus: !listProps?.selectedKey,
        firstFocusableSelector: "encoway-cui-dropdown-selected-value",
      },
      props.focusProps || {},
    );

    return (
      <FocusTrapZone
        className={"dropdownFocusTrap"}
        componentRef={trapZoneRef}
        {...focusTrapZoneProps}
      >
        {defaultRender && defaultRender(listProps)}
      </FocusTrapZone>
    );
  };

  let dropdownClass = classNames.dropdown;
  if (readOnly) {
    dropdownClass = mergeStyles(dropdownClass, classNames.disabled);
  } else if (props.data.readyState === READY_STATE_NOT_READY) {
    dropdownClass = mergeStyles(dropdownClass, classNames.notReady);
  }

  const generateOptions: () => IDropdownOption[] = () =>
    props.onGetOptions
      ? props.onGetOptions(extractDropdownOptions)
      : extractDropdownOptions();

  const onRenderTitle: IRenderFunction<IDropdownOption[]> = (
    selectedDropdownOptions,
  ) => {
    if (!selectedDropdownOptions) {
      return null;
    }
    return props.onRenderTitle
      ? props.onRenderTitle(selectedDropdownOptions[0], renderTitle)
      : renderTitle(selectedDropdownOptions[0]);
  };

  const onRenderItem: IRenderFunction<IDropdownOption> = (option) =>
    props.onRenderItem
      ? props.onRenderItem(
          option as IDropdownOption,
          onChangeValue,
          renderOption,
        )
      : renderOption(option as IDropdownOption, onChangeValue, true);

  const inputProps: IFluentDropdownProps = {
    componentRef: dropdownRef,
    disabled: readOnly,
    className: dropdownClass,
    options: generateOptions() || [],
    placeholder: hasValues
      ? placeholderText
      : L10n.format("Configuration.DropDown.noValue"),
    onChange,
    onRenderTitle,
    // Fix bug where dropdown opens again after option is selected by space bar
    onKeyUp: (e) => {
      e.preventDefault();
    },
    onKeyDown: (e) => {
      if (e.key === " " && dropdownRef.current?.setState)
        dropdownRef.current.setState({ isOpen: true });
    },
    onRenderItem,
    onRenderList: (listProps, defaultRender) =>
      renderList(listProps, defaultRender),
    onFocus: (e) => {
      if (
        dropdownRef.current?.state?.isOpen === true &&
        trapZoneRef.current?.focus
      ) {
        trapZoneRef.current.focus();
        e.preventDefault();
      }
    },
    calloutProps: merge(
      {
        calloutMaxHeight: convertRemToPixels(20),
        className: classNames.callout,
        layerProps: {
          className: determineLayerClass(props.layerProps, classNames.layer),
          ...props.layerProps,
        },
      },
      props.calloutProps || {},
    ),
    selectedKey: selectedValue,
    // This responsiveMode keeps the "normal" behavior of the dropdown callout on small screens
    responsiveMode: ResponsiveMode.unknown,
  };

  const defaultRenderDropdownComponent = (
    componentProps?: IFluentDropdownProps,
  ) =>
    componentProps ? (
      <FluentDropdown
        className={"dropdownFluentComponent"}
        {...componentProps}
      />
    ) : null;

  return (
    <Stack className={classnames("dropdownWrapper", classNames.root)}>
      <Parameter className={"dropdownParameter"} {...delegatedProps} />
      <Stack
        horizontal
        className={classnames(
          "dropdownContainer",
          classNames.dropdownContainer,
        )}
        tokens={{ childrenGap: "0.5em" }}
      >
        {props.onRenderInputComponent
          ? props.onRenderInputComponent(
              inputProps,
              defaultRenderDropdownComponent,
            )
          : defaultRenderDropdownComponent(inputProps)}
        <StateIcon {...delegatedProps} className={"dropdownStateIcon"} />
        <BurgerMenu {...delegatedProps} className={"dropdownBurgerMenu"} />
      </Stack>
    </Stack>
  );
}

const IDropdownWithForwardRef = React.forwardRef(
  (
    props: DropdownProps<IDropdownStyles>,
    dropdownForwardRef: ForwardedRef<IDropdownWithState>,
  ) => IDropdown(props, dropdownForwardRef),
);

export const Dropdown = styled(IDropdownWithForwardRef, DropdownStyles);
Dropdown.displayName = "Dropdown";
