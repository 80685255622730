import { t } from "i18next";
import { equals, find, map, path, pathOr } from "ramda";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ContainerTO } from "@encoway/c-services-js-client";

import { ConfigurationContext } from "./useConfiguration";
import { useProductContext } from "./useProduct";

export type InfoState = {
  image: string | undefined;
  infos: string[];
  text: string | undefined;
  warning?: boolean;
  configurationInvalid?: boolean;
};

type SideBarStore = {
  summary: ContainerTO | undefined;
  setInfo({ infos, image, warning }: InfoState): void;
  releaseTooltip(setInvalid?: boolean): void;
} & InfoState;

const initialInfo = {
  image: undefined,
  infos: [],
  text: undefined,
  warning: false,
  configurationInvalid: undefined,
};

export const SideBarContext = createContext<SideBarStore>({
  ...initialInfo,
  summary: undefined,
  setInfo: () => {
    throw new Error("setInfo in SidebarContext not initialized");
  },
  releaseTooltip: () => {
    throw new Error("releaseTooltip in SidebarContext not initialized");
  },
});

export function useSidebar(): SideBarStore {
  const [isMarkedInvalid, setIsMarkedInvalid] = useState<boolean>(false);
  const { guiTO, filteredInvalidParameters, valid } =
    useContext(ConfigurationContext);
  const { products } = useProductContext();
  const [info, setInfo] = useState<InfoState>(initialInfo);
  const summary = find(
    (parameter) => equals(parameter.name, "CB_CONDENSED_SUMMARY"),
    pathOr<ContainerTO[]>([], ["rootContainer", "children"], guiTO),
  );

  useEffect(() => {
    if (isMarkedInvalid) {
      if (valid) {
        setIsMarkedInvalid(false);
      }
      releaseTooltip(false);
    }
  }, [valid, guiTO, isMarkedInvalid]);

  function releaseTooltip(showInvalidConfiguration?: boolean) {
    if (showInvalidConfiguration) {
      setIsMarkedInvalid(true);
    }
    if (
      filteredInvalidParameters.length > 0 &&
      (isMarkedInvalid || showInvalidConfiguration)
    ) {
      setInfo({
        text: t("t:validation_failed"),
        infos: map(
          (parameter) => parameter.translatedName || parameter.name,
          filteredInvalidParameters,
        ),
        image: path(
          [
            "application",
            "characteristicValues",
            "APP_WARNING_IMAGE",
            "values",
            0,
            "uri",
          ],
          products,
        ),
        warning: true,
        configurationInvalid: true,
      });
    } else {
      setInfo(initialInfo);
    }
  }

  return useMemo(
    () => ({
      ...info,
      summary,
      setInfo,
      releaseTooltip,
    }),
    [info, summary],
  );
}

export function SideBarProvider({ children }: { children: ReactNode }) {
  return (
    <SideBarContext.Provider value={useSidebar()}>
      {children}
    </SideBarContext.Provider>
  );
}
