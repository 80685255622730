import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IDropdownStyles } from "./Dropdown.types";

export const DropdownStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IDropdownStyles>
> = (theme: IPartialTheme) => ({
  root: {},
  dropdownContainer: {},
  dropdown: {
    minWidth: 0,
    flexGrow: 1,
    selectors: {
      ".ms-Dropdown-title": {
        borderColor: theme.palette?.neutralTertiary,
      },
      ".ms-Dropdown-titleIsPlaceHolder": {
        fontStyle: "italic",
        color: theme.semanticColors?.disabledText,
      },
      ".ms-Dropdown-caretDown": {
        color: theme.palette?.neutralPrimary,
      },
      ".ms-Dropdown:hover .ms-Dropdown-title": {
        borderColor: theme.palette?.neutralSecondary,
        color: theme.palette?.neutralPrimary,
      },
      ".ms-Dropdown:focus .ms-Dropdown-title": {
        borderColor: theme.palette?.neutralSecondary,
        color: theme.palette?.neutralPrimary,
      },
    },
  },
  notReady: {
    selectors: {
      ".ms-Dropdown-title": {
        borderColor: theme.semanticColors?.warningIcon,
        backgroundColor: theme.semanticColors?.warningBackground,
      },
      ".ms-Dropdown:hover .ms-Dropdown-title": {
        borderColor: theme.semanticColors?.warningIcon,
      },
    },
  },
  callout: {},
  option: {
    height: "2.2rem",
    width: "100%",
    padding: "0.2rem 0.5rem",
    alignItems: "center",
    cursor: "pointer",
    selectors: {
      ":hover": {
        backgroundColor: theme.palette?.themeLighterAlt,
        color: theme.palette?.themePrimary,
      },
      ":focus": {
        outline: `1px solid ${theme.palette?.themePrimary}`,
        outlineOffset: "-1px",
      },
    },
  },
  optionSelected: {},
  optionNotSelected: {},
  optionConflict: {
    color: theme.palette?.neutralSecondary,
  },
  textContainer: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textSelected: {
    fontWeight: "bold",
  },
  textNotSelected: {},
  textConflict: {},
  priceContainer: {
    display: "inline-flex",
    flexGrow: 3,
    justifyContent: "flex-end",
  },
  priceSelected: {},
  priceNotSelected: {},
  priceConflict: {},
  disabled: {
    selectors: {
      ".ms-Dropdown-title": {
        backgroundColor: theme.palette?.neutralLight,
        color: theme.semanticColors?.disabledText,
      },
      ".ms-Dropdown:hover .ms-Dropdown-title": {
        color: theme.semanticColors?.disabledText,
      },
      ".ms-Dropdown:focus .ms-Dropdown-title": {
        color: theme.semanticColors?.disabledText,
      },
    },
  },
  additionalValueIconTooltip: {
    padding: "1em",
  },
  additionalValueIconTooltipText: {
    fontSize: 14,
  },
  additionalValueIcon: {
    zIndex: 1000,
    color: theme.palette?.neutralTertiary,
  },
});
