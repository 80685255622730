import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import {
  IRenderFunction,
  classNamesFunction,
  styled,
} from "@fluentui/react/lib/Utilities";
import classnames from "classnames";

import { Value } from "@encoway/c-services-js-client";
import { Error, mediaLink } from "@encoway/react-configurator";

import { Dropdown } from "../Dropdown/Dropdown";
import {
  IDropdownOption,
  createBasicDropdownOptionType,
  onRenderItem,
} from "../Dropdown/Dropdown.types";
import { VIEWPORT_PROPERTY_VALUE } from "../constants";
import { DetailDropdownStyles } from "./DetailDropdown.styles";
import {
  DetailDropdownProps,
  IDetailDropdownStyles,
} from "./DetailDropdown.types";
import placeholderImageSrc from "./placeholder_icon.png";

interface IDetailDropdownValue extends Value {
  imageUrl?: string;
  shortText?: string;
}
interface IDetailDropdownOption extends IDropdownOption {
  image?: string;
  shortText?: string;
}

type createDetailDropdownOptionType = (
  value: IDetailDropdownValue,
  unit: string,
  error?: Error,
  originalFunction?: createBasicDropdownOptionType,
) => IDropdownOption;

const getShortTextClass = (
  dropdownOption: IDetailDropdownOption,
  classNames: IProcessedStyleSet<IDetailDropdownStyles>,
) => {
  let shortTextClass = classNames.shortTextContainer;
  if (!dropdownOption.selected && !dropdownOption.selectable) {
    shortTextClass = mergeStyles(
      shortTextClass,
      classNames.shortTextContainerNotSelected,
    );
  }

  return shortTextClass;
};

interface IShortTextProps {
  classNames: IProcessedStyleSet<IDetailDropdownStyles>;
  dropdownOption: IDetailDropdownOption;
  showShortText: boolean;
}

const ShortText = (props: IShortTextProps) => {
  const { dropdownOption, showShortText, classNames } = props;
  const optionShortText = dropdownOption.shortText;

  if (showShortText && optionShortText) {
    return (
      <Stack.Item
        className={classnames(
          "detailDropdownShortTextWrapper",
          getShortTextClass(dropdownOption, classNames),
        )}
      >
        <Text className={"detailDropdownShortText"}>{optionShortText}</Text>
      </Stack.Item>
    );
  }
  return null;
};
interface ValueImageProps {
  image?: string;
  size: string;
  showImage: boolean;
  imageResolution?: string;
  className?: string;
  mediaLink?: mediaLink;
}
const ValueImage = (props: ValueImageProps) => {
  const { image, showImage, imageResolution, className, size } = props;
  if (showImage) {
    const imageSrc = image
      ? props.mediaLink?.(image, imageResolution || "tiny")
      : placeholderImageSrc;
    return (
      <Stack.Item className={"detailDropdownValueStackItem"}>
        <Image
          className={classnames("detailDropdownValueStackItemImage", className)}
          src={imageSrc}
          width={size}
          height={size}
          imageFit={ImageFit.centerContain}
        />
      </Stack.Item>
    );
  }
  return <Stack.Item />;
};

/**
 * Renders a DetailDropdown for the possible values of a ParameterTO. It extends the Dropdown component with some new functionalities.
 * It can display an image and a short text if the according widget details are set that way.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/DetailDropdown/DetailDropdown.tsx)
 * - [DetailDropdownStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/DetailDropdown/DetailDropdown.styles.ts)
 * - [DetailDropdownProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/DetailDropdown/DetailDropdown.types.ts)
 * - [MS Fluent Dropdown](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dropdown)
 *
 * @visibleName DetailDropdown
 */
const IDetailDropdown = (props: DetailDropdownProps) => {
  const { styles, theme, imageResolution } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IDetailDropdownStyles>;

  const showImage =
    props.data.viewPortProperties?.DETAIL_DROPDOWN_IMAGE ===
    VIEWPORT_PROPERTY_VALUE.True;
  const showShortText =
    props.data.viewPortProperties?.DETAIL_DROPDOWN_SHORT_TEXT ===
    VIEWPORT_PROPERTY_VALUE.True;
  const createDropdownOption: createDetailDropdownOptionType = (
    value,
    unit,
    error,
    originalFunction,
  ) => {
    return {
      ...(originalFunction?.(value, unit, error) || {}),
      image: value.imageUrl,
      shortText: value.shortText,
    } as IDropdownOption;
  };

  const renderTitle: IRenderFunction<IDetailDropdownOption> = (
    selectedDropdownOption,
    defaultRender,
  ) => {
    // Note: This code does not(!) support multiselection
    return (
      <Stack
        horizontal
        className={classnames(
          "detailDropdownOptionSelected",
          classNames.detailOptionSelected,
        )}
        tokens={{ childrenGap: "0.5em" }}
      >
        <ValueImage
          image={selectedDropdownOption?.image}
          imageResolution={imageResolution}
          mediaLink={props.mediaLink}
          className={classnames(
            "detailDropdownOptionSelectedValueImage",
            showImage
              ? mergeStyles(classNames.imageContainer, {
                  marginTop: "0.33rem",
                })
              : undefined,
          )}
          size={"1.2rem"}
          showImage={showImage}
        />
        <Stack.Item
          style={{ width: "calc(100% - 0.6rem)" }}
          className={"detailDropdownStackItem"}
        >
          {defaultRender && defaultRender(selectedDropdownOption)}
        </Stack.Item>
      </Stack>
    );
  };

  const renderOption: onRenderItem = (
    dropdownOption: IDetailDropdownOption,
    changeValue,
    defaultRender,
  ) => {
    const optionStyle = {
      marginTop: showImage ? "0.1rem" : undefined,
      overflow: "hidden",
      width: "100%",
    };
    return (
      <Stack
        horizontal
        onClick={() => changeValue && changeValue(dropdownOption)}
        key={dropdownOption.key}
        data-is-focusable
        className={classnames(
          "detailDropdownDetailOption",
          `${classNames.detailOption} ${
            dropdownOption.selected ? "encoway-cui-dropdown-selected-value" : ""
          }`,
        )}
      >
        <ValueImage
          image={dropdownOption.image}
          imageResolution={imageResolution}
          mediaLink={props.mediaLink}
          className={classnames(
            "detailDropdownDetailOptionValueImage",
            dropdownOption.shortText && showShortText
              ? mergeStyles(classNames.imageContainer, {
                  marginLeft: "0.2rem",
                })
              : undefined,
          )}
          size={"2rem"}
          showImage={showImage}
        />
        <Stack
          style={optionStyle}
          tokens={{ childrenGap: "0px" }}
          className={"detailDropdownValueWrapper"}
        >
          <Stack.Item className={"detailDropdownValueItem"}>
            {defaultRender(dropdownOption, changeValue)}
          </Stack.Item>
          <ShortText
            classNames={classNames}
            dropdownOption={dropdownOption}
            showShortText={showShortText}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <Dropdown
      {...props}
      onRenderItem={renderOption}
      onRenderTitle={renderTitle}
      onCreateDropdownOption={createDropdownOption}
    />
  );
};

export const DetailDropdown = styled(IDetailDropdown, DetailDropdownStyles);
DetailDropdown.displayName = "DetailDropdown";
