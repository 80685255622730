import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IInputFieldStyles } from "./InputField.types";

export const InputFieldStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IInputFieldStyles>
> = (theme: IPartialTheme) => ({
  root: {},
  horizontalStack: {
    selectors: {
      ">*:not(:last-child)": {
        marginRight: "0.5em",
      },
    },
  },
  textField: {
    selectors: {
      ".ms-TextField-fieldGroup": {
        borderColor: theme.palette?.neutralTertiary,
      },
      ".ms-TextField-fieldGroup:hover": {
        borderColor: theme.palette?.neutralSecondary,
      },
      "input::placeholder": {
        fontStyle: "italic",
        color: theme.palette?.neutralSecondary,
      },
    },
  },
  enabled: {
    selectors: {
      ".ms-TextField-suffix": {
        backgroundColor: "transparent",
      },
    },
  },
  disabled: {
    selectors: {
      ".ms-TextField-field": {
        backgroundColor: theme.palette?.neutralLight,
        color: theme.semanticColors?.disabledText,
      },
      ".ms-TextField-fieldGroup": {
        borderColor: theme.palette?.neutralLight,
      },
      ".ms-TextField-fieldGroup:hover": {
        borderColor: theme.palette?.neutralLight,
      },
      ".ms-TextField-suffix": {
        backgroundColor: theme.palette?.neutralLight,
        color: theme.semanticColors?.disabledText,
      },
    },
  },
  notReady: {
    selectors: {
      ".ms-TextField-fieldGroup": {
        borderColor: theme.semanticColors?.warningIcon,
        backgroundColor: theme.semanticColors?.warningBackground,
      },
      ".ms-TextField-fieldGroup:after": {
        borderColor: theme.semanticColors?.warningIcon,
      },
      ".ms-TextField-fieldGroup:hover": {
        borderColor: theme.semanticColors?.warningIcon,
      },
    },
  },
  error: {
    color: theme.semanticColors?.errorIcon,
    selectors: {
      ".ms-TextField-fieldGroup": {
        borderColor: theme.semanticColors?.errorIcon,
        backgroundColor: theme.semanticColors?.errorIcon + "26", // use alpha of 15%
      },
      ".ms-TextField-fieldGroup:after": {
        borderColor: theme.semanticColors?.errorIcon,
      },
      ".ms-TextField-fieldGroup:hover": {
        borderColor: theme.semanticColors?.errorIcon,
      },
      ":not(:first-child)": {
        marginTop: "5px",
      },
    },
  },
});
