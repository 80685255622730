import { AxiosInstance, AxiosResponse } from "axios";
import { reject, isNil, map, split } from "ramda";

import { Language } from "@encoway/c-services-js-client";

export async function fetchLanguages(
  instance: AxiosInstance,
  language: string,
): Promise<Language[]> {
  const res = await instance.get<{ languages: Language[] }>(
    `api/catalog/languages`,
    { headers: { "Accept-Language": language } },
  );
  return res.data.languages;
}

export async function fetchResource<T>(
  instance: AxiosInstance,
  uri: string,
  language: string,
): Promise<T> {
  const translationRes = await instance.get<T>(uri, {
    headers: { "Accept-Language": language },
  });
  return translationRes.data;
}

export async function fetchURLsToBase64(
  instance: AxiosInstance,
  urls: (string | undefined)[],
): Promise<string[]> {
  const filteredURLs = reject(isNil, urls);
  const responses: AxiosResponse<ArrayBuffer>[] = await Promise.all(
    map(
      (url) => instance.get(url, { responseType: "arraybuffer" }),
      filteredURLs,
    ),
  );
  return responses.map((response, i) => {
    const data = new Uint8Array(response.data);
    const characters = Array.from(data, (byte) => String.fromCharCode(byte));
    return `data:image/${split(".", filteredURLs[i]).pop()};base64,${btoa(characters.join(""))}`;
  });
}
