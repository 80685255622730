import { Stack } from "@fluentui/react";
import { head, split } from "ramda";
import { useContext } from "react";

import {
  ComponentFactory,
  Configurator as EncowayConfigurator,
} from "@encoway/react-configurator";

import { ConfigurationContext } from "../../context/useConfiguration";
import { ConfCheckbox } from "./components/ConfCheckbox";
import { ConfDisplay } from "./components/ConfDisplay";
import { ConfDisplayTitle } from "./components/ConfDisplayTitle";
import { ConfEpri } from "./components/ConfEpri";
import { ConfInput } from "./components/ConfInput";
import { ConfParameter } from "./components/ConfParameter";
import { ConfPriority } from "./components/ConfPriority";
import { ConfRadio } from "./components/ConfRadio";
import { ConfSection } from "./components/ConfSection";
import { ConfSelect } from "./components/ConfSelect";
import { ConfSoftConstraint } from "./components/ConfSoftConstraint";
import { BurgerMenu } from "./components/standard/BurgerMenu/BurgerMenu";
import { DetailButton } from "./components/standard/DetailButton/DetailButton";
import { DetailDropdown } from "./components/standard/DetailDropdown/DetailDropdown";
import { Display } from "./components/standard/Display/Display";
import { Dropdown } from "./components/standard/Dropdown/Dropdown";
import { ErrorDialog } from "./components/standard/ErrorDialog/ErrorDialog";
import { FilterDropdown } from "./components/standard/FilterDropdown/FilterDropdown";
import { Hidden } from "./components/standard/Hidden/Hidden";
import { ImageButton } from "./components/standard/ImageButton/ImageButton";
import { InfoButton } from "./components/standard/InfoButton/InfoButton";
import { InputField } from "./components/standard/InputField/InputField";
import { LinkedTree } from "./components/standard/LinkedTree/LinkedTree";
import { Message } from "./components/standard/Message/Message";
import { NotReadyCount } from "./components/standard/NotReadyCount/NotReadyCount";
import { OptionalPositionDisplay } from "./components/standard/OptionalPositionDisplay/OptionalPositionDisplay";
import { Price } from "./components/standard/Price/Price";
import { Quantity } from "./components/standard/Quantity/Quantity";
import { RadioButton } from "./components/standard/RadioButton/RadioButton";
import { Root } from "./components/standard/Root/Root";
import { Spinner } from "./components/standard/Spinner/Spinner";
import { StateIcon } from "./components/standard/StateIcon/StateIcon";
import { Tab } from "./components/standard/Tab/Tab";
import { TabButton } from "./components/standard/TabButton/TabButton";
import { Tabs } from "./components/standard/Tabs/Tabs";
import { TabsFooter } from "./components/standard/TabsFooter/TabsFooter";
import { TabsList } from "./components/standard/TabsList/TabsList";
import { TextButton } from "./components/standard/TextButton/TextButton";
import { ComponentName } from "./components/standard/constants";

// Handle inconsistent namings
ComponentFactory.addAlias("tabs", ComponentName.DummyViewport);
ComponentFactory.addAlias("selectBox", ComponentName.Dropdown);
ComponentFactory.addAlias("readOnlyInputField", ComponentName.DisplayOnly);

// legacy gui model stuff
ComponentFactory.addAlias("crm,default.kly", ComponentName.Tabs);
ComponentFactory.addAlias("crm,paging.kly", ComponentName.Section);
ComponentFactory.addAlias(
  "parameter,label_dynamic_pair.kly",
  ComponentName.DisplayOnly,
);
ComponentFactory.addAlias(
  "master_viewport_layout_tabbed_pane_main.kly",
  ComponentName.Tabs,
);
ComponentFactory.addAlias(
  "master_viewport_layout_tabbed_pane_traffic_light_main.kly",
  ComponentName.Tabs,
);
ComponentFactory.addAlias(
  ComponentName.StructureViewport,
  ComponentName.Section,
);
ComponentFactory.addAlias(ComponentName.DummyViewport, ComponentName.Root);

// structural components
ComponentFactory.register(ComponentName.Root, Root);
ComponentFactory.register(ComponentName.LinkedTree, LinkedTree);
ComponentFactory.register(ComponentName.Tabs, Tabs);
ComponentFactory.register(ComponentName.TabsFooter, TabsFooter);

// helping components
ComponentFactory.register(ComponentName.ErrorViewport, ErrorDialog);
ComponentFactory.register(ComponentName.Hidden, Hidden);
ComponentFactory.register(ComponentName.InfoButton, InfoButton);
ComponentFactory.register(ComponentName.Message, Message);
ComponentFactory.register(ComponentName.NotReadyCount, NotReadyCount);
ComponentFactory.register(ComponentName.Quantity, Quantity);
ComponentFactory.register(ComponentName.StateIcon, StateIcon);
ComponentFactory.register(ComponentName.Tab, Tab);
ComponentFactory.register(ComponentName.TabButton, TabButton);
ComponentFactory.register(ComponentName.TabsList, TabsList);

ComponentFactory.register(ComponentName.Label, Display);
ComponentFactory.register(ComponentName.Price, Price);
ComponentFactory.register(
  ComponentName.OptionalPositionDisplay,
  OptionalPositionDisplay,
);

ComponentFactory.register(ComponentName.Spinner, Spinner);
// Studio Widgets
ComponentFactory.register(ComponentName.BurgerMenu, BurgerMenu);
ComponentFactory.register(ComponentName.DisplayOnly, Display);
ComponentFactory.register(ComponentName.Dropdown, Dropdown);
ComponentFactory.register(ComponentName.FilterDropdown, FilterDropdown);
ComponentFactory.register(ComponentName.DetailButton, DetailButton);
ComponentFactory.register(ComponentName.DetailDropdown, DetailDropdown);
ComponentFactory.register(ComponentName.ImageButton, ImageButton);
ComponentFactory.register(ComponentName.InputField, InputField);
ComponentFactory.register(ComponentName.RadioButton, RadioButton);
ComponentFactory.register(ComponentName.TextButton, TextButton);

// Custom Overwrite
ComponentFactory.register(ComponentName.Section, ConfSection);
ComponentFactory.register(ComponentName.Parameter, ConfParameter);

// Custom Widgets
ComponentFactory.register(ComponentName.Epri, ConfEpri);
ComponentFactory.register(ComponentName.Checkbox, (props) => (
  <ConfParameter {...props}>
    <ConfCheckbox {...props} />
  </ConfParameter>
));
ComponentFactory.register("CustomDisplayOnly", (props) => (
  <ConfParameter {...props}>
    <ConfDisplay {...props} />
  </ConfParameter>
));
ComponentFactory.register("CustomDisplayTitle", (props) => (
  <ConfParameter {...props}>
    <ConfDisplayTitle {...props} />
  </ConfParameter>
));
ComponentFactory.register("CustomSelect", (props) => {
  return (
    <ConfParameter {...props}>
      <ConfSelect {...props} />
    </ConfParameter>
  );
});
ComponentFactory.register("CustomSelectImage", (props) => {
  return (
    <ConfParameter {...props}>
      <ConfSelect {...props} resolution="small" />
    </ConfParameter>
  );
});
ComponentFactory.register("CustomSelectImageBig", (props) => {
  return (
    <ConfParameter {...props}>
      <ConfSelect {...props} resolution="large" />
    </ConfParameter>
  );
});
ComponentFactory.register("CustomPriorityOld", (props) => (
  <ConfParameter {...props}>
    <ConfPriority {...props} />
  </ConfParameter>
));
ComponentFactory.register("priority", () => null);
ComponentFactory.register("CustomRadio", (props) => (
  <ConfParameter {...props}>
    <ConfRadio {...props} />
  </ConfParameter>
));
ComponentFactory.register("CustomInput", (props) => (
  <ConfParameter {...props}>
    <ConfInput {...props} />
  </ConfParameter>
));
ComponentFactory.register("CustomSoftConstraint", ConfSoftConstraint);

export function Configurator() {
  const { cfg, eventBus, language } = useContext(ConfigurationContext);
  if (cfg) {
    return (
      <>
        <Stack.Item align="auto">
          <div id="cui">
            <EncowayConfigurator
              lang={head(split("-", language)) || language}
              config={cfg}
              eventBus={eventBus}
              options={{
                dontDeleteOnIdChange: false,
                deleteOnUnmount: false,
                hideLinkedTree: true,
              }}
              styles={{
                section: {
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  overflow: "hidden",
                },
                main: {
                  marginTop: ".5em",
                  flex: 1,
                },
                aside: {
                  display: "flex",
                  overflow: "auto",
                },
              }}
            />
          </div>
        </Stack.Item>
      </>
    );
  }

  return <p>Error</p>;
}
