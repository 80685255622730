import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IPriceStyles } from "./Price.types";

export const PriceStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IPriceStyles>
> = () => ({
  root: {},
});
