import { Language } from "@encoway/c-services-js-client";

export function toLanguage(
  acc: { [locale: string]: Language },
  { displayName, tag }: Language,
): { [locale: string]: Language } {
  return {
    ...acc,
    [tag]: { tag, displayName },
  };
}
