import { Stack } from "@fluentui/react";
import { head } from "ramda";
import { useContext, useEffect } from "react";

import { ParameterTO } from "@encoway/c-services-js-client";
import { Constants } from "@encoway/react-configurator";

import { ConfigurationContext } from "../../../context/useConfiguration";
import { ConfiguratorComponentProps } from "../../../types/configuration";
import {
  determinePriorityParameters,
  getWatchParamIds,
  prioritySetParameter,
  priorityUndoParameter,
  SELECTION_SOURCE,
} from "./confPriorityUtils";

function toLocale(input: string | number | undefined = "") {
  return Number(input).toLocaleString("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
}

export function ConfPriority({
  data,
  onValueChanged,
  ...props
}: ConfiguratorComponentProps<ParameterTO>) {
  const { guiTO } = useContext(ConfigurationContext);

  useEffect(() => {
    if (guiTO) {
      const watchParamIds = getWatchParamIds(
        data.viewPortProperties as { [key: string]: string },
      );
      const watchParams = determinePriorityParameters(guiTO, watchParamIds);
      const selectedValue = head(
        data.selectedValues || [
          { value: undefined, selectionSource: SELECTION_SOURCE.NOT_SET },
        ],
      );
      if (priorityUndoParameter(watchParams, selectedValue)) {
        onValueChanged(data, Constants.Undo);
      } else if (prioritySetParameter(data, watchParams, selectedValue)) {
        onValueChanged(data, data.minValue!);
      }
    }
  }, [guiTO]);

  const selectedValue = head(data?.selectedValues || [{ value: undefined }]);

  return (
    <Stack>
      <Stack>
        <strong>
          {selectedValue?.value ? `${toLocale(selectedValue?.value)}` : "-"}
        </strong>
      </Stack>
      <Stack>
        {toLocale(data.minValue)}/{toLocale(data.maxValue)} min/max
      </Stack>
      <Stack>{selectedValue?.selectionSource || "NOT_SET"} </Stack>
      <Stack>{data.values?.length} Values</Stack>
    </Stack>
  );
}
