import {
  all,
  any,
  equals,
  filter,
  head,
  isEmpty,
  not,
  replace,
  split,
} from "ramda";

import { GuiTO, ParameterTO } from "@encoway/c-services-js-client";

import { SETTINGS } from "../../../settings";
import { findDeepContainer } from "../configuratorUtils";

export enum SELECTION_SOURCE {
  NOT_SET = "NOT_SET",
  SET_BY_DEFAULT = "SET_BY_DEFAULT",
  SET_BY_SYSTEM = "SET_BY_SYSTEM",
  SET_BY_EXTERNAL = "SET_BY_EXTERNAL",
  SET_BY_USER = "SET_BY_USER",
}

export function getWatchParamIds(viewPortProperties: {
  [key: string]: string;
}): string[] {
  return split(
    ";",
    replace(
      /^{"([^"]+)"}$/,
      "$1",
      viewPortProperties[SETTINGS.configuration.viewportProperties.priority],
    ),
  );
}

export function determinePriorityParameters(
  guiTO: GuiTO | undefined,
  ids: string[],
): ParameterTO[] {
  if (guiTO) {
    const priorityContentBlock = head(
      findDeepContainer([guiTO.rootContainer], "name", [
        SETTINGS.configuration.priority.contentBlock,
      ]),
    );
    if (priorityContentBlock) {
      return filter(
        ({ name }) => any(equals(name), ids),
        priorityContentBlock.parameters,
      );
    }
  }
  return [];
}

export function priorityUndoParameter(
  watchParams: ParameterTO[],
  selectedValue:
    | undefined
    | { value: string; selectionSource: SELECTION_SOURCE },
): boolean {
  return Boolean(
    selectedValue &&
      not(isEmpty(watchParams)) &&
      equals(selectedValue.selectionSource, SELECTION_SOURCE.SET_BY_USER) &&
      any((watchParam) => !watchParam.terminal!, watchParams),
  );
}

export function prioritySetParameter(
  data: ParameterTO,
  watchParams: ParameterTO[],
  selectedValue:
    | undefined
    | { value: string; selectionSource: SELECTION_SOURCE },
): boolean {
  if (not(isEmpty(watchParams))) {
    if (
      !selectedValue ||
      (selectedValue.value &&
        !equals(Number(selectedValue.value), Number(data.minValue)))
    ) {
      return all((watchParam) => watchParam.terminal!, watchParams);
    }
  }
  return false;
}
