import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { Login } from "./pages/login/Login";

const sessionBasic = localStorage.getItem("basic");

ReactDOM.render(
  sessionBasic ? <App /> : <Login />,
  document.getElementById("encoway-configurator"),
);
