import { mergeStyleSets, Text } from "@fluentui/react";
import classNames from "classnames";
import { addIndex, map } from "ramda";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { SideBarContext } from "../../../context/useSidebar";

const sidebarListStyled = mergeStyleSets({
  root: {
    flexFlow: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textLabel: {
    selectors: {
      "&.warning": {
        color: "#ed1c24",
      },
    },
    fontWeight: 600,
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  li: {
    padding: ".5em 0",
    selectors: {
      ":first-child": {
        padding: "0",
      },
    },
  },
});

export function SidebarInfo() {
  const { infos, image, text, warning } = useContext(SideBarContext);
  const { t } = useTranslation();
  const { ul, li, root, textLabel } = sidebarListStyled;
  return (
    <div className={classNames("sideBarInfo", root)}>
      {text && (
        <Text className={classNames("sideBarInfoText", textLabel, { warning })}>
          {t(text)}
        </Text>
      )}
      {image && <img src={image} alt="SideBarInfoImage" />}
      <ul className={classNames("sidebarList", ul)}>
        {addIndex(map)(
          (value, index) => (
            <li key={index} className={classNames("sidebarListValue", li)}>
              <div>{value}</div>
            </li>
          ),
          infos,
        )}
      </ul>
    </div>
  );
}
