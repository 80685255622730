import {
  IStackTokens,
  mergeStyleSets,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import React from "react";

const useStyles = () => {
  const theme = useTheme();
  return mergeStyleSets({
    categoryTitle: {
      fontSize: theme.fonts.xLarge.fontSize,
      fontWeight: theme.fonts.xLarge.fontWeight,
      color: theme.palette.neutralPrimary,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      paddingBottom: "10px",
    },
  });
};

const stackTokens: IStackTokens = { childrenGap: 20 };

interface CategoryGroupProps {
  name: string;
  children: React.ReactNode;
}

export function CategoryGroup({ name, children }: CategoryGroupProps) {
  const styles = useStyles();
  return (
    <Stack tokens={stackTokens}>
      <Text className={styles.categoryTitle}>{name}</Text>
      {children}
    </Stack>
  );
}
