import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import { Provider } from "./Provider";
import { Layout } from "./layout/layout";
import { Catalog } from "./pages/catalog/Catalog";
import { LoadConfigurator } from "./pages/configurator/LoadConfigurator";
import { Login } from "./pages/login/Login";
import { Mocks } from "./pages/mocks/Mocks";

import "./App.css";

function App() {
  return (
    <Provider>
      <Mocks />
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="login" element={<Login />} />
            <Route index element={<Catalog />} />
            <Route path="products">
              <Route path=":product" element={null} />
              <Route index element={<Catalog />} />
            </Route>
            <Route path="configuration">
              <Route path=":article" element={<LoadConfigurator />} />
              <Route path=":article/:session" element={<LoadConfigurator />} />
              <Route
                path=":article/:session/:action"
                element={<LoadConfigurator />}
              />
              <Route index element={<LoadConfigurator />} />
            </Route>
          </Route>
        </Routes>
      </HashRouter>
    </Provider>
  );
}

export default App;
