import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IErrorDialogStyles } from "./ErrorDialog.types";

export const ErrorDialogStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IErrorDialogStyles>
> = (theme: IPartialTheme) => ({
  modal: {},

  overlay: {},

  dialog: {},

  continueButton: {
    border: "none",
    borderRadius: 0,
    selectors: {
      ":focus&::after": {
        outlineOffset: "3",
        outlineColor: theme.semanticColors?.focusBorder,
      },
      ":hover": {
        border: "none",
      },
      ":active": {
        border: "none",
      },
    },
  },

  footer: {},
});
