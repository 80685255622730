import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IDetailButtonStyles } from "./DetailButton.types";

export const DetailButtonStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IDetailButtonStyles>
> = (theme: IPartialTheme) => ({
  root: {},
  list: {},
  detailButton: {
    cursor: "pointer",
    width: "100%",
    border: "solid 1px",
    borderColor: theme.palette?.neutralTertiary || "",
    selectors: {
      ":focus-visible": {
        outline: `1px solid ${theme.semanticColors?.focusBorder}`,
        outlineOffset: "0.25rem",
      },
      ":hover": {
        borderColor: theme.palette?.neutralSecondary,
      },
    },
  },
  detailButtonSelected: {
    backgroundColor: theme.palette?.themePrimary,
    selectors: {
      ":hover": {
        borderColor: theme.palette?.themeDark,
      },
    },
  },
  detailButtonNotReady: {
    borderColor: theme.semanticColors?.warningIcon,
    selectors: {
      ":hover": {
        borderColor: theme.semanticColors?.warningIcon,
      },
    },
  },
  detailButtonDisabled: {
    border: "none",
    cursor: "default",
    backgroundColor: theme.palette?.neutralLight,
    selectors: {
      ":hover": {
        borderColor: theme.palette?.neutralLight,
      },
    },
  },
  detailButtonSelectedDisabled: {
    borderColor: theme.palette?.neutralTertiary,
    border: "solid 2px",
    selectors: {
      ":hover": {
        borderColor: theme.palette?.neutralTertiary,
      },
    },
  },
  rightContainer: {
    padding: "1rem 1rem 1rem 0.5rem",
  },
  imageContainer: {
    padding: "1rem",
  },
  imageContainerNotSelected: {
    selectors: {
      ".ms-Image": {
        img: {
          opacity: 0.5,
        },
      },
    },
  },
  imageContainerConflict: {
    ".ms-Image": {
      img: {
        opacity: 0.5,
      },
    },
  },
  imageContainerDisabled: {
    selectors: {
      ".ms-Image": {
        img: {
          opacity: 0.5,
        },
      },
    },
  },
  upperRow: {
    marginBottom: "0.5rem",
  },
  lowerRow: {
    marginRight: "0.5rem",
  },
  text: {
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  textSelected: {
    color: theme.palette?.white,
  },
  textNotSelected: {
    color: theme.palette?.neutralSecondary,
  },
  textDisabled: {
    color: theme.palette?.neutralSecondary,
  },
  shortText: {
    wordBreak: "break-word",
    textAlign: "left",
  },
  shortTextSelected: {
    color: theme.palette?.white,
  },
  shortTextNotSelected: {
    color: theme.palette?.neutralSecondary,
  },
  shortTextDisabled: {
    color: theme.palette?.neutralSecondary,
  },
  price: {
    display: "inline-flex",
    flexGrow: 3,
    justifyContent: "flex-end",
  },
  priceSelected: {
    selectors: {
      span: {
        color: theme.palette?.white,
      },
    },
  },
  priceNotSelected: {
    selectors: {
      span: {
        color: theme.palette?.neutralSecondary,
      },
    },
  },
  priceDisabled: {},
  infoButton: {
    div: {
      a: {
        selectors: {
          ":focus:not(:hover)": {
            color: `${theme.palette?.themePrimary}`,
          },
        },
      },
    },
  },
  infoButtonSelected: {
    div: {
      a: {
        color: theme.palette?.white,
        selectors: {
          ":hover": {
            color: theme.palette?.themeTertiary,
          },
          ":active:hover": {
            color: theme.palette?.themeTertiary,
          },
          ":focus-visible": {
            color: `${theme.palette?.themeTertiary} !important`,
          },
          ":focus:not(:hover)": {
            color: `${theme.palette?.themeTertiary} !important`,
          },
          ":focus:not(:focus-visible):not(:hover)": {
            color: `${theme.palette?.white} !important`,
          },
        },
      },
    },
  },
  infoButtonDisabled: {
    div: {
      a: {
        color: theme.palette?.neutralSecondary,
      },
    },
  },
});
