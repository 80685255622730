import { equals, gt, map, match, not, pathOr } from "ramda";
import { useMemo } from "react";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { Section } from "../../../components/Section";
import { useSortParameter } from "../../../hooks/useSortParameter";
import { SETTINGS } from "../../../settings";
import { ConfiguratorComponentProps } from "../../../types/configuration";
import { deepFindAnyParameter } from "../configuratorUtils";
import { ConfGrid } from "./ConfGrid";
import { getTitle } from "./confSectionUtils";

export function ConfSection({
  data,
  ...props
}: ConfiguratorComponentProps<ContainerTO>) {
  const matchGrid = match(SETTINGS.configuration.grid.contentBlock, data.name);
  const sortedParameterValues = useSortParameter(data.parameters);

  const showArrow = useMemo(
    () => gt(pathOr(0, ["numberOfVisibleParameters"], data), 0),
    [data],
  );

  if (matchGrid.length > 1) {
    return <ConfGrid {...{ ...props, data, size: matchGrid[1] }} />;
  }

  if (
    equals(data.name, "CB_SOFT_CONSTRAINT") ||
    not(deepFindAnyParameter([data]))
  ) {
    return null;
  }

  return (
    <Section title={getTitle(data)} showArrow={showArrow}>
      {data.parameters &&
        map(
          (parameter) =>
            ComponentFactory.instanceOf(parameter.viewPort!, {
              ...props,
              data: {
                ...parameter,
                values: sortedParameterValues[parameter.name],
              },
              key: parameter.id,
            }),
          data.parameters,
        )}
      {data.children &&
        map(
          (container) => (
            <ConfSection
              key={container.id}
              {...{ ...props, data: container }}
            />
          ),
          data.children,
        )}
    </Section>
  );
}
