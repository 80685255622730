import { Callout, DirectionalHint } from "@fluentui/react/lib/Callout";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import * as React from "react";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { ComponentName, READY_STATE_NOT_READY } from "../constants";
import { containsVisibleParameter } from "../helperFunctions";
import { TabsListStyles } from "./TabsList.styles";
import { ITabsListStyles, TabsListProps } from "./TabsList.types";

/**
 * The TabsList component renders a callout with a list of all tabs of a given container.
 * A tab with pending mandatory decisions is marked with the NotReadyCount component.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabsList/TabsList.tsx)
 * - [TabsListStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabsList/TabsList.styles.ts)
 * - [TabsListProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabsList/TabsList.types.ts)
 * - [MS Fluent Stack](https://developer.microsoft.com/de-DE/fluentui#/controls/web/stack)
 * - [MS Fluent Text](https://developer.microsoft.com/de-DE/fluentui#/controls/web/text)
 * - [MS Fluent Callout](https://developer.microsoft.com/de-DE/fluentui#/controls/web/callout)
 *
 * @visibleName TabsList
 */
const ITabsList = (props: TabsListProps) => {
  const { styles, theme } = props;
  const classNames: IProcessedStyleSet<ITabsListStyles> = classNamesFunction()(
    styles,
    theme,
  );
  const links = props.data.children
    .filter(containsVisibleParameter)
    .map((container: ContainerTO) => {
      const notReadyCount = ComponentFactory.instanceOf(
        ComponentName.NotReadyCount,
        { data: container },
      );
      let className = classNames.link;
      if (props.selectedTabID === container.id) {
        className = mergeStyles(className, classNames.linkSelected);
      }
      return (
        <Stack
          key={container.id}
          horizontal
          tokens={{ childrenGap: "0.5em" }}
          className={"tabsListStack"}
        >
          <Text
            nowrap
            className={classnames("tabsListStackText", className)}
            onClick={() => props.onClick?.(container)}
          >
            {container.translatedName}
          </Text>
          {container.readyState === READY_STATE_NOT_READY && notReadyCount}
        </Stack>
      );
    });

  return (
    <Callout
      className={classnames("tabsListCallout", classNames.callout)}
      directionalHint={DirectionalHint.bottomLeftEdge}
      target={`#encoway-cui-configuration-tabs-button-${props.data.id}`}
      onDismiss={() => props.onDismiss?.()}
    >
      <Stack
        className={classnames("tabsListCalloutStack", classNames.stack)}
        tokens={{ childrenGap: "0.5em" }}
      >
        {links}
      </Stack>
    </Callout>
  );
};

/**
 * The TabsList component renders a callout with a list of all tabs of a given container.
 * A tab with pending mandatory decisions is marked with the NotReadyCount component.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabsList/TabsList.tsx)
 * - [TabsListStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabsList/TabsList.styles.ts)
 * - [TabsListProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/TabsList/TabsList.types.ts)
 * - [MS Fluent Stack](https://developer.microsoft.com/de-DE/fluentui#/controls/web/stack)
 * - [MS Fluent Text](https://developer.microsoft.com/de-DE/fluentui#/controls/web/text)
 * - [MS Fluent Callout](https://developer.microsoft.com/de-DE/fluentui#/controls/web/callout)
 *
 * @visibleName TabsList
 */
export const TabsList = styled(ITabsList, TabsListStyles);
TabsList.displayName = "TabsList";
