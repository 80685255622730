import { Stack } from "@fluentui/react";
import { Outlet } from "react-router-dom";

const styled = {
  root: {
    "@media (min-width: 1400px)": {
      mt: "20px",
    },
  },
};

export function Layout() {
  return (
    <div id="encoway">
      <Stack styles={styled}>
        <Outlet />
      </Stack>
    </div>
  );
}
