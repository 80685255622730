import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { ITextButtonStyles } from "./TextButton.types";

export const TextButtonStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<ITextButtonStyles>
> = (theme: IPartialTheme) => ({
  root: {},
  option: {
    maxWidth: "13rem",
    minWidth: "3rem",
    height: "100%",
    padding: "0.5rem 1rem",
    backgroundColor: "transparent",
    selectors: {
      ":hover": {
        backgroundColor: "",
        color: "",
        borderColor: theme.palette?.neutralSecondary,
      },
      ":active": {
        backgroundColor: "",
        color: "",
      },
      ".ms-Fabric--isFocusVisible &:focus::after": {
        outlineColor: theme.palette?.themePrimary,
        outlineOffset: "6px",
      },
    },
  },
  optionsContainer: {},
  optionContentContainer: {
    width: "100%",
    justifyContent: "center",
  },
  optionText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
  },
  optionTextSelected: {
    color: theme.palette?.white,
  },
  optionTextNotSelected: {
    color: theme.palette?.neutralSecondary,
  },
  optionTextDisabled: {
    color: theme.palette?.neutralSecondary,
  },
  optionSelected: {
    backgroundColor: theme.palette?.themePrimary,
    color: theme.palette?.white,
    fontWeight: "bold",
    borderColor: theme.palette?.themePrimary,
    selectors: {
      ":hover": {
        borderColor: theme.palette?.themeDark,
      },
    },
  },
  optionNotSelected: {
    borderColor: theme.palette?.neutralTertiary,
  },
  optionNotReady: {
    borderColor: theme.semanticColors?.warningIcon,
    selectors: {
      ":hover": {
        borderColor: theme.semanticColors?.warningIcon,
      },
    },
  },
  optionConflict: {
    backgroundColor: theme.palette?.neutralLight,
    border: 0,
  },
  optionDisabled: {
    border: "none",
    cursor: "default",
    backgroundColor: theme.palette?.neutralLight,
    color: theme.palette?.neutralSecondary,
    selectors: {
      ":hover": {
        borderColor: theme.palette?.neutralLight,
      },
    },
  },
  optionSelectedDisabled: {
    fontWeight: "bold",
    borderColor: theme.palette?.neutralTertiary,
    border: "solid 2px",
    selectors: {
      ":hover": {
        borderColor: theme.palette?.neutralTertiary,
      },
    },
  },
  price: {
    marginTop: "0.4rem",
  },
  priceDisabled: {
    span: {
      color: theme.palette?.neutralSecondary,
    },
  },
  priceSelected: {
    span: {
      color: theme.palette?.white,
    },
  },
  priceNotSelected: {
    span: {
      color: theme.palette?.neutralSecondary,
    },
  },
  infoButton: {
    selector: {
      "&:empty": {
        marginLeft: "0 !important",
      },
    },
  },
  infoButtonDisabled: {
    div: {
      a: {
        color: theme.palette?.neutralSecondary,
      },
    },
  },
  infoButtonSelected: {
    div: {
      a: {
        color: theme.palette?.white,
        selectors: {
          ":hover": {
            color: theme.palette?.themeTertiary,
          },
          ":active:hover": {
            color: theme.palette?.themeTertiary,
          },
          ":focus-visible": {
            color: `${theme.palette?.themeTertiary} !important`,
          },
          ":focus:not(:focus-visible):not(:hover)": {
            color: `${theme.palette?.white} !important`,
          },
          ":focus:not(:hover)": {
            color: `${theme.palette?.themeTertiary} !important`,
          },
        },
      },
    },
  },
  infoButtonNotSelected: {
    div: {
      a: {
        color: theme.palette?.neutralTertiary,
        selectors: {
          ":focus:not(:focus-visible):not(:hover)": {
            color: `${theme.palette?.neutralSecondary} !important`,
          },
        },
      },
    },
  },
});
