import { PartialTheme } from "@fluentui/react";

export const mediaQueryWidth = {
  sm: "@media only screen and (max-width:576px)",
  md: "@media only screen and (max-width:768px)",
  lg: "@media only screen and (max-width:992px)",
  sidebarThreshold: "@media only screen and (max-width:1200px)",
  xl: "@media only screen and (max-width:1800px)",
  xxl: "@media only screen and (min-width:1801px)",
  xxxl: "@media only screen and (max-width:1919px)",
};

export const mediaQueryHeight = {
  sm: "@media only screen and (max-height:576px)",
  md: "@media only screen and (max-height:768px)",
  lg: "@media only screen and (max-height:992px)",
  xl: "@media only screen and (min-height:993px)",
};

export const theme: PartialTheme = {
  palette: {
    themePrimary: "#d47b00",
    themeLighterAlt: "#080500",
    themeLighter: "#221400",
    themeLight: "#3f2500",
    themeTertiary: "#7f4a00",
    themeSecondary: "#ba6d00",
    themeDarkAlt: "#d88716",
    themeDark: "#de9835",
    themeDarker: "#e7b166",
    neutralLighterAlt: "#f8f8f8",
    neutralLighter: "#f4f4f4",
    neutralLight: "#eaeaea",
    neutralQuaternaryAlt: "#dadada",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c8c8",
    neutralTertiary: "#595959",
    neutralSecondary: "#373737",
    neutralPrimaryAlt: "#2f2f2f",
    neutralPrimary: "#000000",
    neutralDark: "#151515",
    black: "#0b0b0b",
    white: "#ffffff",
  },
  defaultFontStyle: {
    ".configurator-grid": {
      padding: "10px 0px 10px 10px",
      display: "grid",
      gap: "20px 0",
      justifyContent: "stretch",
      alignContent: "center",
      justifyItems: "stretch",
    },
    ".grid-1": {
      gridTemplateColumns: "1fr",
    },
    ".grid-2": {
      gridTemplateColumns: ".2fr 1fr",
    },
    ".grid-3": {
      gridTemplateColumns: ".4fr 1fr 1fr",
    },
    ".grid-4": {
      gridTemplateColumns: ".6fr 1fr 1fr 1fr",
    },
    ".grid-5": {
      gridTemplateColumns: ".8fr 1fr 1fr 1fr 1fr",
    },
    ".grid-6": {
      gridTemplateColumns: ".10fr 1fr 1fr 1fr 1fr 1fr",
    },
    ".grid-7": {
      gridTemplateColumns: ".12fr 1fr 1fr 1fr 1fr 1fr 1fr",
    },
    ".grid-8": {
      gridTemplateColumns: ".14fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    },
    ".grid-9": {
      gridTemplateColumns: ".16fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    },
    ".grid-10": {
      gridTemplateColumns: ".18fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    },
    [mediaQueryWidth.md]: {
      ".grid-md-1": {
        gridTemplateColumns: "1fr",
      },
    },
  },
};
