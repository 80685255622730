import { ChevronDownIcon, ChevronUpIcon } from "@fluentui/react-icons-mdl2";
import { INavLink, INavLinkGroup, Nav } from "@fluentui/react/lib/Nav";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, registerIcons } from "@fluentui/react/lib/Styling";
import {
  classNamesFunction,
  IRenderFunction,
  styled,
} from "@fluentui/react/lib/Utilities";
import { Bus } from "baconjs";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { ContainerTO, LinkedTree as Tree } from "@encoway/c-services-js-client";
import { ComponentFactory, Constants } from "@encoway/react-configurator";

import { ComponentName, EventTypes } from "../constants";
import { LinkedTreeStyles } from "./LinkedTree.styles";
import { ILinkedTreeStyles, LinkedTreeProps } from "./LinkedTree.types";

registerIcons(
  {
    icons: {
      ChevronDown: <ChevronDownIcon />,
      ChevronUp: <ChevronUpIcon />,
    },
  },
  { disableWarnings: true },
);
type ResizeProps = {
  target: { current: null | HTMLDivElement };
  className?: string;
};

const Resize = (props: ResizeProps) => {
  const isFirefox = /Firefox/.test(navigator.userAgent);
  if (!props.target.current) return null;
  const current = props.target.current;
  const axis = "x";
  const dir = -1;
  let pos = 0;

  const resize = (event: MouseEvent) => {
    const attribute = "width";
    const delta = dir * (pos - event[axis]);
    pos = event.x;
    const computedStyle = getComputedStyle(current, "");
    const size = parseInt(computedStyle[attribute]) + delta;

    const borderLeftWidth = parseInt(computedStyle.borderLeftWidth) | 0;
    const borderRightWidth = parseInt(computedStyle.borderRightWidth) | 0;

    const scrollbarWidth =
      current.offsetWidth -
      current.clientWidth -
      borderLeftWidth -
      borderRightWidth;

    if (isFirefox) {
      current.style[attribute] = `${size}px`;
    } else {
      current.style[attribute] = `${size + scrollbarWidth}px`;
    }
    window.dispatchEvent(new Event("resize"));
  };

  return (
    <div
      className={classnames("linkedTreeResizeWrapper", props.className)}
      onMouseDown={(event) => {
        pos = event.clientX;
        document.addEventListener("mousemove", resize, false);
        document.addEventListener(
          "mouseup",
          () => document.removeEventListener("mousemove", resize, false),
          false,
        );
      }}
    />
  );
};

const toNavGroup = (
  container: ContainerTO,
  expanded: (id: string) => boolean,
  onGoto: any,
): INavLink => {
  const key = getContainerKey(container);
  return {
    url: "",
    key,
    name: container.translatedName,
    isExpanded: expanded(key),
    container,
    onClick: () => onGoto(container),
    links:
      container.children?.map((c) => toNavGroup(c, expanded, onGoto)) || [],
  };
};

const renderLink: IRenderFunction<INavLink> = (link, _renderLink) => {
  const notReadyCount = ComponentFactory.instanceOf(
    ComponentName.NotReadyCount,
    { data: link?.container },
  );
  return (
    <Stack horizontal className={"linkedTreeLinkStack"}>
      {_renderLink?.(link)}
      {notReadyCount}
    </Stack>
  );
};

const getContainerKey = (container: ContainerTO) => {
  let key = container.id;
  if (container.properties?.containerId) {
    key = container.properties?.containerId + "@" + key;
  }
  return key;
};

/**
 * The LinkedTree renders the confilink in an interactive tree structure.
 * Each node shows the number of parameters that are mandatory but not yet set, using the NotReadyCount component.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/LinkedTree/LinkedTree.tsx)
 * - [LinkedTreeStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/LinkedTree/LinkedTree.styles.ts)
 * - [LinkedTreeProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/LinkedTree/LinkedTree.types.ts)
 * - [MS Fluent Nav](https://developer.microsoft.com/de-DE/fluentui#/controls/web/nav)
 *
 * @visibleName LinkedTree
 */
const ILinkedTree = (props: LinkedTreeProps) => {
  const { styles, theme, onGoto, options } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<ILinkedTreeStyles>;
  const [tree, setTree] = useState<Tree>();
  const [openState, setOpenState] = useState<Record<string, boolean>>({});

  const resizeRef = useRef<HTMLDivElement | null>(null);
  const navRef = useRef<any>(null);
  const eventBus = options?.eventBus as Bus<any>;

  const groups: INavLinkGroup[] = [];

  const traverseGroups = (
    group: INavLink,
    openState: Record<string, boolean>,
  ) => {
    if (!group) {
      return;
    }
    if (group.key) {
      openState[group.key] =
        group.isExpanded === undefined ? false : group.isExpanded;
    }
    if (group.links === undefined) return;
    for (const child of group.links) {
      traverseGroups(child, openState);
    }
  };

  const preserveOpenState = () => {
    if (groups) {
      const openState = {};
      traverseGroups(navRef.current.props.groups[0], openState);
      setOpenState(openState);
    }
  };

  // This is a workaround because MS Fluent Nav does not allow to hook on the expanded/collapsed button click
  useEffect(() => {
    if (navRef.current) {
      preserveOpenState();
      const buttons = document.querySelectorAll(
        `.${classNames.root} .ms-Nav .ms-Nav-chevronButton`,
      );

      const handler = () => setTimeout(preserveOpenState, 100);

      buttons.forEach((button) => {
        button.addEventListener("click", handler);
      });
      return () => {
        buttons.forEach((button) => {
          button.removeEventListener("click", handler);
        });
      };
    }
  }, [props]);

  const containsSelectedContainer = (
    container: ContainerTO,
    selectedKey: string,
  ): boolean => {
    if (getContainerKey(container) === selectedKey) {
      return true;
    }

    return (
      container.children?.some((child) =>
        containsSelectedContainer(child, selectedKey),
      ) || false
    );
  };

  const updateTree = () => {
    props.config
      .linkedTree()
      .then((newTree) => {
        eventBus?.push({
          type: EventTypes.LinkedTreeLoaded,
          tree: newTree,
        });
        setTree(newTree);
      })
      .catch((e) => console.error("Failure while updating tree", e));
  };

  useEffect(() => {
    if (eventBus) {
      const unregister = eventBus
        ?.filter(
          (e) =>
            e.event === Constants.Events.InitialState ||
            e.event === Constants.Events.UpdateState,
        )
        .onValue(updateTree);
      return () => unregister();
    }
  }, []);

  useEffect(() => {
    if (!options?.eventBus) {
      updateTree();
    }
  }, [props]);

  useEffect(() => {
    if (tree && navRef.current) {
      if (!navRef.current.state.selectedKey) {
        navRef.current.state.selectedKey = getContainerKey(tree.rootContainer);
      } else if (
        !containsSelectedContainer(
          tree.rootContainer,
          navRef.current.state.selectedKey,
        )
      ) {
        onGoto(tree.rootContainer);
      }
    }
  }, [tree, navRef.current]);

  if (!tree || tree.rootContainer.children?.length === 0) {
    return null;
  }
  groups.push({
    links: [
      toNavGroup(
        tree.rootContainer,
        (id: string) => (openState[id] === undefined ? true : openState[id]),
        onGoto,
      ),
    ],
  });

  let resize: JSX.Element | null = (
    <Resize
      target={resizeRef}
      className={classnames("linkedTreeResizeBar", classNames.resizeBar)}
    />
  );
  if (props.resize === false) {
    resize = null;
  }

  return (
    <div className={classnames("linkedTreeWrapper", classNames.root)}>
      <div
        ref={resizeRef}
        className={classnames(
          "linkedTreeResizeContainer",
          classNames.resizeContainer,
        )}
      >
        <Nav
          componentRef={navRef}
          className={classnames("linkedTreeNav", classNames.nav)}
          groups={groups}
          onRenderLink={renderLink}
        />
      </div>
      {resize}
    </div>
  );
};

export const LinkedTree = styled(ILinkedTree, LinkedTreeStyles);
LinkedTree.displayName = "LinkedTree";
