import { ParameterTO } from "@encoway/c-services-js-client";

interface ConfRadioProps {
  data: ParameterTO;

  onValueChanged(data: ParameterTO, value: string): Promise<void>;
}

export function ConfDisplayTitle({ data }: ConfRadioProps) {
  return <>{data.translatedName || data.name}</>;
}
