import { mergeStyleSets } from "@fluentui/react";
import { Checkbox as FluentCheckbox } from "@fluentui/react/lib/Checkbox";
import classnames from "classnames";
import { pathOr, replace } from "ramda";
import React, { useState, useEffect } from "react";

import {
  Constants as RestApiConstants,
  selectedRawValue,
  Value,
} from "@encoway/c-services-js-client";

import { CHECKBOX_POSITIONS, getCheckboxSide } from "./ConfCheckboxUtils";
import { CheckboxProps } from "./standard/Checkbox/Checkbox.types";
import {
  determineBinaryOneValue,
  determineBinaryZeroValue,
} from "./standard/helperFunctions";

const checkboxStyle = mergeStyleSets({
  label: {
    gap: "4px",
  },
  checkbox: {
    margin: 0,
  },
});

const CHECKBOX_STYLE_PATH = ["data", "viewPortProperties", "C_CHECKBOX_STYLE"];

export function ConfCheckbox(props: CheckboxProps) {
  const isEditable = props.data.editable && !props.options?.readOnly;
  const values: Value[] = props.data.values || [];
  const falseValue = determineBinaryZeroValue(values);
  const trueValue = determineBinaryOneValue(values, falseValue);
  const value = selectedRawValue(props.data, falseValue?.value);
  const [localChecked, setLocalChecked] = useState(value === trueValue?.value);

  useEffect(() => {
    setLocalChecked(value === trueValue?.value);
  }, [value, trueValue]);

  const checkboxClass = replace(
    /[^a-z]/gm,
    "",
    pathOr("", CHECKBOX_STYLE_PATH, props),
  );

  const onValueChanged = () => {
    const newCheckedState = !localChecked;
    setLocalChecked(newCheckedState);

    props.onValueChanged(
      props.data,
      newCheckedState ? trueValue?.value : falseValue?.value,
      RestApiConstants.ValueFormat.Unformatted,
    );
  };

  return (
    <FluentCheckbox
      className={classnames("checkboxRootFluent", checkboxClass)}
      styles={checkboxStyle}
      disabled={!isEditable}
      label={
        getCheckboxSide(props.data) !== CHECKBOX_POSITIONS.STANDARD
          ? props.data.translatedName
          : undefined
      }
      boxSide={
        getCheckboxSide(props.data) === CHECKBOX_POSITIONS.START
          ? CHECKBOX_POSITIONS.START
          : CHECKBOX_POSITIONS.END
      }
      checked={localChecked}
      onChange={onValueChanged}
    />
  );
}
