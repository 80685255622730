import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { ITabsFooterStyles } from "./TabsFooter.types";

export const TabsFooterStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<ITabsFooterStyles>
> = (theme: IPartialTheme) => ({
  root: {},
  footer: {
    marginTop: "2em",
    marginBottom: "1em",
  },
  footerLeft: {
    flexGrow: 1,
  },
  footerRight: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row-reverse",
    selectors: {
      ".ms-Button-flexContainer": {
        flexDirection: "row-reverse",
      },
    },
  },
  footerLink: {
    maxHeight: "2.2em",
    padding: "0.5em",
    whiteSpace: "nowrap",
    color: theme.palette?.themePrimary,
    span: {
      color: "inherit",
      selectors: {
        ":hover": {
          color: "inherit",
        },
      },
    },
  },
});
