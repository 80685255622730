import { mergeStyleSets, Text } from "@fluentui/react";
import { any, equals, path, toLower } from "ramda";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { Loading } from "../../components/Loading";
import { ConfigurationContext } from "../../context/useConfiguration";
import { useProductContext } from "../../context/useProduct";
import { SETTINGS } from "../../settings";
import { Category } from "../../types/category";
import { DeepCategories } from "./DeepCategories";

const useStyles = () => {
  return mergeStyleSets({
    catalog: {
      width: "60vw",
      margin: "0 auto",
      padding: "20px",
    },
  });
};

export function Catalog() {
  const { session } = useContext(ConfigurationContext);
  const { getCategory, categories, products } = useProductContext();
  const catalog = path<Category>(["_CATALOGUE"], categories);
  const { t, i18n } = useTranslation();
  const isDevSystem = any(equals(toLower(SETTINGS.system)), [
    "local",
    "cpq4",
    "test",
  ]);
  const styles = useStyles();

  useEffect(() => {
    if (!catalog && isDevSystem) {
      getCategory("_CATALOGUE").then();
    }
  }, [catalog, isDevSystem, i18n.language]);

  if (isDevSystem) {
    if (path(["_CATALOGUE"], categories) && products) {
      return (
        <div className={styles.catalog}>
          <DeepCategories
            categories={catalog?.categories}
            products={products}
          />
        </div>
      );
    }
    return <Loading />;
  }

  if (session) {
    return <Navigate to={`/configuration/${session.article}/${session.id}`} />;
  }

  return <Text>{t("t:error_message")}</Text>;
}
