import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { useMemo } from "react";

import { countNotReadyChildren } from "../countNotReadyChildren";
import { NotReadyCountStyles } from "./NotReadyCount.styles";
import {
  INotReadyCountStyles,
  NotReadyCountProps,
} from "./NotReadyCount.types";

/**
 * The NotReadyCount component renders an icon which indicates the number of ParameterTOs in a ContainerTO that has the state `NOT_READY`.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/NotReadyCount/NotReadyCount.tsx)
 * - [NotReadyCountStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/NotReadyCount/NotReadyCount.styles.ts)
 * - [NotReadyCountProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/NotReadyCount/NotReadyCount.types.ts)
 *
 * @visibleName NotReadyCount
 */
const INotReadyCount = (props: NotReadyCountProps) => {
  const { styles, theme, data } = props;
  const count = useMemo(() => {
    return countNotReadyChildren(data);
  }, [data]);

  const defaultRender = (notReadyCount?: number) => {
    if (notReadyCount === 0) {
      return null;
    }
    const classNames = classNamesFunction()(
      styles,
      theme,
    ) as IProcessedStyleSet<INotReadyCountStyles>;
    if (notReadyCount && notReadyCount < 10) {
      return (
        <div className={classnames("notReadyCountRoot", classNames.root)}>
          <div
            className={classnames(
              "notReadyCountContainer",
              mergeStyles(classNames.numberContainer, classNames.circle),
            )}
          >
            <div
              className={classnames("notReadyCountNumber", classNames.number)}
            >
              {notReadyCount}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classnames("notReadyCountRoot", classNames.root)}>
        <div
          className={classnames(
            "notReadyCountLeftHalfCircle",
            classNames.leftHalfCircle,
          )}
        />
        <div
          className={classnames(
            mergeStyles("notReadyCountContainer", classNames.numberContainer),
          )}
        >
          <div className={classnames("notReadyCountNumber", classNames.number)}>
            {notReadyCount}
          </div>
        </div>
        <div
          className={classnames(
            "notReadyCountRightHalfCircle",
            classNames.rightHalfCircle,
          )}
        />
      </div>
    );
  };
  return (
    (props.onRenderCountValue &&
      props.onRenderCountValue(count, defaultRender)) ||
    defaultRender(count)
  );
};

export const NotReadyCount = styled(INotReadyCount, NotReadyCountStyles);
NotReadyCount.displayName = "NotReadyCount";
