import {
  ChevronLeftIcon,
  ChevronRightIcon,
  GlobalNavButtonIcon,
} from "@fluentui/react-icons-mdl2";
import { Icon } from "@fluentui/react/lib/Icon";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, registerIcons } from "@fluentui/react/lib/Styling";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { TabButtonProps } from "../TabButton/TabButton.types";
import { ComponentName } from "../constants";
import { containsVisibleParameter } from "../helperFunctions";
import { TabsStyles } from "./Tabs.styles";
import { ITabsStyles, TabsProps } from "./Tabs.types";

const CHEVRON_LEFT = "ChevronLeft";
const CHEVRON_RIGHT = "ChevronRight";
const NAV_BUTTON = "GlobalNavButton";

registerIcons(
  {
    icons: {
      [CHEVRON_LEFT]: <ChevronLeftIcon />,
      [CHEVRON_RIGHT]: <ChevronRightIcon />,
      [NAV_BUTTON]: <GlobalNavButtonIcon />,
    },
  },
  { disableWarnings: true },
);

const tabId = (container: ContainerTO) => {
  return `encoway-cui-tab-${container.id}`;
};

/**
 * The Tabs component renders a Tab component for each of its ContainerTOs (children) and an interface to navigate between the Tab components.
 *
 * @visibleName Tabs
 */
const ITabs = (props: TabsProps) => {
  const tabsGap = "2";
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [selectedTabID, setSelectedTabID] = useState<string | null>(null);
  const [existingTabs, setExistingTabs] = useState(props.data.children);
  const [visibleTabs, setVisibleTabs] = useState(props.data.children);

  const tabsStackRef = useRef<HTMLDivElement>(
    null,
  ) as React.MutableRefObject<HTMLDivElement>;
  const contentRef = useRef<HTMLDivElement>(
    null,
  ) as React.MutableRefObject<HTMLDivElement>;

  const { styles, theme, onGetTabData, onRenderContent, ...delegatedProps } =
    props;
  const classNames: IProcessedStyleSet<ITabsStyles> = classNamesFunction()(
    styles,
    theme,
  );
  useEffect(() => {
    const updatedTabs =
      onGetTabData && onGetTabData(props.data, (dat) => dat.children);
    setExistingTabs(updatedTabs || props.data.children);
  }, [props.data.children, onGetTabData]);

  const firstVisibleTab = () => existingTabs.find(containsVisibleParameter);

  useEffect(() => {
    if (existingTabs.length !== 0) {
      const isSelectedTabContentVisible = existingTabs.some(
        (c) => c.id === selectedTabID && containsVisibleParameter(c),
      );
      if (!isSelectedTabContentVisible) {
        const firstTab = firstVisibleTab();
        firstTab && selectAndScrollTab(firstTab);
      }
      setVisibleTabs(determineVisibleTabs());
    }
  }, [existingTabs]);

  const selectTab = (container: ContainerTO) => {
    setSelectedTabID(container.id);
  };

  const selectAndScrollTab = (container: ContainerTO) => {
    selectTab(container);
    scrollToTab(container);
  };

  const isTabVisible = (container: ContainerTO) => {
    const tab = document.getElementById(tabId(container)) as HTMLElement;
    const tabsStack = tabsStackRef.current as HTMLDivElement;
    const tabsStackRect = tabsStack.getBoundingClientRect();
    const tabRect = tab.getBoundingClientRect();

    return (
      tabRect.x + tabRect.width < tabsStackRect.x + tabsStackRect.width &&
      tabRect.x > tabsStackRect.x
    );
  };

  const determineVisibleTabs = () =>
    props.data.children.filter((c) => containsVisibleParameter(c));

  const scrollToTab = (container: ContainerTO) => {
    const firstTab = firstVisibleTab();
    if (firstTab) {
      const firstTabOffset = document.getElementById(tabId(firstTab))
        ?.offsetLeft as number;
      const tab = document.getElementById(tabId(container)) as HTMLElement;
      const tabsStack = tabsStackRef.current;
      tabsStack.scrollLeft = tab.offsetLeft - firstTabOffset;
    }
  };

  const remToPx = (rem: number) => {
    return (
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  };

  const scrollRight = () => {
    const tabsStack = tabsStackRef.current;
    tabsStack.scrollLeft += remToPx(6);
  };

  const scrollLeft = () => {
    const tabsStack = tabsStackRef.current;
    tabsStack.scrollLeft -= remToPx(6);
  };

  const getNextIndex = (direction: number) => {
    let nextIndex =
      existingTabs.findIndex((c) => c.id === selectedTabID) + direction;
    while (nextIndex >= 0 && nextIndex < existingTabs.length) {
      if (containsVisibleParameter(existingTabs[nextIndex])) {
        return nextIndex;
      }
      nextIndex += direction;
    }
    return false;
  };

  const selectNextTab = (direction: number) => {
    const nextIndex = getNextIndex(direction);
    if (nextIndex !== false) {
      const container = existingTabs[nextIndex];
      selectAndScrollTab(container);
    }
  };

  const changeTab = (offset: number) => {
    selectNextTab(offset);
    scrollToTop();
  };

  const isFirstVisibleTab = () => {
    const nextIndex = visibleTabs.findIndex((c) => c.id === selectedTabID) - 1;
    return nextIndex < 0;
  };

  const isLastVisibleTab = () => {
    const nextIndex = visibleTabs.findIndex((c) => c.id === selectedTabID) + 1;
    return nextIndex >= visibleTabs.length;
  };

  const toggleCallout = () => {
    setCalloutVisible(!calloutVisible);
  };

  const ScrollButton = (props: TabButtonProps) =>
    ComponentFactory.instanceOf(ComponentName.TabButton, props);

  const tabs = existingTabs.map((childContainer: ContainerTO) =>
    ComponentFactory.instanceOf(ComponentName.Tab, {
      key: childContainer.id,
      data: childContainer,
      selected: selectedTabID === childContainer.id,
      onClick: (container: ContainerTO) => {
        if (!isTabVisible(childContainer)) {
          scrollToTab(container);
        }
        selectTab(container);
        scrollToTop();
      },
    }),
  );

  const tabListButton = ComponentFactory.instanceOf(ComponentName.TabButton, {
    onClick: toggleCallout,
    id: `encoway-cui-configuration-tabs-button-${props.data.id}`,
    children: [
      <Stack
        horizontal
        className={"configurationTabsButtonStack"}
        tokens={{ childrenGap: "0.5em" }}
        key={`tablist-${props.data.id}`}
      >
        <Icon
          iconName={NAV_BUTTON}
          className={"configurationTabsButtonStackIcon"}
          styles={{
            root: {
              span: {
                verticalAlign: "inherit",
                paddingTop: "0.1rem",
              },
            },
          }}
        />
        {ComponentFactory.instanceOf(ComponentName.NotReadyCount, {
          ...delegatedProps,
          data: props.data,
        })}
      </Stack>,
    ],
  });

  const defaultRenderContent = (props?: TabsProps) =>
    ComponentFactory.instanceOf(ComponentName.Section, {
      hideLabel: true,
      ...props,
    });

  const renderContent = onRenderContent
    ? (props: TabsProps) => onRenderContent(props, defaultRenderContent)
    : defaultRenderContent;

  const tabsFooter = ComponentFactory.instanceOf(ComponentName.TabsFooter, {
    ...delegatedProps,
    data: props.data,
    changeTab,
    isFirstTab: isFirstVisibleTab(),
    isLastTab: isLastVisibleTab(),
  });

  const callout = ComponentFactory.instanceOf(ComponentName.TabsList, {
    data: props.data,
    selectedTabID,
    onClick: (c: ContainerTO) => {
      toggleCallout();
      selectAndScrollTab(c);
      scrollToTop();
    },
    onDismiss: toggleCallout,
  });

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  return (
    <div className={classnames("tabsBarWrapper", classNames.root)}>
      <Stack
        horizontal
        className={classnames("tabsBar", classNames.tabsBar)}
        tokens={{ childrenGap: tabsGap }}
      >
        <ScrollButton iconName={CHEVRON_LEFT} onClick={scrollLeft} />
        <div
          ref={tabsStackRef}
          id={`tabs-stack-${props.data.id}`}
          className={classnames("tabsBarStack", classNames.tabsStack)}
        >
          {tabs}
        </div>
        <Stack
          className={"tabsBarListStack"}
          horizontal
          tokens={{ childrenGap: tabsGap }}
        >
          <ScrollButton iconName={CHEVRON_RIGHT} onClick={scrollRight} />
          {tabListButton}
        </Stack>
      </Stack>

      {selectedTabID && (
        <div
          ref={contentRef}
          className={classnames("tabsBarContent", classNames.content)}
        >
          {renderContent({
            ...delegatedProps,
            data: existingTabs.find(
              (c) => c.id === selectedTabID,
            ) as ContainerTO,
          })}
          {tabsFooter}
        </div>
      )}

      {calloutVisible && callout}
    </div>
  );
};

/**
 * The Tabs component renders a Tab component for each of its ContainerTOs (children) and is an interface to navigate between the Tab components.
 *
 * @visibleName Tabs
 */
export const Tabs = styled(ITabs, TabsStyles);
Tabs.displayName = "Tabs";
