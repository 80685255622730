import { mergeStyleSets, Text } from "@fluentui/react";
import classNames from "classnames";
import { equals, map, toLower, values } from "ramda";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../context/useApp";

const languageMockStyled = mergeStyleSets({
  root: {
    cursor: "pointer",
    selectors: {
      ":not(:last-child)": {
        padding: ".2em",
      },
      ":hover": {
        fontWeight: 600,
      },
      "&.selected": {
        fontWeight: 600,
      },
    },
  },
});

export function LanguageMock() {
  const { i18n } = useTranslation();
  const { languages } = useContext(AppContext);
  const { root } = languageMockStyled;
  function onSelectionChanged(lang: string) {
    return function (): void {
      i18n.changeLanguage(lang).then();
    };
  }

  return (
    <>
      {map(
        (option) => (
          <Text
            variant="small"
            key={option.tag}
            onClick={onSelectionChanged(option.tag)}
            className={classNames(root, {
              selected: equals(toLower(i18n.language), toLower(option.tag)),
            })}
          >
            {option.tag}
          </Text>
        ),
        values(languages[i18n.language]),
      )}
    </>
  );
}
