import { mergeStyleSets, Stack } from "@fluentui/react";
import classNames from "classnames";
import { not } from "ramda";
import { useContext } from "react";

import { ParameterTO } from "@encoway/c-services-js-client";
import { Constants } from "@encoway/react-configurator";

import { SideBarContext } from "../../../context/useSidebar";
import { useTooltip } from "../../../hooks/useTooltip";
import { ConfiguratorComponentProps } from "../../../types/configuration";
import { isCharacteristicLabelHidden } from "./ConfParameterUtils";

function parameterStyles() {
  return mergeStyleSets({
    root: {
      alignSelf: "center",
      flex: 0.2,
      justifyContent: "space-between",
      alignItems: "center",
    },
    undo: {
      display: "none",
      fontSize: "6px",
      cursor: "pointer",
      paddingRight: "15px",
    },
  });
}

export function ConfParameter(props: ConfiguratorComponentProps<ParameterTO>) {
  const { children, data, onValueChanged } = props;
  const paramStyled = parameterStyles();
  const { image, text, heading } = useTooltip(data);
  const { configurationInvalid, setInfo, releaseTooltip } =
    useContext(SideBarContext);

  function onHover() {
    setInfo({
      configurationInvalid,
      infos: [text],
      image,
      text: heading,
    });
  }
  function onMouseLeave() {
    releaseTooltip();
  }

  return (
    <Stack
      className={classNames("customParameter configurator-grid grid-md-1", {
        "grid-2": not(isCharacteristicLabelHidden(data)),
      })}
    >
      {not(isCharacteristicLabelHidden(data)) && (
        <Stack
          className={classNames(paramStyled.root, "customParameterTitle")}
          horizontal
          onMouseEnter={onHover}
          onMouseLeave={onMouseLeave}
        >
          <Stack.Item className="customParameterName">
            {data.translatedName ?? data.name}
          </Stack.Item>
          <Stack.Item
            className={classNames(paramStyled.undo, "customParameterAction")}
            onClick={() => onValueChanged(data, Constants.Undo)}
          >
            Undo
          </Stack.Item>
        </Stack>
      )}
      <div className="customParameterChild" style={{ flex: 1 }}>
        {children}
      </div>
    </Stack>
  );
}
