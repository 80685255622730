import { mergeStyleSets } from "@fluentui/react";
import { filter, isEmpty, keys, map } from "ramda";
import React from "react";

import { Categories, Category } from "../../types/category";
import { Products } from "../../types/product";
import { CategoryGroup } from "./CategoryGroup";
import { Product } from "./Product";

const useStyles = () => {
  return mergeStyleSets({
    productGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
      gap: "4px",
      marginBottom: "40px",
    },
  });
};

interface DeepCategoriesProps {
  categories: Categories | undefined;
  products: Products;
  parentName?: string;
}

export function DeepCategories({
  categories,
  products,
  parentName = "",
}: DeepCategoriesProps) {
  const styles = useStyles();

  const hasContent = (category: Category): boolean => {
    return (
      !isEmpty(category.products) ||
      (category.categories &&
        keys(category.categories).some((subCatKey) =>
          hasContent(category.categories[subCatKey]),
        ))
    );
  };

  const renderCategory = (catKey: string, category: Category) => {
    const hasProducts = !isEmpty(category.products);
    const fullCategoryName = parentName
      ? `${parentName} > ${category.name}`
      : category.name;

    return (
      <React.Fragment key={catKey}>
        {hasProducts && (
          <CategoryGroup name={fullCategoryName}>
            <div className={styles.productGrid}>
              {map(
                (productKey) => (
                  <Product key={productKey} product={products[productKey]} />
                ),
                category.products,
              )}
            </div>
          </CategoryGroup>
        )}
        {!isEmpty(category.categories) && (
          <DeepCategories
            categories={category.categories}
            products={products}
            parentName={hasProducts ? "" : fullCategoryName}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      {categories &&
        products &&
        filter(
          (catKey) => hasContent(categories[catKey]),
          keys(categories),
        ).map((catKey) =>
          renderCategory(catKey.toString(), categories[catKey]),
        )}
    </>
  );
}
