import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IImageButtonStyles } from "./ImageButton.types";

export const ImageButtonStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IImageButtonStyles>
> = (theme: IPartialTheme) => ({
  root: {},
  option: {
    cursor: "pointer",
    backgroundColor: "transparent",
    border: 0,
    selectors: {
      ":focus-visible": {
        outline: `1px solid ${theme.semanticColors?.focusBorder}`,
        outlineOffset: "0.25rem",
      },
    },
  },
  upperCaption: {
    textAlign: "left",
    margin: "0.5rem 0 0.5rem 0",
  },
  lowerCaption: {
    textAlign: "left",
  },
  imageContainer: {
    border: "1px solid",
    borderColor: theme.palette?.neutralTertiary,
    selectors: {
      ":hover": {
        borderColor: theme.palette?.neutralSecondary,
      },
    },
  },
  imageContainerSelected: {
    border: "2px solid",
    borderColor: theme.palette?.themePrimary,
    selectors: {
      ":hover": {
        borderColor: theme.palette?.themeDark,
      },
    },
  },
  imageContainerNotSelected: {
    selectors: {
      ".ms-Image": {
        img: {
          opacity: 0.5,
        },
      },
    },
  },
  imageContainerNotReady: {
    borderColor: theme.semanticColors?.warningIcon,
    selectors: {
      ":hover": {
        borderColor: theme.semanticColors?.warningIcon,
      },
    },
  },
  imageContainerConflict: {
    ".ms-Image": {
      img: {
        opacity: 0.5,
      },
    },
  },
  imageContainerDisabled: {
    border: "none",
    backgroundColor: theme.semanticColors?.disabledBackground,
    selectors: {
      ":hover": {
        borderColor: theme.semanticColors?.disabledBackground,
      },
      ".ms-Image": {
        img: {
          opacity: 0.5,
        },
      },
    },
  },
  imageContainerDisabledSelected: {
    border: "2px solid",
    borderColor: theme.palette?.neutralTertiary,
    selectors: {
      ":hover": {
        borderColor: theme.palette?.neutralTertiary,
      },
    },
  },
  captionText: {},
  captionTextSelected: {
    fontWeight: "bold",
  },
  captionTextNotSelected: {
    color: theme.semanticColors?.disabledText,
  },
  captionTextDisabled: {
    color: theme.semanticColors?.disabledText,
  },
  captionTextSelectedDisabled: {
    fontWeight: "bold",
  },
  price: {},
  priceNotSelected: {
    selectors: {
      span: {
        color: theme.palette?.neutralSecondary,
      },
    },
  },
  priceDisabled: {},
});
