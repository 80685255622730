import {
  any,
  equals,
  filter,
  isEmpty,
  isNil,
  pathOr,
  reduce,
  replace,
  sort,
} from "ramda";

import { ContainerTO, ParameterTO, Value } from "@encoway/c-services-js-client";

import { SETTINGS } from "../../settings";
import { Products } from "../../types/product";

const TRUE_NUMBER_VALUE = 1;
const pathToBooleanPropertyValue = (
  articleName: string,
  propertyId: string,
) => [articleName, "characteristicValues", propertyId, "values", 0, "value"];

export const INITIAL_CUSTOM_VIEW_PROPERTIES = {
  hideCharacteristicImage: false,
  hideCharacteristicKey: false,
  hideCharacteristicLabel: false,
  hideCharacteristicText: false,
};

export function deepFindAnyParameter(containers: ContainerTO[]): boolean {
  return any((c) => {
    if (!isEmpty(c.parameters)) {
      return true;
    }
    if (!isEmpty(c.children)) {
      return deepFindAnyParameter(c.children);
    }
    return false;
  }, containers);
}

export function findDeepParameter(
  containerTOs: ContainerTO[],
  searchProp: keyof ParameterTO,
  searchValues: string[],
): ParameterTO[] {
  return reduce(
    (acc: ParameterTO[], containerTO: ContainerTO) => {
      const param = filter(
        (parameterTO) => any(equals(parameterTO[searchProp]), searchValues),
        containerTO.parameters,
      );
      if (isEmpty(param)) {
        return [
          ...acc,
          ...findDeepParameter(containerTO.children, searchProp, searchValues),
        ];
      }
      return acc;
    },
    [],
    containerTOs,
  );
}

export function findDeepContainer(
  containerTOs: ContainerTO[],
  searchProp: keyof ContainerTO,
  searchValues: string[],
): ContainerTO[] {
  return reduce(
    (acc: ContainerTO[], containerTO: ContainerTO) => {
      if (any(equals(containerTO[searchProp]), searchValues)) {
        return [...acc, containerTO];
      }
      return [
        ...acc,
        ...findDeepContainer(containerTO.children, searchProp, searchValues),
      ];
    },
    [],
    containerTOs,
  );
}

export function sortValuesBy(
  values: Value[],
  sortProp: keyof Value,
  sortType: "asc" | "desc" = "asc",
) {
  return {
    asc: () =>
      sort((a, b) => Number(a[sortProp]) - Number(b[sortProp]), values),
    desc: () =>
      sort((a, b) => Number(b[sortProp]) - Number(a[sortProp]), values),
  }[sortType]();
}

export function placeholderMinMax({ minValue, maxValue }: ParameterTO): string {
  if (minValue && maxValue) {
    if (equals(minValue, "-infDouble") || equals(maxValue, "infDouble")) {
      return "";
    }
    if (equals(minValue, maxValue)) {
      return minValue;
    }
  }
  return `${minValue} ... ${maxValue}`;
}

export const equals1 = equals("1");

export function getCustomViewportProperty(
  data: ParameterTO,
  propertyId: string,
) {
  const viewPortProperties = data.viewPortProperties as {
    [key: string]: string;
  };
  return replace(/^{"([^"]+)"}$/, "$1", viewPortProperties[propertyId] || "");
}

export function getBooleanModelProperty(
  propertyId: string,
  articleName: string | undefined,
  products: Products,
) {
  if (isNil(articleName)) {
    return false;
  }

  return (
    TRUE_NUMBER_VALUE ===
    pathOr<number>(
      0,
      pathToBooleanPropertyValue(articleName, propertyId),
      products,
    )
  );
}

export function getCustomViewportProperties(data: ParameterTO) {
  return {
    hideCharacteristicImage: equals1(
      getCustomViewportProperty(
        data,
        SETTINGS.configuration.viewportProperties.hideCharacteristicImage,
      ),
    ),
    hideCharacteristicKey: equals1(
      getCustomViewportProperty(
        data,
        SETTINGS.configuration.viewportProperties.hideCharacteristicKey,
      ),
    ),
    hideCharacteristicLabel: equals1(
      getCustomViewportProperty(
        data,
        SETTINGS.configuration.viewportProperties.hideCharacteristicLabel,
      ),
    ),
    hideCharacteristicText: equals1(
      getCustomViewportProperty(
        data,
        SETTINGS.configuration.viewportProperties.hideCharacteristicText,
      ),
    ),
  };
}
