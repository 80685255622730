/**
 * Test, if container contains any visible parameter (either itself or within an (indirect) child).
 * @param {*} container
 */
import { mergeStyles } from "@fluentui/react";
import { ILayerProps } from "@fluentui/react/lib/Layer";

import {
  ContainerTO,
  ParameterTO,
  Value,
  Unit,
} from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { layerStyle } from "./common.styles";
import { CHECKBOX_FALSE_VALUES, ComponentName } from "./constants";

export function containsVisibleParameter(container: ContainerTO) {
  let visible = container.numberOfVisibleParameters > 0;
  if (!visible) {
    const pending = [];
    pending.push(...container.children);
    while (!visible && pending.length > 0) {
      const current = pending.shift();
      if (current) {
        if (current.numberOfVisibleParameters > 0) {
          visible = true;
        } else {
          pending.push(...current.children);
        }
      }
    }
  }
  return visible;
}

const hasHost = (layerProps?: ILayerProps) => {
  return layerProps?.hostId || false;
};

export const determineLayerClass = (
  layerProps?: ILayerProps,
  className?: string,
) => {
  return hasHost(layerProps)
    ? mergeStyles(layerStyle, className)
    : className || "";
};

export const determineLayerStyle = (
  layerProps?: ILayerProps,
  style?: Record<string, unknown>,
) => {
  return hasHost(layerProps)
    ? { ...(layerStyle as Record<string, unknown>), ...(style || {}) }
    : style || {};
};

/**
 * Determines the false value from a set of values, using CHECKBOX_FALSE_VALUES constant.
 * @param {Value[]} values
 */
export const determineBinaryZeroValue = (values: Value[]) => {
  const falseValues = values.filter(
    (v) => CHECKBOX_FALSE_VALUES.indexOf(v.value) !== -1,
  );
  return falseValues.length === 1 ? falseValues[0] : undefined;
};

/**
 * Determines the true value from a set of values, looking for the first value it finds which is not the falseValue.
 * @param {Value[]} values
 * @param {any} falseValue
 */
export const determineBinaryOneValue = (
  values: Value[],
  falseValue?: Value,
) => {
  const fValue = falseValue?.value;
  const match = values.filter((v) => v.value !== fValue);
  return match.length === 0 ? undefined : match[0];
};

export const isBinary: (p: ParameterTO) => boolean = (p: ParameterTO) => {
  return test(p.values || []) || requiresCheckboxComponent(p.viewPort || "");
};

const requiresCheckboxComponent: (viewport: string) => boolean = (viewport) => {
  return (
    viewport === ComponentName.Checkbox ||
    ComponentFactory.aliases[viewport] === ComponentName.Checkbox
  );
};

const test: (values: Value[]) => boolean = (values) => {
  if (values.length && values.length < 3) {
    const binaryZeroValue = determineBinaryZeroValue(values);
    const binaryOneValue = determineBinaryOneValue(values, binaryZeroValue);
    if (binaryOneValue) {
      return binaryZeroValue !== undefined || values.length === 1;
    }
  }
  return false;
};

export const getDisplayValue = (translatedValue?: string, unit?: Unit) => {
  return translatedValue && `${translatedValue}${getUnitDisplayValue(unit)}`;
};
export const getUnitDisplayValue = (unit?: Unit) => {
  return unit?.translatedUnit ? ` ${unit.translatedUnit}` : "";
};
