import React from "react";

import { Portal } from "../../components/Portal";
import { SETTINGS } from "../../settings";
import { LanguageMock } from "./LanguageMock";

export function Mocks() {
  return (
    <>
      <Portal id="encoway-mock-inject-header">
        <LanguageMock />
      </Portal>
      <Portal id="encoway-mock-system">
        <li style={{ textTransform: "uppercase" }}>{SETTINGS.system}system</li>
      </Portal>
    </>
  );
}
