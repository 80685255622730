import { useEffect, useMemo, useState } from "react";

import { encryptDecrypt } from "./browserStorageUtils";

/**
 * Options for customizing the behavior of the useBrowserStorage hook.
 * @interface
 * @template T - The type of data being stored in browser storage.
 * @property {T} [defaultValue] - The default value to be used if no value is found in browser storage.
 * @property {string} [encryptionKey] - A key to use for encrypting and decrypting the data stored in browser storage.
 * @property {boolean} [disableExpiration] - A flag to disable the expiration timer for the data stored in browser storage.
 * @property {boolean} [localStorage] - A flag to use local storage instead of session storage.
 */
interface UseSessionStorageOptions<T> {
  defaultValue?: T;
  encryptionKey?: string;
  disableExpiration?: boolean;
  localStorage?: boolean;
}

interface UseSessionStorageReturnType<T> {
  session: T | undefined;

  /**
   * Updates the data stored in browser storage for the current session.
   * @async
   * @function
   * @param {T} newData - The new data to be stored in browser storage.
   * @returns {Promise<void>} A promise that resolves when the data has been updated in browser storage.
   */
  setSession(newData: T): void;

  /**
   *
   * Refreshes the expiration timer for the current session in browser storage.
   * @function
   * @returns {void}
   */
  refreshSession(): void;

  /**
   * Clears all data stored in browser storage for the current session.
   * @function
   * @returns {void}
   */
  clearSession(): void;
}

const DEFAULT_SESSION_EXPIRATION_TIME = 300000; // 5 minutes in milliseconds

/**
 * A custom hook that provides a stateful value stored in the session storage, and allows to manage it.
 *
 * @template T - The data type to be stored in session storage.
 *
 * @param {string} key - The key to be used for the session storage.
 * @param {number} expirationTime - The expiration time of the session data in milliseconds. Defaults to 5 minutes (300000 ms).
 * @param {T} defaultValue - The default value to be returned when no data is stored in the session storage.
 * @param {UseSessionStorageOptions} options - Optional configurations for the session storage.
 *
 * @returns {UseSessionStorageReturnType<T>} An object containing the current session data, functions to update, refresh and clear the session data.
 *
 * @throws {Error} If the key parameter is empty or undefined.
 */
export function useBrowserStorage<T>(
  key: string,
  expirationTime: number = DEFAULT_SESSION_EXPIRATION_TIME,
  options: UseSessionStorageOptions<T> = {},
): UseSessionStorageReturnType<T> {
  const [data, setData] = useState<T | undefined>(undefined);

  const storage = options.localStorage
    ? window.localStorage
    : window.sessionStorage;

  if (!key) {
    throw new Error("useSessionStorage: key cannot be empty or undefined");
  }

  /**
   * Async function that gets the data stored in browser storage for the current session.
   * @async
   * @function
   * @returns {Promise<T | undefined>} A promise that resolves with the stored data or undefined if no data is stored in browser storage.
   */
  async function getData(): Promise<T | undefined> {
    checkTimer();
    let storedData = storage.getItem(key);
    if (options.encryptionKey && storedData) {
      storedData = await encryptDecrypt(storedData, {
        key: options.encryptionKey,
        operation: "decrypt",
      });
    }
    return storedData ? JSON.parse(storedData) : options.defaultValue;
  }

  /**
   * Function that refreshes the expiration timer for the current session in browser storage.
   * @function
   * @returns {void}
   */
  function refreshTimer(): void {
    if (!options.disableExpiration) {
      storage.setItem(`${key}__timer`, `${Date.now() + expirationTime}`);
    }
  }

  /**
   * Function that removes all data stored in browser storage for the current session.
   * @function
   * @returns {void}
   */
  function removeSession(): void {
    storage.removeItem(key);
    storage.removeItem(`${key}__timer`);
  }

  /**
   * Async function that updates the data stored in browser storage for the current session.
   * @async
   * @function
   * @param {T} newData - The new data to be stored in browser storage.
   * @returns {Promise<void>} A promise that resolves when the data has been updated in browser storage.
   */
  async function updateData(newData: T): Promise<void> {
    const updatedData = newData ?? options.defaultValue;
    const serializedData = JSON.stringify(updatedData);
    const encryptedData = options.encryptionKey
      ? await encryptDecrypt(serializedData, {
          key: options.encryptionKey,
          operation: "encrypt",
        })
      : serializedData;
    storage.setItem(key, encryptedData);
    setData(updatedData);
    refreshTimer();
  }

  /**
   * Function that checks the expiration timer for the current session in browser storage and removes the data if it has expired.
   * @function
   * @returns {void}
   */
  function checkTimer(): void {
    if (!options.disableExpiration) {
      const timer = storage.getItem(`${key}__timer`);
      const expired = Date.now() > Number(timer);
      if (expired) {
        removeSession();
      }
    }
  }

  /**
   * Function that clears all data stored in browser storage for the current session.
   * @function
   * @returns {void}
   */
  function clearSession(): void {
    if (options.localStorage) {
      window.localStorage.clear();
    } else {
      window.sessionStorage.clear();
    }
  }

  useEffect(() => {
    getData().then((newData) => setData(newData));
    const timerId = setInterval(checkTimer, expirationTime + 1);
    checkTimer();

    return function cleanup() {
      clearInterval(timerId);
    };
  }, [key]);

  return useMemo(
    (): UseSessionStorageReturnType<T> => ({
      session: data,
      setSession: updateData,
      refreshSession: refreshTimer,
      clearSession: removeSession,
    }),
    [data],
  );
}
