import { Bus } from "baconjs";
import { equals, map, reduce, reject, slice } from "ramda";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ConfigurationService,
  GuiTO,
  MediaValue,
  ParameterTO,
  StopConfigurationResponse,
} from "@encoway/c-services-js-client";
import { translations as Tconf } from "@encoway/cui-configurator-components";
import { L10n } from "@encoway/l10n";
import { Constants } from "@encoway/react-configurator";

import { useBrowserStorage } from "../hooks/useBrowserStorage";
import { getCustomConfigurationView } from "../service/configurationService";
import { AppInstance, SETTINGS } from "../settings";
import { Bom, ConfigurationSession } from "../types/configuration";
import {
  determineInvalidSoftConstraints,
  determineSoftConstraints,
  filterUnwantedViewports,
  isValidConfiguration,
  toBom,
  toSelectedParameters,
  toTerminal,
} from "./configurationUtils";

export interface CfgState {
  valid: boolean;
  articleName: string | undefined;
  cfg: ConfigurationService | undefined;
  guiTO: GuiTO | undefined;
  boms: {
    [lang: string]: {
      small: Bom[];
      big: Bom[];
    };
  };
}

export interface Configuration {
  cfg: ConfigurationService;
  guiTO: GuiTO;
}

interface ConfigurationActions {
  start(
    articleName: string,
    salesContext?: SalesContext,
  ): Promise<Configuration>;

  loadSession(articleName: string, id: string): Promise<Configuration>;

  selectedParameters(guiTO?: GuiTO): SelectedParameters;

  getBom(bom: string | string[], mapping?: string): Promise<Bom[] | Bom[][]>;

  stop(): Promise<StopConfigurationResponse>;
}

export interface ConfigurationStore {
  articleName: string | undefined;
  session: ConfigurationSession | undefined;
  guiTO: GuiTO | undefined;
  cfg: ConfigurationService | undefined;
  language: string;
  valid: boolean;
  softConstraints: ParameterTO[];
  filteredInvalidParameters: ParameterTO[];
  boms:
    | {
        small: Bom[];
        big: Bom[];
      }
    | undefined;
  actions: ConfigurationActions;
  eventBus: any;
  validation: ParameterTO[];
}

interface SalesContext {
  characteristics: { [key: string]: string | number | MediaValue };
}

const initialCfg: CfgState = {
  valid: false,
  articleName: undefined,
  guiTO: undefined,
  cfg: undefined,
  boms: {},
};

const initialStore: ConfigurationStore = {
  session: undefined,
  articleName: undefined,
  guiTO: undefined,
  cfg: undefined,
  valid: false,
  softConstraints: [],
  filteredInvalidParameters: [],
  language: slice(0, 2, SETTINGS.language),
  boms: {
    small: [],
    big: [],
  },
  validation: [],
  eventBus: undefined,
  actions: {
    loadSession: async function () {
      throw new Error("load not initialized");
    },
    start: async function () {
      throw new Error("start not initialized");
    },
    selectedParameters: function () {
      throw new Error("selectedParameters not initialized");
    },
    getBom: function () {
      throw new Error("loadSaved not initialized");
    },
    stop: function () {
      throw new Error("stop not initialized");
    },
  },
};

export interface SelectedParameters {
  [key: string]: string | number | undefined;
}

type SingleBom = Bom[];
type MultipleBoms = Bom[][];
type BomResult = SingleBom | MultipleBoms;

export const ConfigurationContext =
  createContext<ConfigurationStore>(initialStore);

L10n.source("Configuration", Tconf, true);
L10n.reloadResources("en").then();

function useConfiguration(): ConfigurationStore {
  const { i18n } = useTranslation();
  const browserStorage = useBrowserStorage<ConfigurationSession>(
    SETTINGS.session.key,
    SETTINGS.showroom.sessionTimeout * 1000,
    { encryptionKey: SETTINGS.session.encryption },
  );
  const eventBus = useMemo(() => new Bus(), []);
  const language = useMemo(
    () =>
      equals(i18n.language, "en-GB")
        ? i18n.language
        : slice(0, 2, i18n.language),
    [i18n.language],
  );
  const [{ cfg, guiTO, boms, articleName, valid }, setConfiguration] =
    useState<CfgState>(initialCfg);
  const softConstraints = useMemo(
    () => determineSoftConstraints(guiTO),
    [guiTO],
  );
  const invalidParameters = useMemo(
    () =>
      guiTO
        ? [
            ...reduce(toTerminal, [], [guiTO?.rootContainer]),
            ...determineInvalidSoftConstraints(guiTO, softConstraints),
          ]
        : [],
    [guiTO],
  );

  const filteredInvalidParameters = useMemo(
    () => reject(filterUnwantedViewports, invalidParameters),
    [invalidParameters],
  );

  async function getCustomData(
    configurationId: string,
  ): Promise<{ [lang: string]: { small: Bom[]; big: Bom[] } }> {
    /*
    const [fetchedBig, fetchedSmall] = await Promise.all([
      getCustomConfigurationView(configurationId, SETTINGS.boms.big, i18n.language),
      getCustomConfigurationView(configurationId, AppSettings.studio.boms.small, i18n.language),
    ]);
    const bigBom = reduce(toBom, [], [fetchedBig.data.rootContainer]);
    const smallBom = reduce(toBom, [], [fetchedSmall.data.rootContainer]);
    */
    return { [i18n.language]: { big: [], small: [] } };
  }

  async function setConfig(
    articleName: string,
    configuration: ConfigurationService,
  ): Promise<Configuration> {
    const [newGuiTO, newBoms] = await Promise.all([
      configuration.ui(language),
      getCustomData(configuration.id()),
    ]);
    const config = {
      articleName,
      guiTO: newGuiTO as GuiTO,
      cfg: configuration,
      boms: newBoms,
      valid: isValidConfiguration(newGuiTO as GuiTO, softConstraints),
    };
    browserStorage.setSession({ id: configuration.id(), article: articleName });
    setConfiguration(config);
    return config;
  }

  async function start(
    articleName: string,
    salesContext?: SalesContext,
  ): Promise<Configuration> {
    const configuration = await ConfigurationService.create(
      AppInstance.http,
      SETTINGS.showroom.url,
      {
        articleName: articleName,
        ...(salesContext && { salesContext }),
      },
      i18n.language,
    );
    configuration.settings({
      mappingOptions: {
        mappingProfile: "MAXIMUM_CONTENT_MAPPING",
        pricingMappingType: "NONE",
      },
    });
    return setConfig(articleName, configuration);
  }

  async function loadSession(
    articleName: string,
    confId: string,
  ): Promise<Configuration> {
    const configuration = await ConfigurationService.create(
      AppInstance.http,
      SETTINGS.showroom.url,
      { configurationId: confId },
      i18n.language,
    );
    configuration.settings({
      mappingOptions: {
        mappingProfile: "MAXIMUM_CONTENT_MAPPING",
        pricingMappingType: "NONE",
      },
    });
    return setConfig(articleName, configuration);
  }

  function selectedParameters(_guiTO?: GuiTO): SelectedParameters {
    const newGuiTO = _guiTO || guiTO;
    if (newGuiTO && newGuiTO.rootContainer.children[0]) {
      return reduce(
        toSelectedParameters,
        {},
        newGuiTO.rootContainer.children[0].parameters,
      );
    }
    throw new Error("Could not get selected parameters. GuiTO is undefined");
  }

  async function getBom(
    bom: string | string[],
    mapping: string = "MAXIMUM_CONTENT_MAPPING",
  ): Promise<BomResult> {
    if (typeof bom === "string") {
      const fetchedBom = await getCustomConfigurationView(
        AppInstance.axios,
        cfg!.id(),
        SETTINGS.studio.boms.big,
        i18n.language,
        mapping,
      );
      return reduce(toBom, [], [fetchedBom.data.rootContainer]);
    } else {
      const cfgId = cfg!.id();
      const fetchedBoms = await Promise.all(
        map(
          (bomToFetch) =>
            getCustomConfigurationView(
              AppInstance.axios,
              cfgId,
              bomToFetch,
              i18n.language,
              mapping,
            ),
          bom,
        ),
      );
      return map(
        (fetchedBom) => reduce(toBom, [], [fetchedBom.data.rootContainer]),
        fetchedBoms,
      );
    }
  }

  function stop(): Promise<StopConfigurationResponse> {
    if (cfg) {
      browserStorage.clearSession();
      return cfg.stop();
    }
    throw new Error(
      "cfg is not defined, trying to stop the configuration isn't possible in configurator context",
    );
  }

  useEffect(() => {
    return eventBus.onValue(async (e: any) => {
      if (equals(e.event, Constants.Events.UpdateState)) {
        const _guiTO = e.rawState as GuiTO;
        if (cfg) {
          const boms = await getCustomData(cfg.id());
          setConfiguration((prev) => ({
            ...prev,
            cfg: prev.cfg,
            valid: isValidConfiguration(
              _guiTO,
              determineSoftConstraints(_guiTO),
            ),
            guiTO: _guiTO,
            boms,
          }));
        } else {
          throw new Error(
            "cfg is not defined, trying to change language not possible in configurator context",
          );
        }
      }
    });
  }, [eventBus, cfg]);

  useEffect(() => {
    if (cfg) {
      // Subs even if configurator isn't rendered
      const id = setInterval(
        () => cfg.status().then(() => browserStorage.refreshSession()),
        (SETTINGS.showroom.sessionTimeout * 1000) / 2,
      );
      return () => clearInterval(id);
    }
  }, [cfg, guiTO]);

  return useMemo(
    (): ConfigurationStore => ({
      session: browserStorage.session,
      articleName,
      cfg,
      guiTO,
      eventBus,
      language,
      valid,
      softConstraints,
      filteredInvalidParameters,
      validation: invalidParameters,
      boms: boms[i18n.language],
      actions: {
        start,
        stop,
        loadSession,
        selectedParameters,
        getBom,
      },
    }),
    [browserStorage.session, articleName, cfg, eventBus, guiTO, boms, language],
  );
}

export function ConfigurationProvider({ children }: { children: ReactNode }) {
  return (
    <ConfigurationContext.Provider value={useConfiguration()}>
      {children}
    </ConfigurationContext.Provider>
  );
}
