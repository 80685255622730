import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { INotReadyCountStyles } from "./NotReadyCount.types";

export const NotReadyCountStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<INotReadyCountStyles>
> = (theme: any) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
  },
  numberContainer: {
    height: "1em",
    minHeight: "1em",
    backgroundColor:
      theme.semanticColors?.notReadyCount || theme.semanticColors?.warningIcon,
    display: "flex",
    color: theme.palette.neutralPrimaryAlt,
  },
  number: {
    margin: "auto",
    fontSize: "0.8em",
    lineHeight: "1em",
    fontWeight: "normal",
  },
  circle: {
    width: "1em",
    minWidth: "1em",
    borderRadius: "50%",
  },
  leftHalfCircle: {
    height: "1em",
    width: "0.5em",
    borderRadius: "1em 0em 0em 1em",
    backgroundColor:
      theme.semanticColors?.notReadyCount || theme.semanticColors?.warningIcon,
    display: "block",
  },
  rightHalfCircle: {
    height: "1em",
    width: "0.5em",
    borderRadius: "0em 1em 1em 0em",
    backgroundColor:
      theme.semanticColors?.notReadyCount || theme.semanticColors?.warningIcon,
    display: "block",
  },
});
