import {
  any,
  equals,
  filter,
  find,
  gt,
  keys,
  map,
  path,
  prop,
  propEq,
  reduce,
} from "ramda";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { ParameterTO } from "@encoway/c-services-js-client";

import { toParameter } from "../context/configurationUtils";
import { ConfigurationContext } from "../context/useConfiguration";
import { useProductContext } from "../context/useProduct";
import { ExtendedCharacteristic } from "../types/product";

export type DenyEpriStore = {
  refAffectedParameters: ParameterTO[];
  affectedEpriCharacteristics: EpriCharacteristic[];
};

export type EpriCharacteristic = {
  name: string;
  parameter: ParameterTO | undefined;
  disableEpri: boolean;
  disableEpriRef: ParameterTO | undefined;
};

function determineEpriRef(
  ref: string | undefined,
  parameters: ParameterTO[],
): ParameterTO | undefined {
  if (ref) {
    const refParameter = find(
      (parameter) => equals(parameter.name, ref),
      parameters,
    );
    if (refParameter) {
      return refParameter;
    }
    throw new Error(
      `Ref ${ref} could not be found in product characteristics. Please set a correct reference to proceed.`,
    );
  }
  return undefined;
}

export function useDenyEpri(affectedParameters: ParameterTO[]): DenyEpriStore {
  const { products } = useProductContext();
  const { guiTO } = useContext(ConfigurationContext);
  const { article } = useParams();

  const allParameter = useMemo(
    () => (guiTO ? reduce(toParameter, [], [guiTO.rootContainer]) : []),
    [guiTO],
  );

  const epriCharacteristics = useMemo(
    function (): EpriCharacteristic[] {
      if (article && products[article] && gt(allParameter.length, 0)) {
        const product = products[article];
        return reduce(
          (
            acc: EpriCharacteristic[],
            charKey: keyof ExtendedCharacteristic,
          ) => {
            const epriValue = path<string>(
              [
                charKey,
                "characteristicValues",
                "C_DISABLE_EPRI",
                "values",
                0,
                "value",
              ],
              product.characteristicValues,
            );
            const refValue = path<string>(
              [
                charKey,
                "characteristicValues",
                "C_DISABLE_EPRI_REF",
                "values",
                0,
                "value",
              ],
              product.characteristicValues,
            );
            if (epriValue || refValue) {
              acc = [
                ...acc,
                {
                  name: charKey.toString(),
                  parameter: find(
                    (parameter) => equals(parameter.name, charKey),
                    affectedParameters,
                  ),
                  disableEpri: Boolean(epriValue),
                  disableEpriRef: determineEpriRef(refValue, allParameter),
                },
              ];
            }
            return acc;
          },
          [],
          keys(product.characteristicValues),
        );
      }
      throw new Error("Can not determine product object or articleName");
    },
    [products, allParameter, article],
  );

  const affectedEpriCharacteristics = useMemo(
    function (): EpriCharacteristic[] {
      const affectedParameterIds = map(prop("name"), affectedParameters);
      return filter(
        (epriCharacteristic) =>
          any(equals(epriCharacteristic.name), affectedParameterIds),
        epriCharacteristics,
      );
    },
    [epriCharacteristics, affectedParameters],
  );

  const refAffectedParameters = useMemo(
    function (): ParameterTO[] {
      return reduce(
        function (acc: ParameterTO[], ele: ParameterTO): ParameterTO[] {
          const affectedRef = find(
            propEq("name", ele.name),
            affectedEpriCharacteristics,
          );
          if (affectedRef?.disableEpriRef) {
            return [...acc, affectedRef.disableEpriRef];
          }
          return [...acc, ele];
        },
        [],
        affectedParameters,
      );
    },
    [affectedEpriCharacteristics],
  );

  return useMemo(() => {
    return {
      affectedEpriCharacteristics,
      refAffectedParameters,
    };
  }, [affectedEpriCharacteristics, refAffectedParameters]);
}
