import { ThemeProvider } from "@fluentui/react";
import { ReactNode } from "react";

import { AppProvider } from "./context/useApp";
import { ConfigurationProvider } from "./context/useConfiguration";
import { ProductProvider } from "./context/useProduct";
import { SideBarProvider } from "./context/useSidebar";
import { theme } from "./theme";

export function Provider({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <ProductProvider>
        <AppProvider>
          <ConfigurationProvider>
            <SideBarProvider>{children}</SideBarProvider>
          </ConfigurationProvider>
        </AppProvider>
      </ProductProvider>
    </ThemeProvider>
  );
}
