import { path, pathOr, reduce, values } from "ramda";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ParameterTO, PossibleValue } from "@encoway/c-services-js-client";

import { useProductContext } from "../context/useProduct";
import { InfoState } from "../context/useSidebar";

export type Tooltips = {
  [value: string]: {
    heading: string | undefined;
    text: string;
    image: string | undefined;
  };
};

type ToolTipState = {
  heading: string | undefined;
  text: string;
  image: string | undefined;
  tooltips: Tooltips;
};

export type TooltipStore = {
  getTooltip(value: string): InfoState | undefined;
} & ToolTipState;

function toToolTip(acc: Tooltips, possibleValue: PossibleValue): Tooltips {
  const tooltip = path<string>(
    ["characteristicValues", "C_TOOLTIP", "values", 0],
    possibleValue,
  );
  const image = path<string>(
    ["characteristicValues", "C_TOOLTIP_IMAGE", "values", 0, "uri"],
    possibleValue,
  );
  const heading = pathOr<string>(
    "",
    ["characteristicValues", "C_TOOLTIP_HEADING", "values", 0],
    possibleValue,
  );
  return {
    ...acc,
    [possibleValue.id]: { text: tooltip || "", image, heading },
  };
}

export function useTooltip(parameterTO: ParameterTO): TooltipStore {
  const { article } = useParams();
  const { products } = useProductContext();
  const [tooltips, setTooltips] = useState<ToolTipState>({
    tooltips: {},
    image: undefined,
    text: "",
    heading: "",
  });

  useEffect(() => {
    (async () => {
      if (!article) {
        throw new Error(
          "Can not find article from params for tooltip evaluation",
        );
      }
      const characteristic = path<{ [key: string]: PossibleValue }>(
        [article, "characteristicValues", parameterTO.name, "possibleValues"],
        products,
      );
      if (characteristic) {
        const tooltips = reduce(toToolTip, {}, values(characteristic));
        setTooltips({
          tooltips,
          heading: pathOr<string>(
            "",
            [
              article,
              "characteristicValues",
              parameterTO.name,
              "characteristicValues",
              "C_TOOLTIP_HEADING",
              "values",
              0,
            ],
            products,
          ),
          text: pathOr<string>(
            "",
            [
              article,
              "characteristicValues",
              parameterTO.name,
              "characteristicValues",
              "C_TOOLTIP",
              "values",
              0,
            ],
            products,
          ),
          image: path<string>(
            [
              article,
              "characteristicValues",
              parameterTO.name,
              "characteristicValues",
              "C_TOOLTIP_IMAGE",
              "values",
              0,
              "uri",
            ],
            products,
          ),
        });
      }
    })();
  }, [parameterTO]);

  function getTooltip(value: string): InfoState | undefined {
    const tooltip = tooltips.tooltips[value];
    if (tooltip) {
      return {
        infos: [tooltip.text || ""],
        image: tooltip.image,
        text: tooltip.heading,
      };
    }
  }

  return { ...tooltips, getTooltip };
}
