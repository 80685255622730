import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import { Bus } from "baconjs";
import classnames from "classnames";
import React from "react";

import { L10n } from "@encoway/l10n";
import { ComponentFactory, Constants } from "@encoway/react-configurator";

import { ComponentName } from "../constants";
import { determineLayerClass } from "../helperFunctions";
import { MessageStyles } from "./Message.styles";
import { IMessageStyles, MessageProps } from "./Message.types";

/**
 *
 * The Message component renders a dialog for errors during configuration.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Message/Message.tsx)
 * - [MessageStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Message/Message.styles.ts)
 * - [MessageProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Message/Message.types.ts)
 * - [MS Fluent Dialog](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dialog)
 *
 * @visibleName Message
 */
export const IMessage = (props: MessageProps) => {
  const { styles, theme } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IMessageStyles>;

  const dialogContentProps = {
    type: DialogType.normal,
    title: L10n.format("Configuration.Root.Message.title"),
    closeButtonAriaLabel: "Close",
  };

  if (props.error) {
    console.error("Error during react-configurator rendering:", props.error);
  }
  const eventBus = props.options.eventBus as Bus<any>;
  return (
    <>
      <Dialog
        className={classnames("messageDialogRoot", classNames.root)}
        hidden={false}
        dialogContentProps={dialogContentProps}
        modalProps={{
          className: classnames(
            "messageDialog",
            mergeStyles(
              classNames.modal,
              determineLayerClass(
                props.modalProps?.layerProps,
                classNames.layer,
              ),
            ),
          ),
          ...props.modalProps,
        }}
      >
        {L10n.format("Configuration.Root.Message.subtitle")}
        <DialogFooter
          className={classnames("messageDialogFooter", classNames.footer)}
        >
          <PrimaryButton
            onClick={() => {
              props.onClick && props.onClick();
              eventBus.push(Constants.Events.reloadState);
            }}
            text={L10n.format("Configuration.Root.Message.button")}
            className={classnames("messageDialogButton", classNames.button)}
          />
        </DialogFooter>
      </Dialog>
      {props.data && ComponentFactory.instanceOf(ComponentName.Root, props)}
    </>
  );
};

export const Message = styled(IMessage, MessageStyles);
Message.displayName = "Message";
