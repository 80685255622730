import React from "react";

import { ComponentFactory } from "@encoway/react-configurator";

import { ComponentName } from "../constants";
import { RootProps } from "./Root.types";

/**
 * The Root component is rendered as root for a CUI configurator. The default implementation just renders the Tabs component.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Root/Root.tsx)
 * - [RootProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Root/Root.types.ts)
 *
 * @visibleName Root
 */
export const Root = (props: RootProps) => {
  // If the root component has parameters, we cannot use the tabs component (which only supports child containers) but display a single section instead.
  // This is the case when no root structure node was specified for the view in CPQ Studio
  if (props.data?.parameters?.length > 0) {
    return (
      <div
        className={"rootDiv"}
        style={{
          flex: 1,
          padding: "0em 2em",
          overflow: "auto",
        }}
      >
        {ComponentFactory.instanceOf(ComponentName.Section, props)}
      </div>
    );
  }
  return <>{ComponentFactory.instanceOf(ComponentName.Tabs, props)}</>;
};
