import { mergeStyleSets, PrimaryButton } from "@fluentui/react";
import classNames from "classnames";
import { equals, not } from "ramda";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ConfigurationContext } from "../../context/useConfiguration";
import { useProductContext } from "../../context/useProduct";
import { SideBarContext } from "../../context/useSidebar";
import { SETTINGS } from "../../settings";
import { getBooleanModelProperty } from "./configuratorUtils";

type ConfFinishProps = {
  article: string;
};

const finishStyle = mergeStyleSets({
  save: {
    display: "flex",
    justifyContent: "flex-end",
    gap: ".5em",
  },
  button: {
    selectors: {
      "&.disabled, &.disabled:hover": {
        backgroundColor: "#918f8f",
        borderColor: "#918f8f",
        cursor: "auto",
      },
    },
  },
});

export function ConfFinish({ article }: Readonly<ConfFinishProps>) {
  const { valid } = useContext(ConfigurationContext);
  const { releaseTooltip } = useContext(SideBarContext);
  const { products } = useProductContext();
  const { session, action } = useParams();
  const isEditMode = equals(action, "edit");
  const { t } = useTranslation();
  const { button, save } = finishStyle;

  function onClickFinish(next: boolean = false) {
    return function () {
      if (not(valid)) {
        releaseTooltip(true);
      } else if (next) {
        finishConfiguration(session!, true);
      } else {
        finishConfiguration(session!);
      }
    };
  }

  const hideCompleteAndNextButton =
    isEditMode ||
    getBooleanModelProperty(
      SETTINGS.configuration.modelProperties.hideCompleteNextButton,
      article,
      products,
    );

  return (
    <div className={classNames("configurationFinish", save)}>
      {!hideCompleteAndNextButton && (
        <PrimaryButton
          className={classNames(
            "configurationFinishSaveNext",
            button,
            not(valid),
          )}
          onClick={onClickFinish(true)}
        >
          {t("t:save_configuration_next")}
        </PrimaryButton>
      )}
      <PrimaryButton
        className={classNames("configurationFinishSave", button, not(valid))}
        onClick={onClickFinish()}
      >
        {t(isEditMode ? "t:edit_configuration" : "t:save_configuration")}
      </PrimaryButton>
    </div>
  );
}
