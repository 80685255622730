import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";

import { determineLayerClass } from "../helperFunctions";
import { ErrorDialogStyles } from "./ErrorDialog.styles";
import { ErrorDialogProps, IErrorDialogStyles } from "./ErrorDialog.types";
import { useErrorDialog } from "./useErrorDialog";

/**
 * The ErrorDialog component renders a Dialog, containing an error message.
 *
 * ErrorDialogs are shown for conflicts, that could not be solved with an EPRI and in general for runtime errors.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/ErrorDialog/ErrorDialog.tsx)
 * - [ErrorDialogStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/ErrorDialog/ErrorDialog.styles.ts)
 * - [ErrorDialogProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/ErrorDialog/ErrorDialog.types.ts)
 * - [MS Fluent Dialog](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dialog)
 *
 * @visibleName ErrorDialog
 */
const IErrorDialog = (props: ErrorDialogProps) => {
  const { styles, theme, error, close, modalProps } = props;
  const classNames = classNamesFunction<{}, IErrorDialogStyles>()(
    styles,
    theme,
  );
  const { title, dialogText, buttonText } = useErrorDialog(error);

  if (!error) return null;

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        type: DialogType.normal,
        showCloseButton: false,
        title,
      }}
      onDismiss={close}
      modalProps={{
        className: classnames(
          "errorDialog",
          mergeStyles(
            classNames.dialog,
            classNames.modal,
            determineLayerClass(modalProps?.layerProps, classNames.layer),
          ),
        ),
        isBlocking: true,
        overlay: {
          isDarkThemed: false,
          className: classNames.overlay,
        },
        ...modalProps,
      }}
    >
      <Text className="errorDialogText">{dialogText}</Text>
      <DialogFooter
        className={classnames("errorDialogFooter", classNames.footer)}
      >
        <PrimaryButton
          onClick={close}
          text={buttonText}
          className={classnames(
            "errorDialogContinueButton",
            classNames.continueButton,
          )}
        />
      </DialogFooter>
    </Dialog>
  );
};

export const ErrorDialog = styled(IErrorDialog, ErrorDialogStyles);
ErrorDialog.displayName = "ErrorDialog";
