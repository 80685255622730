import { equals, not } from "ramda";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentName as ConfiguratorComponentName } from "@encoway/cui-configurator-components";

export const SECTION_WITHOUT_TITLE_NAME = "CB_VW_Section_NoTitle";

export function getTitle(data: ContainerTO) {
  return not(
    equals(ConfiguratorComponentName.DummyViewport, data.viewPort) ||
      equals(SECTION_WITHOUT_TITLE_NAME, data.name),
  )
    ? data.translatedName
    : undefined;
}
