import { MessageBar, MessageBarType } from "@fluentui/react";
import { equals } from "ramda";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../components/Loading";
import { ConfigurationContext } from "../../context/useConfiguration";
import { useProductContext } from "../../context/useProduct";
import { PROGRESS, useProgress } from "../../hooks/useProgress";
import { Configuration } from "./Configuration";

export function LoadConfigurator() {
  const { cfg, guiTO, actions } = useContext(ConfigurationContext);
  const { getProducts } = useProductContext();
  const { session, article, load, action } = useParams();
  const progress = useProgress();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        progress.set(PROGRESS.LOADING);
        let config = { cfg, guiTO };
        if (article) {
          await getProducts(article);
          if (!session) {
            config = await actions.start(article);
          } else {
            if (!cfg || !equals(session, cfg.id())) {
              config = await actions.loadSession(article, session);
            }
            progress.set(PROGRESS.LOADED);
          }
          const id = config.cfg!.id();
          if (action) {
            navigate(`/configuration/${article}/${id}/${action}`, {
              replace: true,
            });
          } else {
            navigate(`/configuration/${article}/${id}`, { replace: true });
          }
        }
      } catch (e: any) {
        console.error(e);
        progress.set(
          PROGRESS.ERROR,
          `${e.message} - Configuration could not be loaded.`,
        );
      }
    })();
  }, [article, session, load]);

  if (progress.error) {
    return (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
        onDismiss={() => navigate("/")}
        dismissButtonAriaLabel="back"
      >
        {progress.message}
      </MessageBar>
    );
  }

  if (cfg && progress.loaded) {
    return <Configuration />;
  }

  return <Loading />;
}
