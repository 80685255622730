import { mergeStyleSets } from "@fluentui/react";
import { DownIcon, UpIcon } from "@fluentui/react-icons-mdl2";
import classNames from "classnames";
import { pathOr } from "ramda";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";

import { Section } from "../../../components/Section";
import { useProductContext } from "../../../context/useProduct";
import { SideBarContext } from "../../../context/useSidebar";
import { SETTINGS } from "../../../settings";
import { mediaQueryWidth } from "../../../theme";
import { Visualization } from "../Visualization";
import { getBooleanModelProperty } from "../configuratorUtils";
import { SidebarInfo } from "./SidebarInfo";
import { SidebarSummary } from "./SidebarSummary";

const sideBarStyled = (collapsed: boolean) =>
  mergeStyleSets({
    root: {},
    wrapper: {
      padding: ".75em .25em",
    },
    iconWrapper: {
      display: "none",
      backgroundColor: "#FFF",
      zIndex: 0,
      [mediaQueryWidth.sidebarThreshold]: {
        bottom: "6vh",
        right: collapsed ? "480px" : "-6px",
        position: "fixed",
        padding: "2em .75em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 0px 9px 0px #CCC",
        borderRadius: "5px",
        "&:hover": {
          color: "rgb(21, 21, 21)",
          cursor: "pointer",
        },
      },
    },
    rightIcon: {
      transform: "rotate(90deg)",
    },
    leftIcon: {
      transform: "rotate(90deg)",
    },
    sectionWrapper: {
      [mediaQueryWidth.sidebarThreshold]: {
        zIndex: 2,
        position: "fixed",
        width: "473px",
        background: "#FFF",
        right: "1em",
      },
    },
    closeOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  });

function toggleSection(maxHeight: number) {
  return window.innerHeight >= maxHeight;
}

function SidebarWrapper({
  title,
  className,
  children,
  initialToggle,
}: {
  className: string;
  title: string;
  children: ReactNode;
  initialToggle?: boolean;
}) {
  const { t } = useTranslation();
  const { wrapper } = sideBarStyled(false);
  return (
    <Section
      title={t(title)}
      className={className}
      saveToggleName={`show${title}`}
      initialToggle={initialToggle}
    >
      <div className={classNames("sidebar", wrapper)}>{children}</div>
    </Section>
  );
}

const SIDEBAR_WIDTH_THRESHOLD = 1200;
const SIDEBAR_HEIGHT_THRESHOLDS = [820, 1080];

export function Sidebar({
  session,
  article,
  showSideBar,
  setShowSideBar,
}: {
  session: string;
  article: string;
  showSideBar: boolean;
  setShowSideBar: Dispatch<SetStateAction<boolean>>;
}) {
  const {
    root,
    iconWrapper,
    leftIcon,
    rightIcon,
    sectionWrapper,
    closeOverlay,
  } = sideBarStyled(showSideBar);
  const { infos, warning } = useContext(SideBarContext);
  const { products } = useProductContext();
  const hideVisualization = getBooleanModelProperty(
    SETTINGS.configuration.modelProperties.hideVisualization,
    article,
    products,
  );

  function toggleShowSideBar() {
    setShowSideBar(!showSideBar);
    localStorage.setItem("showSideBar", JSON.stringify(!showSideBar));
  }

  useLayoutEffect(() => {
    function onUpdateSize() {
      const showSideBarStored = JSON.parse(
        localStorage.getItem("showSideBar") ?? "false",
      );
      if (window.innerWidth <= SIDEBAR_WIDTH_THRESHOLD && !showSideBarStored) {
        setShowSideBar(false);
        return;
      }
      setShowSideBar(true);
    }

    window.addEventListener("resize", onUpdateSize);
    onUpdateSize();
    return () => window.removeEventListener("resize", onUpdateSize);
  }, []);

  useEffect(() => {
    if (warning) {
      setShowSideBar(true);
    }
  }, [infos, warning]);

  return (
    <>
      {showSideBar && window.innerWidth <= SIDEBAR_WIDTH_THRESHOLD && (
        <div className={closeOverlay} onClick={toggleShowSideBar} />
      )}
      <div className={iconWrapper} onClick={toggleShowSideBar}>
        {showSideBar ? (
          <UpIcon className={rightIcon} />
        ) : (
          <DownIcon className={leftIcon} />
        )}
      </div>
      <div className={sectionWrapper}>
        {(showSideBar || window.innerWidth > SIDEBAR_WIDTH_THRESHOLD) && (
          <div
            className={classNames("sidebarWrapper", root, {
              sidebarOpen: showSideBar,
            })}
          >
            {!hideVisualization && (
              <SidebarWrapper
                title={"t:visualization"}
                className="sidebarWrapperVisualization"
                initialToggle={toggleSection(
                  pathOr(820, [0], SIDEBAR_HEIGHT_THRESHOLDS),
                )}
              >
                <Visualization
                  articleName={article}
                  configurationId={session}
                />
              </SidebarWrapper>
            )}
            <SidebarWrapper title={"t:info"} className="sidebarWrapperInfo">
              <SidebarInfo />
            </SidebarWrapper>
            <SidebarWrapper
              title={"t:summary"}
              className="sidebarWrapperSummary"
              initialToggle={toggleSection(
                pathOr(1080, [1], SIDEBAR_HEIGHT_THRESHOLDS),
              )}
            >
              <SidebarSummary />
            </SidebarWrapper>
          </div>
        )}
      </div>
    </>
  );
}
