import { DefaultButton, mergeStyleSets, Text, useTheme } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ExtendedProduct } from "../../types/product";

const useStyles = () => {
  const theme = useTheme();
  return mergeStyleSets({
    productButton: {
      lineBreak: "anywhere",
      height: "100%",
      minHeight: "65px",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.neutralLight}`,
      borderRadius: theme.effects.roundedCorner2,
      transition: "all 0.2s ease",
      ":hover": {
        backgroundColor: theme.palette.neutralLight,
        borderColor: theme.palette.neutralLight,
      },
    },
    productName: {
      fontSize: theme.fonts.mediumPlus.fontSize,
      fontWeight: theme.fonts.mediumPlus.fontWeight,
      color: theme.palette.neutralPrimary,
    },
  });
};

export function Product({ product }: { product: ExtendedProduct }) {
  const navigate = useNavigate();
  const styles = useStyles();
  return (
    <DefaultButton
      className={styles.productButton}
      onClick={() => navigate(`configuration/${product.id}`)}
    >
      <Text className={styles.productName}>{product.name || product.id}</Text>
    </DefaultButton>
  );
}
