import { DefaultButton, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";

export function Login() {
  const [{ user, pw }, setBasicState] = useState<{
    user: string | undefined;
    pw: string | undefined;
  }>({ user: undefined, pw: undefined });

  useEffect(() => {
    const basic = localStorage.getItem("basic");
    if (basic) {
      setBasicState(JSON.parse(basic));
    }
  }, []);

  function onSubmit() {
    localStorage.setItem("basic", JSON.stringify({ user, pw }));
    window.location.href = window.location.origin;
  }

  return (
    <Stack gap={10}>
      <Stack>
        <Stack.Item>Username</Stack.Item>
        <Stack.Item>
          <input
            value={user}
            type="text"
            onChange={(e) => setBasicState({ user: e.target.value, pw })}
          />
        </Stack.Item>
      </Stack>
      <Stack>
        <Stack.Item>Password</Stack.Item>
        <Stack.Item>
          <input
            value={pw}
            type="password"
            onChange={(e) => setBasicState({ pw: e.target.value, user })}
          />
        </Stack.Item>
      </Stack>
      <Stack>
        <Stack.Item>
          <DefaultButton onClick={onSubmit}>Submit</DefaultButton>
        </Stack.Item>
      </Stack>
    </Stack>
  );
}
