import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { ITabsListStyles } from "./TabsList.types";

export const TabsListStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<ITabsListStyles>
> = (theme: IPartialTheme) => ({
  callout: {
    padding: "1em",
    boxShadow: "none",
    selectors: {
      ".ms-Callout-beak": {
        backgroundColor: theme.palette?.themeLighter,
      },
      ".ms-Callout-beakCurtain": {
        backgroundColor: theme.palette?.themeLighter,
      },
    },
  },
  stack: {
    backgroundColor: theme.palette?.themeLighter,
  },
  link: {
    cursor: "pointer",
    maxWidth: "12em",
    color: theme.palette?.neutralPrimary,
    selectors: {
      ":hover": {
        color: theme.palette?.themePrimary,
        textDecoration: "none",
      },
      ":active": {
        textDecoration: "none",
      },
      ":hover:active": {
        textDecoration: "none",
      },
    },
  },
  linkSelected: {
    fontWeight: 600,
  },
});
