import { Toggle } from "@fluentui/react";

import { useSwitch } from "./useSwitch";

import "./switch.css";

const styled = {
  pill: {
    background: "#cccccc",
    border: "#cccccc",
    "&:hover": {
      background: "#cccccc",
    },
  },
  thumb: {
    backgroundColor: "#FFF",
  },
};

export function Switch({
  pre = false,
  label,
  onChange,
  onHover,
}: {
  pre?: boolean;
  label: string;
  onChange: () => void;
  onHover: () => void;
}) {
  const { set } = useSwitch(onChange, pre);

  return (
    <div className="customSwitch" onMouseEnter={onHover}>
      <div className="customSwitchAction field" onClick={set}>
        <Toggle checked={pre} onChange={() => null} styles={styled} />
        <label className="customSwitchLabel" htmlFor={label} />
      </div>
    </div>
  );
}
