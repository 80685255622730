import Numeral, { NumeralJSLocale } from "numeral";
import React, { useCallback } from "react";

import { L10n } from "@encoway/l10n";

/**
 * Validates if a given string is a correctly formatted number.
 * If the range argument is provided it also checks whether the number is in the range.
 *
 * @param {string} input - The string that will be validated.
 * @param {string} fullLocale - Full locale to use for converting the argument input to a number
 * @param {number|string} minValue - Optional allowed minimal value to check the input against.
 * @param {number|string} maxValue - Optional allowed maximal value to check the input against.
 * @returns {number|NaN} The input as number if valid, NaN otherwise.
 *
 */
export const validateNumericInput: (
  input: string,
  fullLocale: string,
  minValue?: number | string,
  maxValue?: number | string,
) => number = (input, fullLocale, minValue, maxValue) => {
  const def: NumeralJSLocale = registerNumeralForLocale(fullLocale);

  const number = Numeral(input)?.value();

  if (
    number === null ||
    isNaN(number) ||
    !isValidInput(input, def.delimiters)
  ) {
    return NaN;
  }
  if (
    (minValue !== undefined && number < minValue) ||
    (maxValue !== undefined && number > maxValue)
  ) {
    return NaN;
  }
  return number;
};
interface IDelimiters {
  thousands: string;
  decimal: string;
}
const isValidInput = (input: string, delimiters: IDelimiters) => {
  const regExp = new RegExp(
    `^[+-]?(\\d{1,2}[${delimiters.thousands}])?((\\d{3}[${delimiters.thousands}]|\\d{4,}}))*?\\d+([${delimiters.decimal}]\\d+)?$`,
  );
  return regExp.test(input);
};

const registerNumeralForLocale = (fullLocale: string) => {
  const parts = new Intl.NumberFormat(fullLocale).formatToParts(12345.6);
  const grouping = parts.find((d) => d.type === "group")?.value;
  const decimal = parts.find((d) => d.type === "decimal")?.value;

  let locale = fullLocale.toLowerCase();
  if (locale === "de-de") {
    locale = `${locale}-cui`;
  }

  try {
    if (!Numeral.locales[locale]) {
      const registerArgs = {
        delimiters: {
          thousands: grouping,
          decimal,
        },
        abbreviations: {},
        currency: {},
      };
      Numeral.register("locale", locale, registerArgs as NumeralJSLocale);
    }
  } catch (e) {
    console.error(e);
  }
  Numeral.locale(locale);

  return Numeral.locales[locale];
};

type ValidateOptions = {
  updateError?: boolean;
  removeError?: boolean;
};

export const useValidation = (
  component: string,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  formattedMinValue?: string,
  formattedMaxValue?: string,
  minValue?: number | string,
  maxValue?: number | string,
) => {
  const validate = useCallback(
    (currentValue: string, options?: ValidateOptions) => {
      if (currentValue !== "") {
        const validatedNumber = validateNumericInput(
          currentValue,
          L10n.currentFullLocale(),
          minValue,
          maxValue,
        );
        if (
          options &&
          options.updateError &&
          isNaN(validatedNumber) &&
          currentValue !== formattedMaxValue &&
          currentValue !== formattedMinValue
        ) {
          if (
            (!maxValue || maxValue === "Infinity") &&
            (!minValue || minValue === "-Infinity")
          ) {
            setError(
              L10n.format(`Configuration.${component}.input.invalid`, {
                currentValue,
              }),
            );
          } else {
            setError(
              L10n.format(`Configuration.${component}.input.outofbounds`, {
                originalMinValue: formattedMinValue,
                originalMaxValue: formattedMaxValue,
              }),
            );
          }
        } else {
          if (options && options.removeError) {
            setError(undefined);
          }
        }
        return validatedNumber;
      }
      return NaN;
    },
    [
      component,
      minValue,
      maxValue,
      formattedMinValue,
      formattedMaxValue,
      setError,
      L10n,
    ],
  );
  return validate;
};
