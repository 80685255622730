import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { ITabStyles } from "./Tab.types";

export const TabStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<ITabStyles>
> = (theme: IPartialTheme) => ({
  tab: {
    border: "none",
    flexShrink: 0,
    borderRadius: "0em",
    height: "100%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    backgroundColor: theme.palette?.themeLighter,
    marginRight: 2,
    selectors: {
      ":hover": {
        backgroundColor: theme.palette?.themeLighter,
      },
      ":active": {},
      ":focus&::after": {
        outlineColor: theme.semanticColors?.focusBorder,
      },
    },
  },
  notReady: {
    backgroundColor: theme.semanticColors?.warningBackground,
    selectors: {
      ":hover": {
        backgroundColor: theme.semanticColors?.warningBackground,
      },
      ":active": {
        backgroundColor: theme.semanticColors?.warningBackground,
      },
    },
  },
  selected: {
    backgroundColor: theme.palette?.white,
    fontWeight: 600,
    selectors: {
      ":hover": {
        backgroundColor: theme.palette?.white,
      },
      ":active": {
        backgroundColor: theme.palette?.white,
      },
    },
  },
  text: {
    maxWidth: "11em",
    fontWeight: "inherit",
    display: "block", // otherwise maxWidth does not work
  },
});
