import { groupBy, last, mapObjIndexed, prop, propEq } from "ramda";

import { ParameterTO } from "@encoway/c-services-js-client";

export function isNumberInput(parameter: ParameterTO): boolean {
  return !!(parameter.minValue || parameter.maxValue);
}

function transformParts(numberFormatParts: Intl.NumberFormatPart[]) {
  const groupedParts = groupBy(prop("type"), numberFormatParts);
  return mapObjIndexed((groupedPart) => last(groupedPart)?.value, groupedParts);
}

export function generateNumberProps(locale: string) {
  const numberFormatParts = new Intl.NumberFormat(locale).formatToParts(
    11111.1,
  );
  const { group = ",", decimal = "." } = transformParts(numberFormatParts);
  return {
    thousandSeparator: group,
    decimalSeparator: decimal,
  };
}

export const equalsEnter = propEq("key", "Enter");
