import { useState } from "react";

export function useSwitch(_onChange: (checked: boolean) => void, pre: boolean) {
  const [checked, toggleChecked] = useState<boolean>(pre);

  function set(): void {
    _onChange(!checked);
    toggleChecked(!checked);
  }

  return { checked, set };
}
