import { ITheme, mergeStyleSets, Stack, useTheme } from "@fluentui/react";
import { DownIcon, UpIcon } from "@fluentui/react-icons-mdl2";
import classNames from "classnames";
import { equals, not, isNil } from "ramda";
import { ReactNode, useState } from "react";

import { getLocalStorageItem } from "../hooks/browserStorageUtils";

type SectionProps = {
  title?: string;
  showArrow?: boolean;
  children: ReactNode;
  className?: string;
  saveToggleName?: string;
  initialToggle?: boolean;
};

function titleStyles(theme: ITheme) {
  return mergeStyleSets({
    root: {
      userSelect: "none",
      padding: "10px 2px",
      borderBottom: "1px solid #e6e6e6",
      borderTop: "1px solid #e6e6e6",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      overflow: "hidden",
      "&:hover": {
        cursor: "pointer",
      },
    },
    titleText: {
      fontSize: "1.1em",
      fontWeight: 600,
    },
    titleIcon: {
      margin: "2px 0",
    },
  });
}

function Title({ title, className }: { title?: string; className?: string }) {
  const titleStyled = titleStyles(useTheme());
  if (title) {
    return (
      <div className={classNames(className, titleStyled.titleText)}>
        {title}
      </div>
    );
  }
  return <div className={classNames(className, titleStyled.titleText)} />;
}

function ArrowIcon({ isOpen, show }: { isOpen: boolean; show: boolean }) {
  const titleStyled = titleStyles(useTheme());

  if (not(show)) {
    return null;
  }

  return equals(isOpen, true) ? (
    <DownIcon
      className={classNames(titleStyled.titleIcon, "customSectionArrowDown")}
    />
  ) : (
    <UpIcon
      className={classNames(titleStyled.titleIcon, "customSectionArrowUp")}
    />
  );
}

function getInitialIsOpen(
  initialToggle: boolean,
  saveToggleName: string | undefined,
) {
  if (isNil(saveToggleName)) {
    return initialToggle;
  }
  return getLocalStorageItem(saveToggleName, initialToggle);
}

export function Section({
  title,
  showArrow = true,
  children,
  className,
  saveToggleName,
  initialToggle = true,
}: SectionProps) {
  const styles = titleStyles(useTheme());
  const [isOpen, setIsOpen] = useState<boolean>(
    getInitialIsOpen(initialToggle, saveToggleName),
  );

  function toggleIsOpen() {
    if (not(showArrow)) return;
    setIsOpen(!isOpen);
    if (isNil(saveToggleName)) return;
    localStorage.setItem(saveToggleName, JSON.stringify(!isOpen));
  }

  return (
    <Stack className={classNames("customSection", className)}>
      {title && (
        <Stack
          onClick={toggleIsOpen}
          className={classNames(styles.root, "customSectionTitleWrapper")}
        >
          <Title title={title} className="customSectionTitle" />
          <ArrowIcon isOpen={isOpen} show={showArrow} />
        </Stack>
      )}
      {isOpen && children}
    </Stack>
  );
}
