import { useContext } from "react";
import { useParams } from "react-router-dom";

import { ParameterTO } from "@encoway/c-services-js-client";

import { ConfigurationContext } from "../../../context/useConfiguration";
import { useProductContext } from "../../../context/useProduct";
import { useSoftConstraint } from "../../../hooks/useSoftContraint";
import { ConfiguratorComponentProps } from "../../../types/configuration";

export function ConfSoftConstraint(
  props: ConfiguratorComponentProps<ParameterTO>,
) {
  const { guiTO, softConstraints } = useContext(ConfigurationContext);
  const { article } = useParams();
  const { products } = useProductContext();
  const softConstraintText = useSoftConstraint(
    products[article!],
    guiTO,
    softConstraints,
    props.data,
  );

  if (softConstraintText) {
    return <div className="confSoftConstraint">{softConstraintText}</div>;
  }
  return null;
}
