import { FocusZone } from "@fluentui/react/lib/FocusZone";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { List } from "@fluentui/react/lib/List";
import { Stack } from "@fluentui/react/lib/Stack";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { useCallback, useRef } from "react";

import {
  Constants as RestApiConstants,
  selectedRawValue,
  specState,
} from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import {
  ComponentName,
  READY_STATE_NOT_READY,
  SPEC_STATE_SET_BY_SYSTEM,
} from "../constants";
import { getDisplayValue } from "../helperFunctions";
import { ImageButtonStyles } from "./ImageButton.styles";
import {
  IImageButtonStyles,
  ImageButtonOption,
  ImageButtonProps,
} from "./ImageButton.types";
import placeholderImageSrc from "./placeholder_icon.png";

const Label = (props: ImageButtonProps) => {
  return ComponentFactory.instanceOf(ComponentName.Parameter, {
    ...props,
    hideQuantity: false,
    hideStateIcon: false,
  });
};

const InfoButton = (props: any) => {
  return ComponentFactory.instanceOf(ComponentName.InfoButton, props);
};

interface PriceProps {
  value?: ImageButtonOption;
  readOnly: boolean;
  options?: Record<string, unknown>;
}

const Price = (props: PriceProps) => {
  const { options, value, readOnly, ...delegatedProps } = props;
  if (value?.price === undefined || (readOnly && !value?.selected)) return null;

  return ComponentFactory.instanceOf(ComponentName.Price, {
    ...delegatedProps,
    price: value?.price,
    currency: options?.currency,
    showPrices: options?.showPrices,
  });
};

interface ICurrentClassNames {
  captionTextClass: string;
  priceClass: string;
  imageContainerClass: string;
  upperCaptionClass: string;
  lowerCaptionClass: string;
  setNotSelectedClass: boolean;
}

const OPTION_GAP = 20;

/**
 * The ImageButton component renders a ParameterTO as a headline using the Parameter component and its ValueTOs as image buttons.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/ImageButton/ImageButton.tsx)
 * - [ImageButtonStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/ImageButton/ImageButton.styles.ts)
 * - [ImageButtonProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/ImageButton/ImageButton.types.ts)
 *
 * @visibleName ImageButton
 */
const IImageButton = (props: ImageButtonProps) => {
  const {
    styles,
    theme,
    data,
    minOptionWidth,
    maxOptionWidth,
    imageResolution,
    onRenderCell,
    ...delegatedProps
  } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IImageButtonStyles>;
  const readOnly: boolean = (!data.editable ||
    props.options?.readOnly) as boolean;
  const specStateValue = specState(data);
  const isMandatory = data.readyState === READY_STATE_NOT_READY;
  const minColumnWidth = minOptionWidth || 100;
  const maxColumnWidth =
    (maxOptionWidth &&
      (maxOptionWidth > minColumnWidth ? maxOptionWidth : minOptionWidth)) ||
    minColumnWidth + 100;

  const changeValue = (newValue: ImageButtonOption) => {
    if (!newValue.selected) {
      props.onValueChanged(
        data,
        newValue.value,
        RestApiConstants.ValueFormat.Unformatted,
      );
    }
  };

  const columnCount = useRef(0);
  const columnWidth = useRef(0);

  const getItemCountForPage = useCallback(
    (itemIndex, surfaceRect) => {
      const valueCount = data.values?.length || 0;
      const maxWidth = surfaceRect.width - 21; // remove size used up by state icon
      if (itemIndex === 0) {
        columnCount.current = Math.min(
          valueCount,
          Math.floor(maxWidth / minColumnWidth),
        );
        columnWidth.current = Math.min(
          maxColumnWidth,
          Math.floor(maxWidth / columnCount.current),
        );
        const columnsInFirstRow = Math.floor(maxWidth / columnWidth.current);
        const gaps = (columnsInFirstRow - 1) * OPTION_GAP;
        columnWidth.current =
          columnWidth.current - Math.ceil(gaps / columnsInFirstRow);
      }
      return valueCount;
    },
    [data.values, minColumnWidth, maxColumnWidth],
  );

  const getPageHeight = useCallback(() => {
    return 100;
  }, []);

  const classes = {
    optionClass: classNames.option,
    captionTextClass: "",
    priceClass: "",
    imageContainerClass: "",
    upperCaptionClass: "",
    lowerCaptionClass: "",
  };

  const updateClasses = (value: ImageButtonOption) => {
    let currentClassNames: ICurrentClassNames = {
      captionTextClass: classNames.captionText || "",
      priceClass: classNames.price || "",
      imageContainerClass: classNames.imageContainer || "",
      upperCaptionClass: classNames.upperCaption || "",
      lowerCaptionClass: classNames.lowerCaption || "",
      setNotSelectedClass: false,
    };

    currentClassNames = updateClassNames(value, currentClassNames);

    classes.captionTextClass = currentClassNames.captionTextClass || "";
    classes.priceClass = currentClassNames.priceClass || "";
    classes.imageContainerClass = currentClassNames.imageContainerClass || "";
    classes.upperCaptionClass = currentClassNames.upperCaptionClass || "";
    classes.lowerCaptionClass = currentClassNames.lowerCaptionClass || "";
  };

  const updateClassNames = (
    value: ImageButtonOption,
    updateClassNames: ICurrentClassNames,
  ) => {
    if (isMandatory && !readOnly) {
      updateClassNames.imageContainerClass = mergeStyles(
        updateClassNames.imageContainerClass,
        classNames.imageContainerNotReady,
      );
      if (!value.selectable) updateClassNames.setNotSelectedClass = true;
    } else if (readOnly || !value.enabled) {
      updateClassNames.imageContainerClass = mergeStyles(
        updateClassNames.imageContainerClass,
        classNames.imageContainerDisabled,
      );
      updateClassNames.captionTextClass = mergeStyles(
        updateClassNames.captionTextClass,
        classNames.captionTextDisabled,
      );
      updateClassNames.priceClass = mergeStyles(
        updateClassNames.priceClass,
        classNames.priceDisabled,
      );
      updateClassNames.upperCaptionClass = mergeStyles(
        updateClassNames.upperCaptionClass,
        {
          cursor: "default",
        },
      );
      updateClassNames.lowerCaptionClass = mergeStyles(
        updateClassNames.lowerCaptionClass,
        {
          cursor: "default",
        },
      );
      if (value.selected) {
        updateClassNames.imageContainerClass = mergeStyles(
          updateClassNames.imageContainerClass,
          classNames.imageContainerDisabledSelected,
        );
        updateClassNames.captionTextClass = mergeStyles(
          updateClassNames.captionTextClass,
          classNames.captionTextSelectedDisabled,
        );
      }
    } else if (value.selected) {
      updateClassNames.imageContainerClass = mergeStyles(
        updateClassNames.imageContainerClass,
        classNames.imageContainerSelected,
      );
      updateClassNames.captionTextClass = mergeStyles(
        updateClassNames.captionTextClass,
        classNames.captionTextSelected,
      );
    } else if (!value.selectable) {
      updateClassNames.setNotSelectedClass = true;
    } else if (specStateValue === SPEC_STATE_SET_BY_SYSTEM) {
      updateClassNames.imageContainerClass =
        classNames.imageContainerConflict || "";
    }

    if (updateClassNames.setNotSelectedClass) {
      updateClassNames.imageContainerClass = mergeStyles(
        updateClassNames.imageContainerClass,
        classNames.imageContainerNotSelected,
      );
      updateClassNames.captionTextClass = mergeStyles(
        updateClassNames.captionTextClass,
        classNames.captionTextNotSelected,
      );
      updateClassNames.priceClass = mergeStyles(
        updateClassNames.priceClass,
        classNames.priceNotSelected,
      );
    }
    return updateClassNames;
  };

  const defaultRenderCaption = (item?: ImageButtonOption) => {
    const text = getDisplayValue(item?.translatedValue, data.displayUnit);
    return (
      <>
        <Stack
          horizontal
          className={classnames(
            "imageButtonUpperCaption",
            classes.upperCaptionClass,
          )}
          tokens={{ childrenGap: "0.5rem" }}
        >
          <Text
            block
            nowrap={false}
            className={classnames(
              "imageButtonUpperCaptionText",
              classes.captionTextClass,
            )}
            style={{ wordBreak: "break-word" }}
          >
            {text}
          </Text>
          <InfoButton
            {...delegatedProps}
            data={item}
            className={"imageButtonInfoButton"}
          />
        </Stack>
        <Stack.Item
          className={classnames(
            "imageButtonLowerCaption",
            classes.lowerCaptionClass,
          )}
        >
          <div
            className={classnames(
              "imageButtonLowerCaptionPrice",
              classes.priceClass,
            )}
          >
            <Price
              readOnly={readOnly}
              value={item || undefined}
              {...delegatedProps}
            />
          </div>
        </Stack.Item>
      </>
    );
  };

  const onRenderCellCallback = useCallback(
    (item) => {
      const defaultRenderCell = (item?: ImageButtonOption) => {
        const image = item?.imageUrl;
        const imageSrc = image
          ? props.mediaLink?.(image, imageResolution || "small")
          : placeholderImageSrc;
        const enabled = !readOnly && item?.enabled;
        let preventFocusWhenDisabled;
        if (!enabled) {
          preventFocusWhenDisabled = (e: React.MouseEvent) => {
            e.preventDefault();
          };
        }
        item && updateClasses(item);

        return (
          <button
            name={item?.value}
            key={item?.value}
            onClick={() => enabled && item && changeValue(item)}
            onMouseDown={preventFocusWhenDisabled}
            className={classnames("imageButtonOption", classes.optionClass)}
            data-is-focusable={enabled}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: `${columnWidth.current}px`,
            }}
          >
            <Stack
              horizontalAlign={"center"}
              tokens={{ childrenGap: "0" }}
              style={{ width: "100%" }}
              className={"imageButtonStack"}
            >
              <div
                className={classnames(
                  "imageButtonContainer",
                  classes.imageContainerClass,
                )}
              >
                <Image
                  src={imageSrc}
                  width={`${columnWidth.current}px`}
                  height={`${columnWidth.current}px`}
                  maximizeFrame={true}
                  imageFit={ImageFit.centerContain}
                  loading={"lazy"}
                  className={"imageButtonImage"}
                />
              </div>
              <div className={"imageButtonCaption"} style={{ width: "100%" }}>
                {props.onRenderCaption
                  ? props.onRenderCaption(item, defaultRenderCaption)
                  : defaultRenderCaption(item)}
              </div>
            </Stack>
          </button>
        );
      };

      return onRenderCell
        ? onRenderCell(item, defaultRenderCell)
        : defaultRenderCell(item);
    },
    [data, columnWidth, onRenderCell],
  );

  const onRenderPageCallback = useCallback(
    (pageProps) => {
      const page = pageProps.page;
      if (page.isSpacer) return null;

      const { items, itemCount, startIndex } = page;
      const findTabElement = (root: HTMLElement) => {
        const selectedOption = selectedRawValue(data);
        const options = root.getElementsByTagName("button");
        return (
          options.length > 0 &&
          ((selectedOption && options.namedItem(selectedOption)) || options[0])
        );
      };
      let index = 0;
      return (
        <FocusZone
          className={"imageButtonFocusZone"}
          style={{ width: "100%", height: "100%" }}
          defaultTabbableElement={
            findTabElement as string | ((root: HTMLElement) => HTMLElement)
          }
          key={data.id}
        >
          <Stack
            className={"imageButtonFocusStack"}
            horizontal
            wrap
            tokens={{ childrenGap: `${OPTION_GAP}px` }}
          >
            {items
              .slice(startIndex, itemCount)
              .map((i: ImageButtonOption) => {
                if (!i.key) {
                  i.key = index++;
                }
                return i;
              })
              .map(onRenderCellCallback)}
          </Stack>
        </FocusZone>
      );
    },
    [onRenderCellCallback],
  );

  return (
    <Stack className={classnames("imageButtonRoot", classNames.root)}>
      <Label data={data} {...delegatedProps} />
      <List
        className={"imageButtonList"}
        items={data.values}
        getItemCountForPage={getItemCountForPage}
        getPageHeight={getPageHeight}
        renderedWindowsAhead={0}
        onRenderPage={onRenderPageCallback}
      />
    </Stack>
  );
};

export const ImageButton = styled(IImageButton, ImageButtonStyles);
ImageButton.displayName = "ImageButton";
