import {
  equals,
  find,
  match,
  path,
  pathOr,
  reduce,
  replace,
  isNil,
  propEq,
  isEmpty,
  all,
  map,
  assoc,
  split,
} from "ramda";

import { GuiTO, ParameterTO } from "@encoway/c-services-js-client";

import { toParameter } from "../context/configurationUtils";
import { ExtendedProduct } from "../types/product";

type Placeholder = {
  matches: string[];
  regex: RegExp;
  path: (string | number)[];
  replacerFunction: (value: string) => string;
};

const PLACEHOLDER_ARRAY: Pick<
  Placeholder,
  "regex" | "path" | "replacerFunction"
>[] = [
  {
    regex: /\[VALUE\](.*?)\[\/VALUE\]/g,
    path: ["selectedValues", 0, "value"],
    replacerFunction: (value: string) => `'${formatValue(value)}'`,
  },
  {
    regex: /\[NAME\](.*?)\[\/NAME\]/g,
    path: ["translatedName"],
    replacerFunction: (name: string) => `'${name}'`,
  },
];

function formatValue(value: string) {
  if (isNaN(+value)) {
    return value;
  }
  const [integer, decimal] = split(".", value);
  if (isNil(decimal) || equals(+decimal, 0)) {
    return integer;
  }
  return replace(".", ",", value);
}

function replacePlaceholder(
  placeholder: Placeholder,
  text: string,
  parameterTOs: ParameterTO[],
) {
  return reduce(
    (acc: string | undefined, ele: string): string | undefined => {
      const characteristic = replace(/\[.*?\]/g, "", ele);
      const foundParameterTO = find(
        propEq("name", characteristic),
        parameterTOs,
      );
      const value = path<string>(placeholder.path, foundParameterTO);
      if (acc && value) {
        return replace(ele, placeholder.replacerFunction(value), acc);
      }
    },
    text,
    placeholder.matches,
  );
}

function replacements(
  text: string,
  parameterTOs: ParameterTO[],
): string | undefined {
  const placeholderWithMatches = map(
    (placeholder) =>
      assoc("matches", match(placeholder.regex, text), placeholder),
    PLACEHOLDER_ARRAY,
  );
  const allMatchesEmpty = all(
    ({ matches }) => isEmpty(matches),
    placeholderWithMatches,
  );
  if (allMatchesEmpty) {
    return;
  }
  return reduce(
    (acc: string | undefined, placeholder: Placeholder): string | undefined => {
      if (acc) {
        return replacePlaceholder(placeholder, acc, parameterTOs);
      }
    },
    text,
    placeholderWithMatches,
  );
}

function getSoftConstraintLink(
  softConstraintReference: string,
  parameterTO: ParameterTO,
) {
  return replace(
    /^{"([^"]+)"}$/,
    "$1",
    pathOr<string>(
      "",
      ["viewPortProperties", softConstraintReference],
      parameterTO,
    ),
  );
}

export function useSoftConstraint(
  product: ExtendedProduct,
  guiTO: GuiTO | undefined,
  softConstraints: ParameterTO[],
  parameterTO: ParameterTO,
): string | undefined {
  const constraintLink = getSoftConstraintLink(
    "C_SOFTCONSTRAINT_REFERENCE",
    parameterTO,
  );
  const textToReplace = path<string>(
    ["selectedValues", 0, "translatedValue"],
    parameterTO,
  );

  if (!guiTO) {
    throw new Error(
      `Can't determine Soft constraint ${parameterTO.name}, guiTO is not defined`,
    );
  }
  if (textToReplace) {
    if (softConstraints) {
      const foundParameter = find(
        propEq("name", constraintLink),
        softConstraints,
      );
      if (
        foundParameter &&
        equals(
          pathOr<string>("1", ["selectedValues", 0, "value"], foundParameter),
          "0",
        )
      ) {
        const flattenParameter = reduce(toParameter, [], [guiTO.rootContainer]);
        const replacedText = replacements(textToReplace, flattenParameter);
        if (replacedText) {
          return replacedText;
        }
        const placeholderLink = getSoftConstraintLink(
          "C_SOFTCONSTRAINT_PLACEHOLDER_REFERENCE",
          parameterTO,
        );
        const foundCharacteristic = path<string>(
          [placeholderLink, "values", 0],
          product.characteristicValues,
        );
        if (foundCharacteristic) {
          return foundCharacteristic;
        }
        throw new Error(
          `Can't determine Soft constraint characteristic ${parameterTO.name} placeholder reference, guiTO is not defined on ${placeholderLink}`,
        );
      }
    }
  }
  return undefined;
}
