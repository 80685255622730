import { isNil } from "ramda";

/**
 * Function that encrypts or decrypts a string using the AES-GCM algorithm and the specified encryption/decryption key.
 * @async
 * @function
 * @param {string} data - The string to be encrypted/decrypted.
 * @param {key: string, operation: 'encrypt' | 'decrypt'} options - Options object containing the encryption/decryption key and the operation to perform.
 * @returns {Promise<string>} A Promise that resolves with the encrypted/decrypted string.
 */
export async function encryptDecrypt(
  data: string,
  options: { key: string; operation: "encrypt" | "decrypt" },
): Promise<string> {
  const iv = new Uint8Array(12);

  // Convert the encryption/decryption key to a Uint8Array using the TextEncoder API.
  let keyData = new TextEncoder().encode(options.key);

  // If the key length is not 16 or 32, pad or truncate it to the required length.
  if (keyData.length !== 16 && keyData.length !== 32) {
    const keyDataLength = keyData.length > 32 ? 32 : 16;
    const paddedKeyData = new Uint8Array(keyDataLength);
    paddedKeyData.set(keyData.slice(0, keyDataLength));
    keyData = paddedKeyData;
  }

  // Import the key as a CryptoKey object using the window.crypto.subtle API.
  const cryptoKey = await window.crypto.subtle.importKey(
    "raw",
    keyData,
    "AES-GCM",
    true,
    ["encrypt", "decrypt"],
  );

  // Perform the specified encryption/decryption operation using the window.crypto.subtle API.
  if (options.operation === "encrypt") {
    const result = await window.crypto.subtle[options.operation](
      { name: "AES-GCM", iv },
      cryptoKey,
      new TextEncoder().encode(data),
    );
    return btoa(String.fromCharCode(...new Uint8Array(result)));
  }
  const result = await window.crypto.subtle[options.operation](
    { name: "AES-GCM", iv },
    cryptoKey,
    Uint8Array.from(atob(data), (c) => c.charCodeAt(0)),
  );
  return new TextDecoder().decode(result);
}

export function getLocalStorageItem<T>(itemName: string, defaultValue: T): T {
  const item = localStorage.getItem(itemName);
  return isNil(item) ? defaultValue : JSON.parse(item);
}
