import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Constants,
  GuiTO,
  extractParameters,
} from "@encoway/c-services-js-client";
import { Visualization } from "@encoway/visual-editor";

import { ConfigurationContext } from "../context/useConfiguration";
import { AppInstance, SETTINGS } from "../settings";
import { NodeEntry } from "../types/visualization";
import {
  C_PROPERTY_NAMES,
  applyParameters,
  flattenParameters,
  toSelectedValueMap,
  updateVisSettings,
} from "./visualizationUtils";

interface useVisualizationState {
  context: Visualization | undefined;
  node: NodeEntry | undefined;
}

interface useVisualizationStore {
  vis: useVisualizationState;
  visRef: MutableRefObject<any>;
  visKey: number;
  visSettings: typeof SETTINGS.visualization.settings | null;
  load(): Promise<void>;
}

export function useVisualization(
  articleName: string,
  configurationId: string,
  language = "en-US",
): useVisualizationStore {
  const { eventBus, guiTO } = useContext(ConfigurationContext);
  const [vis, setVis] = useState<useVisualizationState>({
    context: undefined,
    node: undefined,
  });
  const visRef = useRef();
  // needed for remounting the VisualEditor, because camera settings cant be set at runtime
  const [visKey, setVisKey] = useState(1);
  const [visSettings, setVisSettings] = useState<
    typeof SETTINGS.visualization.settings | null
  >(null);

  const { baseUrl, version, token } = SETTINGS.visualization;

  const load = useCallback(async () => {
    const visualizationContext = await Visualization.load(
      baseUrl,
      version,
      token,
    );
    visualizationContext.cloud.addServer({
      name: "default",
      baseUrl: SETTINGS.showroom.url,
      http: AppInstance.http,
      locale: language,
      options: {
        configuration: {
          mappingOptions: {
            mappingProfile: Constants.MappingProfile.Maximum,
          },
        },
      },
    });

    const node = await visualizationContext.cloud.mount(
      undefined,
      "product",
      "article",
      {
        id: articleName,
        configurationId: configurationId,
      },
      "default",
      true,
    );
    setVis({
      node,
      context: visualizationContext,
    });
  }, [articleName, configurationId]);

  const flattenedParameterMap = useMemo(
    () => toSelectedValueMap(guiTO!.rootContainer, C_PROPERTY_NAMES),
    [guiTO],
  );

  const minAzimuthAngle = flattenedParameterMap["C_VIS_MIN_AZIMUTH_ANGLE"];
  const maxAzimuthAngle = flattenedParameterMap["C_VIS_MAX_AZIMUTH_ANGLE"];
  const minPolarAngle = flattenedParameterMap["C_VIS_MIN_POLAR_ANGLE"];
  const maxPolarAngle = flattenedParameterMap["C_VIS_MAX_POLAR_ANGLE"];

  useEffect(() => {
    if (vis.node) {
      return eventBus
        .filter(
          (e: any) => e.event === "UpdateState" || e.event === "InitialState",
        )
        .onValue(({ rawState }: { rawState: GuiTO }) => {
          const extractedParameters = extractParameters(rawState);
          const flattenedParameters = flattenParameters(
            extractedParameters.state,
          );
          vis?.node?.state?.setState(flattenedParameters);
        });
    }
  }, [vis]);

  useEffect(() => {
    applyParameters(vis.context, flattenedParameterMap);
  }, [vis.context, flattenedParameterMap]);

  useEffect(() => {
    setVisKey((prevKey) => prevKey + 1);
  }, [minAzimuthAngle, maxAzimuthAngle, minPolarAngle, maxPolarAngle]);

  return useMemo(
    () => ({
      vis,
      visRef,
      visKey,
      visSettings: updateVisSettings(flattenedParameterMap),
      load,
    }),
    [vis, visRef, visKey, visSettings, load],
  );
}
