import { IProcessedStyleSet } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { TooltipHost, TooltipOverflowMode } from "@fluentui/react/lib/Tooltip";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React from "react";

import { L10n } from "@encoway/l10n";

import { PriceStyles } from "./Price.styles";
import { IPriceStyles, PriceProps } from "./Price.types";

/**
 * Displays a numeric price and its currency.
 * The price will be displayed according to the current locale (using the encoway L10n package).
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Price/Price.tsx)
 * - [PriceStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Price/Price.styles.ts)
 * - [PriceProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Price/Price.types.ts)
 * - [MS Fluent Text](https://developer.microsoft.com/de-DE/fluentui#/controls/web/text)
 *
 * @visibleName Price
 */
const IPrice = (props: PriceProps) => {
  const { price, currency, variant, styles, theme, showPrices } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IPriceStyles>;

  if (showPrices === false || !currency) {
    return null;
  }

  let displayPrice = "";
  if (price !== undefined && price !== null) {
    const options = { style: "currency", currency };
    try {
      displayPrice =
        price.toLocaleString(L10n.currentFullLocale(), options) || "";
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Text
      className={classnames("priceText", classNames.root)}
      variant={variant}
      nowrap={true}
      block={true}
    >
      <TooltipHost
        className={"priceTextTooltipHost"}
        overflowMode={TooltipOverflowMode.Parent}
        content={displayPrice}
      >
        {displayPrice}
      </TooltipHost>
    </Text>
  );
};

export const Price = styled(IPrice, PriceStyles);
Price.displayName = "Price";
