import { mergeStyleSets } from "@fluentui/react";

const loadingStyled = mergeStyleSets({
  root: {
    display: "flex",
    height: "65vh",
    alignItems: "center",
    justifyContent: "center",
  },
});

export function Loading() {
  return (
    <div className={loadingStyled.root}>
      <div className="loader" />
    </div>
  );
}
