import { AcceptIcon, ClearIcon } from "@fluentui/react-icons-mdl2";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { Separator } from "@fluentui/react/lib/Separator";
import { Stack } from "@fluentui/react/lib/Stack";
import {
  IProcessedStyleSet,
  mergeStyles,
  registerIcons,
} from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { CHECKBOX_FALSE_VALUES, ComponentName } from "../constants";
import { determineLayerClass } from "../helperFunctions";
import { EpriStyles } from "./Epri.styles";
import { EpriProps, IEpriStyles } from "./Epri.types";

const ACCEPT = "Accept";
const CLEAR = "Clear";

registerIcons(
  {
    icons: {
      [ACCEPT]: <AcceptIcon />,
      [CLEAR]: <ClearIcon />,
    },
  },
  { disableWarnings: true },
);

const defaultDialogMinWidth = "50rem";

const isCheckBox = (parameter: ParameterTO) => {
  const viewPort: string | undefined = parameter.viewPort;
  return (
    viewPort &&
    (viewPort === ComponentName.Checkbox ||
      ComponentFactory.aliases[viewPort] === ComponentName.Checkbox)
  );
};

const isChecked = (parameter: ParameterTO) => {
  return (
    parameter.selectedValues &&
    parameter.selectedValues.length === 1 &&
    CHECKBOX_FALSE_VALUES.indexOf(parameter.selectedValues[0].value) >= 0
  );
};

const addUnit = (value: string, parameter: ParameterTO) => {
  if (value) {
    const unit = parameter.displayUnit?.translatedUnit;
    if (unit) {
      return `${value} ${unit}`;
    }
  }
  return value || "";
};

interface TooltipTextProps {
  classNames: IProcessedStyleSet<IEpriStyles>;
  text?: string;
}
const TooltipText = (props: TooltipTextProps) => {
  const { classNames, text } = props;
  return (
    <TooltipHost
      content={text || ""}
      className={classnames("epriTooltipHost", classNames.tooltip)}
      styles={{
        root: { display: "inline-block", maxWidth: "100%" },
      }}
    >
      <Text
        block
        nowrap
        className={classnames("epriTooltipText", classNames.text)}
      >
        {text}
      </Text>
    </TooltipHost>
  );
};

interface ParameterItemProps {
  parameter: ParameterTO;
  classNames: IProcessedStyleSet<IEpriStyles>;
  value?: string;
}
const ParameterItem = (props: ParameterItemProps) => {
  const { classNames, parameter, value } = props;
  let displayValue = null;
  if (value) {
    displayValue = (
      <div
        className={classnames("epriParameterValue", classNames.parameterValue)}
      >
        <TooltipText text={value} classNames={classNames} />
      </div>
    );
  }
  return (
    <Stack
      key={parameter.id}
      horizontal
      horizontalAlign="space-between"
      className={classnames("epriParameterStack", classNames.parameter)}
    >
      <div
        className={classnames("epriParameterName", classNames.parameterName)}
      >
        <TooltipText text={parameter.translatedName} classNames={classNames} />
      </div>
      {displayValue}
    </Stack>
  );
};

interface SelectionProps {
  parameter: ParameterTO;
  classNames: IProcessedStyleSet<IEpriStyles>;
  newValue: string;
}
const CurrentSelection = (props: SelectionProps) => {
  const { t } = useTranslation();
  const { parameter, classNames, newValue } = props;
  return (
    <div className={classnames("epriSelectedSection", classNames.section)}>
      <Text
        variant="mediumPlus"
        className={classnames("epriSelectedText", classNames.headline)}
      >
        {t("t:epri.selection")}
      </Text>
      <Separator className={"epriSelectedSeparator"} />
      <ParameterItem
        classNames={classNames}
        parameter={parameter}
        value={newValue}
      />
    </div>
  );
};

const checkboxValue = (parameter: ParameterTO, t: TFunction) => {
  if (isCheckBox(parameter)) {
    return isChecked(parameter)
      ? t("t:epri.checkbox.selected")
      : t("t:epri.checkbox.deselected");
  }
};

const translatedValue = (parameter: ParameterTO) => {
  return parameter.values?.find((v) => v.selected)?.translatedValue;
};

interface AdjustmentsProps {
  classNames: IProcessedStyleSet<IEpriStyles>;
  affectedParameters: ParameterTO[];
}
const Adjustments = (props: AdjustmentsProps) => {
  const { t } = useTranslation();
  const { classNames, affectedParameters } = props;
  const renderParameterItem = (parameter: ParameterTO) => {
    let value = translatedValue(parameter);
    if (value) {
      if (value === parameter.translatedName) {
        // Workaround to identify checked checkboxes,
        // because the parameter may not have information about its display component
        value = t("t:epri.checkbox.selected");
      } else if (isCheckBox(parameter)) {
        value = t("t:epri.checkbox.deselected");
      } else {
        value = addUnit(value, parameter);
      }
    }
    return (
      <ParameterItem
        key={parameter.id}
        classNames={classNames}
        parameter={parameter}
        value={value}
      />
    );
  };
  const adjustments = affectedParameters.map(renderParameterItem);
  return (
    <div className={classnames("epriHeadlineSection", classNames.section)}>
      <Text
        variant="mediumPlus"
        className={classnames("epriHeadlineText", classNames.headline)}
      >
        {t("t:epri.adjustments")}
      </Text>
      <Separator className={"epriHeadlineSeperator"} />
      <div className={classnames("epriContentSection", classNames.section)}>
        {adjustments}
      </div>
    </div>
  );
};

const EpriText = (props: SelectionProps) => {
  const { t } = useTranslation();
  const { parameter, classNames, newValue } = props;
  const epriText = t("t:epri.text", {
    selection: `"${parameter.translatedName}: ${newValue}"`,
  });
  return (
    <div className={classnames("epriTextSection", classNames.section)}>
      {epriText}
    </div>
  );
};

/**
 * The Epri component resolves prioritization conflicts.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Epri/Epri.tsx)
 * - [EpriStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Epri/Epri.styles.ts)
 * - [EpriProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Epri/Epri.types.ts)
 * - [MS Fluent Dialog](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dialog)
 *
 * @visibleName Epri
 */
const EpriDialog = (props: EpriProps) => {
  const { t } = useTranslation();
  const { source, styles, theme, value } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IEpriStyles>;
  const dialogMinWidth = props.dialogMinWidth || defaultDialogMinWidth;
  const newValue = checkboxValue(source, t) || addUnit(value, source);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        type: DialogType.normal,
        showCloseButton: false,
        title: t("t:epri.title"),
      }}
      onDismiss={() => props.onDecline()}
      minWidth={`min(${dialogMinWidth}, 100%)`}
      modalProps={{
        className: classnames(
          "epriDialog",
          mergeStyles(
            classNames.dialog,
            classNames.modal,
            determineLayerClass(props.modalProps?.layerProps, classNames.layer),
          ),
        ),
        isBlocking: true,
        overlay: {
          isDarkThemed: false,
          className: classNames.overlay,
        },
        ...props.modalProps,
      }}
    >
      <div className={classnames("epriDialogContent", classNames.content)}>
        <EpriText
          classNames={classNames}
          parameter={source}
          newValue={newValue}
        />
        <CurrentSelection
          classNames={classNames}
          parameter={source}
          newValue={newValue}
        />
        <Adjustments
          classNames={classNames}
          affectedParameters={props.affectedParameters}
        />
      </div>
      <DialogFooter
        className={classnames("epriDialogFooter", classNames.footer)}
      >
        <PrimaryButton
          onClick={() => props.onAccept()}
          text={t("t:epri.accept")}
          iconProps={{ iconName: ACCEPT }}
          className={classnames(
            "epriDialogAcceptButton",
            classNames.acceptButton,
          )}
        />
        <DefaultButton
          onClick={() => props.onDecline()}
          text={t("t:epri.reject")}
          iconProps={{ iconName: CLEAR }}
          className={classnames(
            "epriDialogRejectButton",
            classNames.rejectButton,
          )}
        />
      </DialogFooter>
    </Dialog>
  );
};

export const Epri = styled(EpriDialog, EpriStyles);
Epri.displayName = "Epri";
