import { mergeStyleSets, Stack } from "@fluentui/react";
import { useEffect, useRef } from "react";

import { L10n } from "@encoway/l10n";
import { VisualEditor } from "@encoway/visual-editor";

import { useVisualization } from "../../hooks/useVisualization";
import { SETTINGS } from "../../settings";

const visStyled = mergeStyleSets({
  root: {
    zIndex: 1,
  },
});

export function Visualization({
  articleName,
  configurationId,
  style,
}: {
  articleName: string;
  configurationId: string;
  style?: any;
}) {
  const { vis, visRef, visKey, visSettings, load } = useVisualization(
    articleName,
    configurationId,
    SETTINGS.language,
  );

  useEffect(() => {
    load().then();
  }, []);

  if (vis.context) {
    return (
      <Stack styles={visStyled} className="encoway-visualization">
        <VisualEditor
          key={visKey}
          style={style ? style : { height: "350px", width: "100%" }}
          visualization={vis.context}
          settings={visSettings}
          locale={L10n.currentFullLocale()}
          ref={visRef}
          onReady={() => visRef.current.zoom()}
        />
      </Stack>
    );
  }

  return null;
}
