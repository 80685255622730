import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IInfoButtonStyles } from "./InfoButton.types";

export const InfoButtonStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IInfoButtonStyles>
> = (theme: IPartialTheme) => ({
  root: {},
  iconButton: {
    color: theme.palette?.neutralTertiary,
    width: "1.25rem",
    height: "1.25rem",
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    selectors: {
      ":hover": {
        backgroundColor: "transparent",
        color: theme.palette?.themePrimary,
        textDecoration: "none",
      },
      ":active": {
        backgroundColor: "transparent",
        color: theme.palette?.themePrimary,
        textDecoration: "none",
      },
      ":active:hover": {
        backgroundColor: "transparent",
        color: theme.palette?.themePrimary,
        textDecoration: "none",
      },
      ":focus:not(:hover)": {
        color: theme.palette?.themePrimary,
      },
      ":focus:not(:focus-visible):not(:hover)": {
        color: theme.palette?.neutralTertiary,
      },
      ".ms-Fabric--isFocusVisible &:focus": {
        outline: "0",
        boxShadow: "none",
        color: theme.palette?.themePrimary,
      },
    },
  },
  icon: {
    lineHeight: "1.25rem",
    fontSize: "1rem",
    margin: 0,
    // the info icon itself is one pixel off which looks weird
    // This is a workaround.
    paddingTop: 1,
  },
  content: {
    marginTop: "1em",
  },
  image: {
    margin: "auto",
  },
  shortText: {
    fontWeight: "bold",
  },
  longText: {},
});
