import { ITheme, mergeStyleSets, Stack } from "@fluentui/react";
import classNames from "classnames";
import { map } from "ramda";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentName as ConfiguratorComponentName } from "@encoway/cui-configurator-components";
import { ComponentFactory } from "@encoway/react-configurator";

import { ConfiguratorComponentProps } from "../../../types/configuration";
import { ConfSection } from "./ConfSection";

function gridStyle(theme?: ITheme) {
  return mergeStyleSets({
    root: {
      alignSelf: "center",
      flexFlow: "initial",
    },
  });
}

export function ConfGrid({
  data,
  size,
  ...props
}: ConfiguratorComponentProps<ContainerTO> & { size: string }) {
  const { root } = gridStyle();

  return (
    <Stack
      className={`customGrid configurator-grid grid-${size} grid-md-1`}
      style={{ padding: 0 }}
    >
      {data.parameters &&
        map(
          (c) => (
            <Stack
              className={classNames("customGridParameter", root)}
              key={c.id}
            >
              {ComponentFactory.instanceOf(
                c.viewPort || ConfiguratorComponentName.Parameter,
                { ...props, data: c },
              )}
            </Stack>
          ),
          data.parameters,
        )}
      {data.children &&
        map(
          (c) => (
            <Stack className={classNames("customGridSection", root)} key={c.id}>
              <ConfSection {...{ ...props, data: c }} />
            </Stack>
          ),
          data.children,
        )}
    </Stack>
  );
}
