import { mergeStyleSets } from "@fluentui/react";
import classNames from "classnames";
import { equals } from "ramda";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useProductContext } from "../../context/useProduct";
import { getLocalStorageItem } from "../../hooks/browserStorageUtils";
import { SETTINGS } from "../../settings";
import { ConfFinish } from "./ConfFinish";
import { Configurator } from "./Configurator";
import { Visualization } from "./Visualization";
import { getBooleanModelProperty } from "./configuratorUtils";
import { Sidebar } from "./sidebar/Sidebar";

function configurationStyle() {
  return mergeStyleSets({
    root: {
      position: "relative",
      display: "grid",
      gridTemplateColumns: "1fr .5fr",
    },
    main: {
      paddingRight: "1em",
      minHeight: "100vh",
      background: "#FFF",
    },
    stickyBottomBar: {
      position: "sticky",
      bottom: 0,
      borderTop: "1px solid #CCC",
      padding: "10px 2px",
      backgroundColor: "white",
      gridColumn: "1 / -1",
    },
  });
}

export function Configuration() {
  const [showSideBar, setShowSideBar] = useState(
    getLocalStorageItem("showSideBar", true),
  );
  const { products } = useProductContext();
  const { root, main, stickyBottomBar } = configurationStyle();
  const { session, article, action } = useParams<{
    session: string;
    article: string;
    action: string;
  }>();

  const hideVisualization = getBooleanModelProperty(
    SETTINGS.configuration.modelProperties.hideVisualization,
    article,
    products,
  );

  if (article && session) {
    return (
      <>
        {equals(action, "vis") && (
          <div className="customVisEditMode">
            {!hideVisualization && (
              <Visualization
                articleName={article}
                configurationId={session}
                style={{ height: "85vh", width: "100%" }}
              />
            )}
          </div>
        )}
        <div
          className={classNames("configuratorRoot", root, {
            sidebarOpen: showSideBar,
          })}
        >
          <div className={classNames("configuratorMain", main)}>
            <Configurator />
          </div>
          <Sidebar
            session={session}
            article={article}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}
          />
          <div className={classNames("stickyBottomBar", stickyBottomBar)}>
            <ConfFinish article={article} />
          </div>
        </div>
      </>
    );
  }

  return null;
}
