import { Modal } from "@fluentui/react/lib/Modal";
import {
  Spinner as FluentSpinner,
  SpinnerSize,
} from "@fluentui/react/lib/Spinner";
import { styled } from "@fluentui/react/lib/Utilities";
import React from "react";

import { determineLayerStyle } from "../helperFunctions";
import { SpinnerStyles } from "./Spinner.styles";
import { SpinnerProps } from "./Spinner.types";

const ISpinner = (props: SpinnerProps) => {
  return (
    <Modal
      isOpen={true}
      isBlocking={true}
      isDarkOverlay={false}
      className={"spinnerModal"}
      styles={{
        root: {
          backgroundColor: "transparent",
        },
        main: {
          background: "transparent",
          boxShadow: "none",
        },
        scrollableContent: {
          overflow: "hidden",
        },
        layer: determineLayerStyle(
          props.modalProps?.layerProps,
          (props.styles as any).layer,
        ),
      }}
      {...props.modalProps}
    >
      <FluentSpinner
        className={"spinnerFluentSpinner"}
        size={props.spinnerSize || SpinnerSize.large}
      />
    </Modal>
  );
};

/**
 * The Spinner component renders a spinner and modal when the application is loading.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Spinner/Spinner.tsx)
 * - [SpinnerStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Spinner/Spinner.styles.ts)
 * - [SpinnerProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Spinner/Spinner.types.ts)
 * - [MS Fluent Spinner](https://developer.microsoft.com/de-DE/fluentui#/controls/web/spinner)
 * - [MS Fluent Modal](https://developer.microsoft.com/de-DE/fluentui#/controls/web/modal)
 *
 * @visibleName Spinner
 */
export const Spinner = styled(ISpinner, SpinnerStyles);
Spinner.displayName = "Spinner";
