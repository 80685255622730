export const ComponentName = {
  OptionalPositionDisplay: "optionalPositionDisplay",
  AdditionalValue: "additionalValue",
  BurgerMenu: "burgerMenu",
  ButtonGroup: "buttonGroup",
  Checkbox: "checkBox",
  DisplayOnly: "displayOnly",
  Dropdown: "dropDown",
  FilterDropdown: "filterDropDown",
  DetailButton: "detailButton",
  DetailDropdown: "detailDropDown",
  DummyViewport: "DUMMY_VIEWPORT",
  Epri: "epri",
  ErrorViewport: "ErrorViewport",
  Hidden: "hidden",
  ImageButton: "imageButton",
  InfoButton: "infoButton",
  InputField: "inputField",
  Label: "label",
  LinkedTree: "linkedTree",
  Message: "message",
  MultiValueCheckbox: "multiValueCheckbox",
  NotReadyCount: "NotReadyCount",
  Parameter: "parameter",
  Price: "price",
  Quantity: "quantity",
  RadioButton: "radioButton",
  Root: "Root",
  Section: "section",
  Spinner: "spinner",
  StateIcon: "stateIcon",
  StructureViewport: "STRUCTURE_VIEWPORT",
  Tab: "Tab",
  TabButton: "TabButton",
  Tabs: "Tabs",
  TabsFooter: "TabsFooter",
  TabsList: "TabsList",
  TextButton: "textButton",
};

/**
 * Check an events 'type' property for one of these constants.
 */
export const EventTypes = {
  /**
   * Fired when the linked tree has been loaded. The 'tree' property will contain the new linked tree that has just been loaded.
   */
  LinkedTreeLoaded: "LinkedTreeLoaded",
};

export const NO_VALUE = "NO_VALUE";
export const READY_STATE_NOT_READY = "NOT_READY";
export const ERROR_STATE = "ERROR";
export const SPEC_STATE_SET_BY_SYSTEM = "SET_BY_SYSTEM";
export const CHECKBOX_FALSE_VALUES = [NO_VALUE, "0", "N", "0.0"];
export const VIEWPORT_PROPERTY_VALUE = {
  // eslint-disable-next-line
  True: '{"1"}',
};
