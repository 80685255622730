import { ContainerTO } from "@encoway/c-services-js-client";

import { READY_STATE_NOT_READY } from "./constants";

/**
 * Traverse the given container and count all parameters which have the readyState `NOT_READY`.
 * @param {Object} container
 */
export function countNotReadyChildren(container: ContainerTO) {
  let count = 0;
  if (container.numberOfMandatoryParameters !== undefined) {
    count +=
      container.numberOfMandatoryParameters -
      (container.numberOfRatedMandatoryParameters || 0);
  } else if (container.parameters) {
    count += container.parameters.reduce(
      (accumulator, parameter) =>
        parameter.readyState === READY_STATE_NOT_READY
          ? accumulator + 1
          : accumulator,
      0,
    );
  }
  if (container.children) {
    count += container.children
      .map(countNotReadyChildren)
      .reduce((acc, v) => acc + v, 0);
  }
  return count;
}
