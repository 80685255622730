import { includes, path, pathOr, replace, values } from "ramda";

import { ParameterTO } from "@encoway/c-services-js-client";
import { ComponentName } from "@encoway/cui-configurator-components";

export const CHECKBOX_POSITIONS = {
  START: "start",
  END: "end",
  STANDARD: "standard",
} as const;
const CHECKBOX_POSITION_PATH = ["viewPortProperties", "C_CHECKBOX_SIDE"];
const CHECKBOX_VIEWPORT_PATH = ["viewPort"];
export function getCheckboxSide(data: ParameterTO) {
  if (path(CHECKBOX_VIEWPORT_PATH, data) !== ComponentName.Checkbox) {
    return undefined;
  }
  const parameterValue = replace(
    /[^a-z]/gm,
    "",
    pathOr("", CHECKBOX_POSITION_PATH, data),
  );
  if (includes(parameterValue, values(CHECKBOX_POSITIONS))) {
    return parameterValue;
  }
  return undefined;
}
