import { GlobalNavButtonIcon } from "@fluentui/react-icons-mdl2";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { DirectionalHint } from "@fluentui/react/lib/ContextualMenu";
import {
  IProcessedStyleSet,
  mergeStyles,
  registerIcons,
} from "@fluentui/react/lib/Styling";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React from "react";

import { BurgerMenuStyles } from "./BurgerMenu.styles";
import { BurgerMenuProps, IBurgerMenuStyles } from "./BurgerMenu.types";

const MENU = "GlobalNavButton";
registerIcons(
  {
    icons: {
      [MENU]: <GlobalNavButtonIcon />,
    },
  },
  { disableWarnings: true },
);

/**
 * Display a parameter burger menu.
 */
const IBurgerMenu = (props: BurgerMenuProps) => {
  const { styles, theme, onlyPlaceholder, parameterMenuData } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IBurgerMenuStyles>;

  const items = [...(parameterMenuData?.getItems() || [])];

  const menuProps = React.useMemo(
    () => ({
      shouldFocusOnMount: true,
      directionalHint: DirectionalHint.bottomRightEdge,
      calloutProps: {
        className: classNames.callout,
      },
      items,
    }),
    [items],
  );

  if (
    props.options?.readOnly ||
    props.options?.hideBurgerMenu ||
    !parameterMenuData ||
    parameterMenuData.suppressDisplay
  ) {
    return null;
  }

  const disabled =
    onlyPlaceholder || items.findIndex((item) => !item.disabled) < 0;

  let className = classNames.button;
  if (!disabled) {
    className = mergeStyles(classNames.button, classNames.visible);
  }

  return (
    <DefaultButton
      className={classnames("burgerMenuDefaultButton", className)}
      disabled={disabled}
      menuProps={menuProps}
      menuIconProps={{
        iconName: disabled ? "" : MENU,
      }}
    />
  );
};

export const BurgerMenu = styled(IBurgerMenu, BurgerMenuStyles);
BurgerMenu.displayName = "BurgerMenu";
