import {
  AcceptIcon,
  ClearIcon,
  EditSolid12Icon,
} from "@fluentui/react-icons-mdl2";
import {
  DefaultButton,
  IconButton,
  PrimaryButton,
} from "@fluentui/react/lib/Button";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { Stack } from "@fluentui/react/lib/Stack";
import {
  IProcessedStyleSet,
  mergeStyles,
  registerIcons,
} from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import { TextField } from "@fluentui/react/lib/TextField";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

import { Quantity as QuantityTO } from "@encoway/c-services-js-client";
import { L10n } from "@encoway/l10n";

import { determineLayerClass } from "../helperFunctions";
import { useValidation } from "../validation";
import { QuantityStyles } from "./Quantity.styles";
import { QuantityProps, IQuantityStyles } from "./Quantity.types";

const ACCEPT = "Accept";
const CLEAR = "Clear";
const EDIT = "EditSolid12";

registerIcons(
  {
    icons: {
      [ACCEPT]: <AcceptIcon />,
      [CLEAR]: <ClearIcon />,
      [EDIT]: <EditSolid12Icon />,
    },
  },
  { disableWarnings: true },
);

const translate = (key: string) => {
  return L10n.format(key, undefined);
};

const getQuantityData = (
  quantity: QuantityTO,
  readOnly: boolean,
  error?: string,
  ignoreEditable?: boolean,
) => {
  const editable =
    (quantity.editable || ignoreEditable) &&
    quantity.minimumQuantity !== quantity.maximumQuantity;

  const hidden =
    (!editable && quantity.currentQuantity === 1) ||
    (editable && readOnly && quantity.currentQuantity === 1);

  const hasRestrictedQuantity =
    quantity.currentMaximumQuantity !== quantity.maximumQuantity;

  const max =
    editable && quantity.maximumQuantity === undefined
      ? `${L10n.format("Configuration.Quantity.Positive.Infinity")}`
      : quantity.maximumQuantity.toLocaleString(L10n.currentFullLocale());
  const min =
    editable && quantity.minimumQuantity === undefined
      ? `${L10n.format("Configuration.Quantity.Negative.Infinity")}`
      : quantity.minimumQuantity.toLocaleString(L10n.currentFullLocale());

  const restriction = hasRestrictedQuantity
    ? {
        minimumQuantity: min,
        maximumQuantity: quantity.currentMaximumQuantity.toLocaleString(
          L10n.currentFullLocale(),
        ),
      }
    : undefined;

  const restrictedLabel =
    !error &&
    restriction &&
    (restriction.minimumQuantity !== restriction.maximumQuantity
      ? `${L10n.format("Configuration.Quantity.Restricted.Label")} ${
          restriction.minimumQuantity
        } - ${restriction.maximumQuantity}`
      : `${L10n.format("Configuration.Quantity.Restricted.Label")} ${
          restriction.maximumQuantity
        }`);

  return [min, max, hidden, editable, restrictedLabel];
};

interface EditIconProps {
  buttonClass?: string;
  visible?: boolean;
  onClick: () => void;
}
const EditIcon = (props: EditIconProps) => {
  return props.visible ? (
    <IconButton
      className={classnames("quantityEditButton", props.buttonClass)}
      iconProps={{ iconName: EDIT }}
      onClick={props.onClick}
    />
  ) : null;
};

/**
 * The Quantity component renders the quantity as a MS Fluent Text for selected values if both the quantity exists and its amount is greater than 1.
 * If the minimum and maximum values of the quantity are not equal, a MS Fluent IconButton is displayed next to the quantity itself, opening a MS Fluent Callout, which contains a MS Fluent TextField that is used to edit the quantity.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Quantity/Quantity.tsx)
 * - [QuantityStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Quantity/Quantity.styles.ts)
 * - [QuantityProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/releases/24.x/cui/features/configurator-components/src/components/Quantity/Quantity.types.ts)
 * - [MS Fluent Stack](https://developer.microsoft.com/de-DE/fluentui#/controls/web/stack)
 * - [MS Fluent Text](https://developer.microsoft.com/de-DE/fluentui#/controls/web/text)
 * - [MS Fluent Button](https://developer.microsoft.com/de-DE/fluentui#/controls/web/button)
 * - [MS Fluent Callout](https://developer.microsoft.com/de-DE/fluentui#/controls/web/callout)
 * - [MS Fluent TextField](https://developer.microsoft.com/de-DE/fluentui#/controls/web/textfield)
 *
 * @visibleName Quantity
 */
function IQuantity(props: QuantityProps) {
  const { styles, theme } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IQuantityStyles>;
  const quantityID = props.quantityId || `quantity-${props.data.id}`;
  const quantity =
    props.quantity || props.data.values?.find((v) => v.selected)?.quantity;
  const readOnly = props.options?.readOnly;

  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const [error, setError] = useState<string | undefined>();
  const min = quantity?.minimumQuantity || 1;
  const max =
    quantity?.maximumQuantity && quantity.maximumQuantity < min
      ? min
      : quantity?.maximumQuantity;
  const validate = useValidation(
    "Quantity",
    setError,
    min?.toLocaleString(L10n.currentFullLocale()),
    max?.toLocaleString(L10n.currentFullLocale()),
    min,
    max,
  );

  function setInitialValue() {
    setCurrentValue(
      quantity?.currentQuantity?.toLocaleString(L10n.currentFullLocale()) || "",
    );
    setError(undefined);
  }

  useEffect(() => {
    if (quantity) {
      setInitialValue();
    }
  }, [quantity]);

  React.useEffect(() => {
    validate(currentValue, { updateError: true, removeError: true });
  }, [currentValue]);

  if (!quantity || quantity.maximumQuantity <= 1) {
    return null;
  }

  const submit = () => {
    const validatedNumber = validate(currentValue);
    if (!isNaN(validatedNumber)) {
      if (quantity?.currentQuantity !== validatedNumber) {
        props.onValueChanged(
          props.data,
          { quantity: validatedNumber },
          undefined,
        );
      }
      setEditing(false);
    }
  };

  const discard = () => {
    if (error || quantity.currentQuantity !== validate(currentValue)) {
      setInitialValue();
    }
    setEditing(false);
  };

  const onKeyPressed = (event: React.KeyboardEvent) => {
    if (event.charCode === 13) {
      submit();
    }
  };

  const [
    minQuantity,
    maxQuantity,
    hideQuantity,
    isQuantityEditable,
    restrictedLabel,
  ] = getQuantityData(quantity, readOnly === true, error, props.ignoreEditable);

  let className = classNames.inputField;
  if (error) {
    className = mergeStyles(className, classNames.inputError);
  }

  const textClass = readOnly ? classNames.disabled : undefined;

  const label: string | undefined = error
    ? undefined
    : `${L10n.format(
        "Configuration.Quantity.Label",
      )} ${minQuantity} - ${maxQuantity}`;

  const modalProps = {
    className: classnames(
      "quantityModalRoot",
      mergeStyles(
        classNames.dialog,
        classNames.modal,
        determineLayerClass(props.modalProps?.layerProps, classNames.layer),
      ),
    ),
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
    overlay: {
      isDarkThemed: false,
      className: classNames.overlay,
    },
    ...props.modalProps,
  };

  const dialogContentProps = {
    type: DialogType.normal,
    showCloseButton: true,
    title: translate("Configuration.Dialog.quantity"),
  };
  return hideQuantity ? null : (
    <Stack
      horizontal
      className={classnames("quantityWrapper", classNames.root)}
    >
      <Text
        nowrap
        id={quantityID}
        className={classnames("quantityText", textClass)}
      >
        {`${quantity.currentQuantity.toLocaleString(
          L10n.currentFullLocale(),
        )} ${L10n.format("Configuration.Quantity.Label.Short")}`}
      </Text>
      <EditIcon
        buttonClass={classnames("quantityEditIcon", classNames.iconButton)}
        visible={isQuantityEditable === true && !readOnly}
        onClick={() => setEditing(true)}
      />
      {editing && (
        <Dialog
          className={"quantityModal"}
          hidden={!editing}
          onDismiss={() => {
            discard();
            setEditing(false);
          }}
          dialogContentProps={dialogContentProps}
          minWidth={"25rem"}
          modalProps={modalProps}
        >
          <TextField
            type="number"
            className={classnames("quantityDialogTextField", className)}
            description={label}
            value={currentValue}
            autoFocus
            onChange={(e) => setCurrentValue(e.currentTarget.value || "")}
            onKeyPress={onKeyPressed}
          />
          {restrictedLabel && (
            <Text
              className={classnames(
                "quantityModalText",
                classNames.restrictedQuantity,
              )}
            >
              {restrictedLabel}
            </Text>
          )}
          {error && (
            <Text
              className={classnames(
                "quantityModalErrorText",
                classNames.errorMessage,
              )}
            >
              {error}
            </Text>
          )}
          <DialogFooter
            className={classnames("quantityDialogFooter", classNames.footer)}
          >
            <PrimaryButton
              onClick={submit}
              disabled={error !== undefined}
              text={translate("Configuration.Dialog.accept")}
              iconProps={{ iconName: ACCEPT }}
              className={classnames(
                "quantityAcceptButton",
                classNames.acceptButton,
              )}
            />
            <DefaultButton
              onClick={discard}
              text={translate("Configuration.Dialog.reject")}
              iconProps={{ iconName: CLEAR }}
              className={classnames(
                "quantityRejectButton",
                classNames.rejectButton,
              )}
            />
          </DialogFooter>
        </Dialog>
      )}
    </Stack>
  );
}

/**
 * The Quantity component renders the quantity as a MS Fluent Text for selected values if both the quantity exists and its amount is greater than 1.
 * If the minimum and maximum values of the quantity are not equal, a MS Fluent IconButton is displayed next to the quantity itself, opening a MS Fluent Callout, which contains a MS Fluent TextField that is used to edit the quantity.
 *
 * @see QuantityStyles
 * @see QuantityProps
 */
export const Quantity = styled(IQuantity, QuantityStyles);
Quantity.displayName = "Quantity";
