import { IStyle } from "@fluentui/react/lib/Styling";

export const layerStyle: IStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 1000001,
  top: "0",
  left: "0",
};
