import axios from "axios";
import i18n from "i18next";
import { pathOr } from "ramda";
import { initReactI18next } from "react-i18next";

import { Http } from "@encoway/c-services-js-client";

export const SETTINGS = {
  system: pathOr<string>(
    process.env.REACT_APP_SYSTEM || "NOT_SET_",
    ["encoway", "system"],
    window,
  ),
  language: pathOr<string>(
    localStorage.getItem("language") || "en-US",
    ["dataset", "language"],
    document.querySelector("#encoway-configurator"),
  ),
  showroom: {
    url: pathOr(
      process.env.REACT_APP_BACKEND_URL || "van-hoecke-server",
      ["encoway", "cpqBaseUrl"],
      window,
    ),
    sessionTimeout: pathOr<number>(30, ["encoway", "sessionTimeout"], window),
  },
  session: {
    key: "configuration",
    encryption: "d41d8cd98f00b204e9800998ecf8427e",
  },
  studio: {
    config: "application",
    boms: {
      big: "encoway.customcalculationview",
    },
  },
  configuration: {
    priority: {
      contentBlock: "CB_PRIORITY",
    },
    grid: {
      contentBlock: /^CB_GRID_(\d+)$/,
    },
    viewPorts: {
      displayTitle: "displayTitle",
      priority: "priority",
    },
    viewportProperties: {
      hideCharacteristicName: "HIDE_CHARACTERISTIC_NAME",
      showImage: "SHOW_IMAGE",
      hideCharacteristicImage: "HIDE_CHARACTERISTIC_IMAGE",
      hideCharacteristicKey: "HIDE_CHARACTERISTIC_KEY",
      hideCharacteristicLabel: "HIDE_CHARACTERISTIC_LABEL",
      hideCharacteristicText: "HIDE_CHARACTERISTIC_TEXT",
      hidePlaceholder: "HIDE_PLACEHOLDER",
      priority: "C_PRIORITY_FILTER_CHARACTERISTICS",
    },
    modelProperties: {
      hideVisualization: "HIDE_VISUALIZATION",
      hideCompleteNextButton: "HIDE_COMPLETE_NEXT_BUTTON",
    },
  },
  visualization: {
    baseUrl: "https://visualization.encoway.cloud/vcs",
    version: "1.10.7",
    token:
      "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aXN1YWxpemF0aW9uIiwiYXVkIjoiaHR0cHM6Ly93d3cudmFuaG9lY2tlLmJlLyIsImlzcyI6IlZhbiBIb2Vja2UiLCJpYXQiOjE2OTI5NjczMzUsImV4cCI6MTc1OTE5MDQwMCwiY2FkIjpbImR4ZiIsImR3ZyIsImlmYyJdLCJ0b29scyI6bnVsbCwicmVuZGVyaW5nIjoic2QiLCJhciI6dHJ1ZX0.8OWbKu-Lbe-o06bhNxaUPMYWYnzrhStkJaY4Lf_LaEQ",
    settings: {
      theme: {
        palette: {
          themePrimary: "#2b2b2b",
          themeLighterAlt: "#fef9f6",
          themeLighter: "#fde9da",
          themeLight: "#fad6ba",
          themeTertiary: "#f6ae77",
          themeSecondary: "#f28a3b",
          themeDarkAlt: "#ce1719",
          themeDark: "#ce1719",
          themeDarker: "#ce1719",
          neutralLighterAlt: "#faf9f8",
          neutralLighter: "#f3f2f1",
          neutralLight: "#edebe9",
          neutralQuaternaryAlt: "#e1dfdd",
          neutralQuaternary: "#d0d0d0",
          neutralTertiaryAlt: "#c8c6c4",
          neutralTertiary: "#a19f9d",
          neutralSecondary: "#605e5c",
          neutralPrimaryAlt: "#3b3a39",
          neutralPrimary: "#323130",
          neutralDark: "#201f1e",
          black: "#000000",
          white: "#ffffff",
        },
      },
      features: {
        Gizmo: "off",
        OrientationIndicator: "off",
        Selection: "on",
        HighlightHoverItem: "off",
        ContextMenu: "off",
      },
      ui: {
        controls: {
          rotation: true,
          autozoom: true,
          minDistance: 10,
          maxDistance: 10000,
          minAzimuthAngle: -Infinity,
          maxAzimuthAngle: Infinity,
          minPolarAngle: 0,
          maxPolarAngle: Math.PI,
        },
        sidebar: {
          type: "none",
        },
        camera: {
          near: 10,
          far: 100000.0,
          fov: 60.0,
          position: {
            x: 100,
            y: 100,
            z: 100,
          },
        },
        contextMenu: {
          info: false,
          state: true,
          configure: false,
          configure_structure: false,
          change_product: false,
          clone: false,
          delete: false,
          delete_structure: false,
          rotation_roll: false,
          rotation_flip: false,
          fill_line_anchor: false,
        },
        contextActions: false,
        globalDrop: false,
        background: 0xffffff,
        lights: [
          {
            type: "ambient",
            name: "#AmbientLight",
            color: "#404040",
            intensity: 1,
          },
          {
            type: "directional",
            position: {
              x: 2.6,
              y: 1,
              z: -3,
            },
            name: "#BackLight",
            intensity: 0.75,
          },
          {
            type: "directional",
            position: {
              x: -2,
              y: -1,
              z: 1,
            },
            name: "#FrontLight",
            intensity: 0.75,
          },
          {
            type: "directional",
            position: {
              x: 1,
              y: 4,
              z: 4,
            },
            name: "#TopFrontLight",
            intensity: 0.5,
          },
          {
            type: "directional",
            position: {
              x: 0,
              y: 5,
              z: 0,
            },
            name: "#TopLight",
            intensity: 0.5,
          },
        ],
        components: {
          catalog: false,
          header: false,
          toolbar: "west",
        },
        toolbar: {
          ViewIsometric: true,
          ViewTop: true,
          ViewFront: true,
          select: true,
          measure_distance: false,
          dimensioning: {
            singleDimensioning: false,
            datumDimensioning: false,
            customDimensioning: true,
          },
          grid: false,
          snap_objects: false,
          help: false,
          configuration: false,
          autozoom: true,
        },
        dimensioning: {
          autoEnabled: false,
          lineColor: "#ef7b22",
          textColor: "#000000",
        },
      },
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: SETTINGS.language,
    interpolation: {
      escapeValue: false,
    },
  })
  .then();

const basic: { user: string; pw: string } = JSON.parse(
  localStorage.getItem("basic") || JSON.stringify({ user: "", pw: "" }),
);
export const AppInstance = {
  axios: axios.create({
    baseURL: SETTINGS.showroom.url,
    auth: {
      username: basic.user,
      password: basic.pw,
    },
  }),
  http: Http.Basic(basic.user, basic.pw),
};
