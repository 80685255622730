import { head } from "ramda";

import { ParameterTO, Value } from "@encoway/c-services-js-client";

import { SETTINGS } from "../../../settings";
import { getCustomViewportProperties } from "../configuratorUtils";

interface ConfRadioProps {
  data: ParameterTO;

  onValueChanged(data: ParameterTO, value: string): Promise<void>;
}

type ConfDisplayImageProps = {
  hideCharacteristicImage: boolean;
  imageUrl?: string;
  alt: string;
};

type ConfDisplayTextProps = {
  hideCharacteristicKey: boolean;
  hideCharacteristicText: boolean;
  selectedValue?: Value;
};

const ConfDisplayImage = ({
  hideCharacteristicImage,
  imageUrl,
  alt,
}: ConfDisplayImageProps) => {
  const image = imageUrl
    ? `${SETTINGS.showroom.url}/api/catalog/media?id=${imageUrl}`
    : undefined;
  return !hideCharacteristicImage ? (
    <img className="customDisplayImage" src={image} alt={alt} />
  ) : null;
};

const ConfDisplayText = ({
  hideCharacteristicKey,
  hideCharacteristicText,
  selectedValue,
}: ConfDisplayTextProps) => {
  return (
    <>
      {!hideCharacteristicKey ? (
        <p className="customDisplayKey">{selectedValue?.value || "-"}</p>
      ) : null}

      {!hideCharacteristicText ? (
        <p className="customDisplayValue">
          {selectedValue?.translatedValue || "-"}
        </p>
      ) : null}
    </>
  );
};

export function ConfDisplay({ data }: ConfRadioProps) {
  const {
    hideCharacteristicImage,
    hideCharacteristicKey,
    hideCharacteristicText,
  } = getCustomViewportProperties(data);
  const selectedValue = head(data?.selectedValues || [{ value: undefined }]);

  return (
    <>
      <ConfDisplayImage
        hideCharacteristicImage={hideCharacteristicImage}
        imageUrl={selectedValue?.imageUrl}
        alt={selectedValue?.value}
      />
      <ConfDisplayText
        hideCharacteristicKey={hideCharacteristicKey}
        hideCharacteristicText={hideCharacteristicText}
        selectedValue={selectedValue}
      />
    </>
  );
}
