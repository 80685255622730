import { IPartialTheme, IStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";

import { IRadioButtonStyles } from "./RadioButton.types";

export const RadioButtonStyles: IStyleFunctionOrObject<
  IPartialTheme,
  IStyleSet<IRadioButtonStyles>
> = (theme: IPartialTheme) => ({
  root: {
    minWidth: 0,
  },
  choiceGroup: {
    selectors: {
      ".ms-ChoiceField-wrapper": {
        flex: 1,
        minWidth: 0,
      },
      ".ms-ChoiceField-wrapper input.ms-ChoiceField-input": {
        top: "inherit",
        right: "inherit",
        width: "unset",
        height: "inherit",
        margin: 0,
      },
      ".ms-ChoiceField:first-child": {
        marginTop: 0,
      },
      ".ms-ChoiceField:not(:first-child)": {
        marginTop: "1em",
      },
      label: {
        minWidth: 0,
      },
      "label::before": {
        background: "transparent",
        // outer ring color
        borderColor: theme.palette?.neutralTertiary,
      },
      "label:hover::before": {
        background: "transparent",
        // outer ring color on hover
        borderColor: theme.palette?.neutralSecondary,
      },
      "label:hover::after": {
        // inner dot background on hover
        background: "transparent",
      },
    },
  },
  option: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  optionSelected: {
    selectors: {
      "label::before": {
        borderColor: theme.palette?.themePrimary,
      },
      "label:hover::before": {
        borderColor: theme.palette?.neutralSecondary,
      },
    },
  },
  optionNotSelected: {
    color: theme.semanticColors?.disabledText,
  },
  optionConflict: {
    selectors: {
      "label::before": {
        // outer ring color
        borderColor: theme.palette?.neutralTertiary,
      },
      "label:not(.is-checked)::before": {
        background: theme.palette?.neutralTertiary,
      },
      "label:hover::before": {
        // outer ring color
        borderColor: theme.palette?.neutralSecondary,
      },
      "label:not(.is-checked):hover::before": {
        background: theme.palette?.neutralSecondary,
      },
      "label::after": {
        // inner dot color
        borderColor: theme.palette?.neutralTertiary,
      },
      "label:hover::after": {
        // inner dot color
        borderColor: theme.palette?.neutralSecondary,
      },
    },
  },
  optionDisabled: {
    selectors: {
      "label.ms-ChoiceField-field .ms-ChoiceFieldLabel": {
        color: theme.semanticColors?.disabledText,
      },
      "label.ms-ChoiceField-field::before": {
        // outer ring color
        borderColor: theme.semanticColors?.buttonBackgroundDisabled,
        backgroundColor: theme.semanticColors?.buttonBackgroundDisabled,
      },
      "label.ms-ChoiceField-field:hover::before": {
        // outer ring color on hover
        borderColor: theme.semanticColors?.buttonBackgroundDisabled,
        backgroundColor: theme.semanticColors?.buttonBackgroundDisabled,
      },
      "label.ms-ChoiceField-field::after": {
        // inner dot color on hover
        borderColor: theme.semanticColors?.disabledText,
      },
      "label.ms-ChoiceField-field:hover::after": {
        // inner dot color on hover
        borderColor: theme.semanticColors?.disabledText,
      },
    },
  },
  price: {
    flex: 1,
    display: "flex",
    flexDirection: "row-reverse",
  },
  priceNotSelected: {
    color: theme.semanticColors?.disabledText,
  },
  textContainer: {
    overflow: "hidden",
    selectors: {
      " .ms-ChoiceField-field": {
        width: "100%",
      },
      " .ms-ChoiceFieldLabel": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
      },
    },
  },
});
