import { equals } from "ramda";
import { useCallback, useState } from "react";

export enum PROGRESS {
  NOT_LOADED,
  LOADING,
  LOADED,
  ERROR,
}

interface ProgressStatus {
  message: string;
  notLoaded: boolean;
  loading: boolean;
  loaded: boolean;
  error: boolean;
}

interface ProgressStore extends ProgressStatus {
  set(progress: PROGRESS, message?: string): void;
}

export function useProgress(): ProgressStore {
  const [progress, setProgress] = useState<ProgressStatus>({
    message: "",
    notLoaded: true,
    loading: false,
    loaded: false,
    error: false,
  });

  const set = useCallback(
    function (value: PROGRESS, message = "") {
      const eq = equals(value);
      setProgress({
        message,
        notLoaded: eq(PROGRESS.NOT_LOADED),
        loading: eq(PROGRESS.LOADING),
        loaded: eq(PROGRESS.LOADED),
        error: eq(PROGRESS.ERROR),
      });
    },
    [setProgress],
  );

  return {
    ...progress,
    set,
  };
}
