import { PartialTheme, ThemeProvider } from "@fluentui/react";
import { path, reduce } from "ramda";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { L10n } from "@encoway/l10n";

import { fetchLanguages, fetchResource } from "../service/appService";
import { AppInstance, SETTINGS } from "../settings";
import { theme } from "../theme";
import { LanguageState, Translation, Translations } from "../types/translation";
import { toLanguage } from "./appUtils";
import { ProductStore, useProductContext } from "./useProduct";

export type AppState = {
  translations: Translations | undefined;
  languages: LanguageState;
  theme: PartialTheme;
};

const initialState: AppState = {
  translations: undefined,
  languages: {
    [SETTINGS.language]: {
      [SETTINGS.language]: {
        tag: SETTINGS.language,
        displayName: SETTINGS.language,
      },
    },
  },
  theme: theme,
};

export const AppContext = createContext<AppState>(initialState);

const TRANSLATION_PATH = [
  SETTINGS.studio.config,
  "characteristicValues",
  "APP_TRANSLATION",
  "values",
  0,
  "uri",
];

function useApp({ getProducts }: ProductStore): AppState {
  const { i18n } = useTranslation();
  const [app, setApp] = useState<AppState>(initialState);

  useEffect(() => {
    (async () => {
      if (!app.translations || !app.translations[i18n.language]) {
        const newLanguages = reduce(
          toLanguage,
          {},
          await fetchLanguages(AppInstance.axios, i18n.language),
        );
        const products = await getProducts(SETTINGS.studio.config);
        const languageUri = path<string>(TRANSLATION_PATH, products);
        if (languageUri) {
          const newTranslation = await fetchResource<Translation>(
            AppInstance.axios,
            languageUri,
            i18n.language,
          );
          await i18n.addResourceBundle(i18n.language, "t", newTranslation);
          setApp((prev) => ({
            ...prev,
            languages: {
              ...prev.languages,
              [i18n.language]: newLanguages,
            },
            translations: {
              ...prev.translations,
              [i18n.language]: newTranslation,
            },
          }));
        } else {
          console.warn(`language ${i18n.language} is not set as language`);
        }
      }
    })();
  }, [i18n.language]);

  useEffect(() => {
    localStorage.setItem("language", i18n.language);
    L10n.reloadResources(i18n.language).then(() =>
      L10n.currentLocale(i18n.language),
    );
  }, [i18n.language]);

  return useMemo(() => app, [app]);
}

export function AppProvider({ children }: { children: ReactNode }) {
  const productStore = useProductContext();
  const appStore = useApp(productStore);

  return (
    <AppContext.Provider value={appStore}>
      <ThemeProvider theme={appStore.theme}>{children}</ThemeProvider>
    </AppContext.Provider>
  );
}
