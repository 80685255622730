import { find } from "ramda";
import { useTranslation } from "react-i18next";

import { L10n } from "@encoway/l10n";
import { Error } from "@encoway/react-configurator";

export type ErrorMessage = {
  key: string;
  translations: {
    title: string;
    text: string;
    confirm: string;
  };
};

const errorMessages: ErrorMessage[] = [
  {
    key: "Error: Not Found",
    translations: {
      title: "invalid_configuration",
      text: "invalid_configuration_text",
      confirm: "invalid_configuration_confirm",
    },
  },
  {
    key: "Failed to fetch",
    translations: {
      title: "not_found_configuration",
      text: "not_found_configuration_text",
      confirm: "not_found_configuration_confirm",
    },
  },
];

export function useErrorDialog(error: Error) {
  const { t } = useTranslation();
  const configurationInvalid = find(
    (msg) => msg.key === error?.message,
    errorMessages,
  );
  const l10nKeyPrefix = `Configuration.${error?.type}.Conflict.`;

  function getMessage(
    key: keyof ErrorMessage["translations"],
    defaultKey: string,
    params?: Record<string, any>,
  ) {
    return configurationInvalid
      ? t(configurationInvalid.translations[key])
      : L10n.format(`${l10nKeyPrefix}${defaultKey}`, params);
  }

  return {
    title: getMessage("title", "Title"),
    dialogText: getMessage("text", "Content", {
      parameter: error?.translatedName,
      value: error?.value,
    }),
    buttonText: getMessage("confirm", "Dialog.continue"),
  };
}
